import {Button, Table} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faListAlt,
    faMagic,
    faPlus,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import CommunicationBetweenCharacters from "./CommunicationBetweenCharacters";
import {Tab} from "bootstrap";
import GeneralTab from "./GeneralTab";
import Editor from "react-medium-editor";
import MediumButton from "medium-button";

const styles = {
    blockHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    editableCell: {
        backgroundColor: '#fff',
        width: '50%'
    },

    noEditableCell: {
        backgroundColor: '#f6f6f6',
        width: '50%'
    },

    actionColumn: {
        backgroundColor: '#fff',
        width: '5%'
    },

    blockStyle: {
        marginBottom: '15px'
    }
}

export default function LanguageTabPreferences(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code

    console.log("LanguageTabPreferences", props.language.code, props)

    async function oninputCell(block, column, row, type, e) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let value

        if (type === "input") {
            queryLinkExist += 'styleGuideLanguagesFilledCellTextByCode'
            queryUpdateCell += 'styleGuideLanguagesOninputUpdateTextCell'
            queryInsertCell += 'styleGuideLanguagesOninputInsertTextCell'
            value = e.target.innerText
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": props.language.code,
                "block": block,
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": props.language.code,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": value,
                                "changedBy": {
                                    id: props.employeeId,
                                    name: props.employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": props.language.code,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": value,
                                "changedBy": {
                                    id: props.employeeId,
                                    name: props.employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    function openModalToAddLinksAndImages(element, /*language, block, row, column*/modalHistoryTitle, tableDBName, data) {
        let cellText = ""

        if (element.target.nodeName === "TD") {
            cellText = element.target.innerHTML
        } else {
            cellText = element.target.closest(".select-double-click-add-links-and-images").innerHTML
        }

        props.openModalToAddLinksAndImages(
            cellText,
            "languageTabSavePreferencesCellValue",
            modalHistoryTitle,
            tableDBName,
            data
            /*{
                language: language.code,
                block: block,
                column: column.code,
                row: row.code,
                type: "input"
            }*/
        )
    }

    let getBlockData = languageData => {
        let content = []
        let languageAcceptData = props.language.accept
        let acceptData = {}

        for (let key in languageAcceptData) {
            let linguistValue = languageAcceptData[key]
            let languageAcceptDataLinguistId = linguistValue.linguistId
            let languageAcceptDataValue = linguistValue.value

            if (Object.keys(languageAcceptDataValue).length > 0) {
                props.linguistLinks[languageAcceptDataLinguistId].data.languagePreferences.map(infoLinguistLinks => {
                    if (infoLinguistLinks.code === props.language.code) {
                        props.language.data.map(languageDataInfo => {
                            for (let blockName in languageAcceptDataValue) {
                                languageDataInfo[blockName].data.map(languageBlockData => {
                                    let rowCode = languageBlockData.code

                                    if (languageAcceptDataValue[blockName].default.includes(rowCode)) {
                                        infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                            if (infoLinguistLinkKeyBlock.code === rowCode) {
                                                if (!(blockName in acceptData)) {
                                                    acceptData[blockName] = {
                                                        "default": [],
                                                        "extra": []
                                                    }
                                                }

                                                acceptData[blockName].default[rowCode] = infoLinguistLinkKeyBlock.row
                                            }

                                            return infoLinguistLinkKeyBlock
                                        })
                                    }

                                    return languageBlockData
                                })

                                for (let i = 0; i < languageAcceptDataValue[blockName].extra.length; i++) {
                                    infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                        if (infoLinguistLinkKeyBlock.code === languageAcceptDataValue[blockName].extra[i]) {
                                            if (!(blockName in acceptData)) {
                                                acceptData[blockName] = {
                                                    "default": [],
                                                    "extra": []
                                                }
                                            }

                                            acceptData[blockName].extra.push(infoLinguistLinkKeyBlock)
                                        }

                                        return infoLinguistLinkKeyBlock
                                    })
                                }

                                if ("otherLinguistExtra" in languageAcceptDataValue[blockName]) {
                                    for (let otherLinguist in languageAcceptDataValue[blockName].otherLinguistExtra) {
                                        //отсюда смотрим принятые строки
                                        let acceptedFromOtherLinguist = languageAcceptDataValue[blockName].otherLinguistExtra[otherLinguist]

                                        props.linguistLinks[otherLinguist].data.languagePreferences.map(infoOtherLinguistLinks => {
                                            if (infoOtherLinguistLinks.code === props.language.code) {
                                                let otherLinguistAllRows = infoOtherLinguistLinks.data[0][blockName].data

                                                for (let i = 0; i < otherLinguistAllRows.length; i++) {
                                                    if (otherLinguistAllRows[i].code in acceptedFromOtherLinguist) {
                                                        let valueToPush = otherLinguistAllRows[i]

                                                        for (let j = 0; j < valueToPush.row.length; j++) {
                                                            if (valueToPush.row[j].code in acceptedFromOtherLinguist[otherLinguistAllRows[i].code]) {
                                                                // valueToPush.row[j].text = acceptedFromOtherLinguist[otherLinguistAllRows[i].code][valueToPush.row[j].code]
                                                                valueToPush.row[j]["acceptedText"] = acceptedFromOtherLinguist[otherLinguistAllRows[i].code][valueToPush.row[j].code]
                                                            }
                                                        }

                                                        if (!(blockName in acceptData)) {
                                                            acceptData[blockName] = {
                                                                "default": [],
                                                                "extra": []
                                                            }
                                                        }

                                                        acceptData[blockName].extra.push(valueToPush)
                                                    }
                                                }
                                            }

                                            return infoOtherLinguistLinks
                                        })
                                    }
                                }
                            }

                            return languageDataInfo
                        })
                    }

                    return infoLinguistLinks
                })
            }
        }

        for (let block in languageData) {
            let value = languageData[block]

            if (block !== "project_management_info" || (block === "project_management_info" && props.projectManagementInfo)) {
                content.push(
                    <div key={block} style={styles.blockStyle} className="row">
                        <div className="col-sm-12 table-blue-thead-not-fixed">
                        <Table responsive bordered>
                            <thead>
                            <tr style={styles.blockHeader}>
                                <th className="center" colSpan={5} style={styles.blockHeader}>
                                    {props.language.data[0][block].title}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                props.language.data[0][block].data.map((data, dataIndex) => {
                                    if (block in acceptData && data.code in acceptData[block].default) {
                                        return (
                                            <tr key={data.code} id={data.code}>
                                                {
                                                    acceptData[block].default[data.code].map(rowData => {
                                                        return (
                                                            <td key={rowData.code} id={rowData.code}
                                                                className="whiteSpacePreLine"
                                                                colSpan={data.colspan ? 4 : 2}
                                                                contentEditable={false}
                                                                style={styles.noEditableCell}
                                                                dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                            </td>
                                                        )
                                                    })
                                                }
                                                <td className="center" contentEditable={false} style={styles.actionColumn}>
                                                </td>
                                            </tr>
                                        )
                                    } else {
                                        return (
                                            <tr key={data.code} id={data.code}>
                                                {
                                                    data.row.map(rowData => {
                                                        if (rowData.styleGuideEditable) {
                                                            let cellValue = rowData.text
                                                            let colspanValue = data.colspan ? 4 : 2

                                                            function handleChange(text, medium) {
                                                                console.log("handleChange", text)

                                                                cellValue = text
                                                            }

                                                            function onBlurSave(e) {
                                                                if (!props.modalAddLinksAndImagesActive && !props.modalOpenAddedImageActive) {
                                                                    if (e.target.nodeName === "TD") {
                                                                        console.log("onBlurSave", e.target.innerHTML, cellValue)

                                                                        props.languageTabSavePreferencesCellValue(
                                                                            props.language.code, block, rowData.code, data.code, "input", e.target.innerHTML, false
                                                                        )
                                                                    }
                                                                }
                                                            }

                                                            if ("templateTextSelection" in rowData) {
                                                                colspanValue = data.colspan ? 2 : 1
                                                            }

                                                            return(
                                                                <>
                                                                    {
                                                                        "templateTextSelection" in rowData &&
                                                                        <td className="center"
                                                                            contentEditable={false}
                                                                            colSpan={colspanValue}
                                                                            style={styles.actionColumn}>
                                                                            <Button size="sm" variant="primary" className="btn btn-blue"
                                                                                    onClick={(e) =>
                                                                                        props.selectTemplateTextForLanguageTabPreferences(
                                                                                            props.language.code, block, rowData.code, data.code, "input", rowData.templateTextSelection, true
                                                                                        )}>
                                                                                <FontAwesomeIcon icon={faListAlt}/>
                                                                            </Button>
                                                                        </td>
                                                                    }
                                                                    <Editor
                                                                        key={rowData.code} id={rowData.code}
                                                                        className="whiteSpacePreLine select-double-click-add-links-and-images"
                                                                        suppressContentEditableWarning={true}
                                                                        tag="td"
                                                                        colSpan={colspanValue}
                                                                        text={cellValue}
                                                                        onChange={handleChange}
                                                                        style={styles.editableCell}
                                                                        options={{
                                                                            toolbar: {
                                                                                buttons: [
                                                                                    'bold',
                                                                                    'italic',
                                                                                    'underline',
                                                                                    'addImage'
                                                                                ]
                                                                            },
                                                                            extensions: {
                                                                                'addImage': new MediumButton({
                                                                                    label: '<b>img</b>',
                                                                                    start: '<button class="btn btn-secondary btn-sm open-in-modal-added-image" type="button" contenteditable="false" data-src="">',
                                                                                    end:   '</button>',
                                                                                    action: function(html){
                                                                                        props.triggerOpenInModalAddedImage()

                                                                                        return html
                                                                                    }
                                                                                })
                                                                            },
                                                                            placeholder: false
                                                                        }}
                                                                        onDoubleClick={(e) =>
                                                                            openModalToAddLinksAndImages(
                                                                                e, /*props.language, block, data, rowData*/
                                                                                "History of changes in \"" + props.language.data[0][block].title + "\" block",
                                                                                "style_guide_language_filled_data",
                                                                                {
                                                                                    language: props.language.code,
                                                                                    block: block,
                                                                                    column: rowData.code,
                                                                                    row: data.code,
                                                                                    type: "input",
                                                                                    refillCell: true
                                                                                }
                                                                            )}
                                                                        onBlur={(e) => onBlurSave(e)}
                                                                    />
                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <td key={rowData.code} id={rowData.code}
                                                                    className="whiteSpacePreLine"
                                                                    colSpan={data.colspan ? 4 : 2}
                                                                    contentEditable={false}
                                                                    style={styles.noEditableCell}
                                                                    dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                </td>
                                                            )
                                                        }
                                                    })
                                                }
                                                <td className="center" contentEditable={false} style={styles.actionColumn}>
                                                    {
                                                        "extraRow" in data && data.extraRow &&
                                                        <Button size="sm" variant="danger" onClick={(e) =>
                                                            props.deleteRowFromStyleGuideLanguageBlock(props.language.code, block, data.code, dataIndex)}>
                                                            <FontAwesomeIcon icon={faTimes}/>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                            {
                                block in acceptData &&
                                <>
                                    {
                                        acceptData[block].extra.map(acceptDataBlock => {
                                            return (
                                                <tr key={acceptDataBlock.code} id={acceptDataBlock.code}>
                                                    {
                                                        acceptDataBlock.row.map(rowData => {
                                                            return (
                                                                <td key={rowData.code} id={rowData.code}
                                                                    className="whiteSpacePreLine"
                                                                    colSpan={acceptDataBlock.colspan ? 4 : 2}
                                                                    contentEditable={false}
                                                                    style={styles.noEditableCell}
                                                                    dangerouslySetInnerHTML={{__html: "acceptedText" in rowData ? rowData["acceptedText"] : rowData.text}}>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    <td className="center" contentEditable={false} style={styles.actionColumn}>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </>
                            }
                            </tbody>
                        </Table>
                        <div className="center">
                            <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                props.addRowToStyleGuideLanguageBlock(props.language.code, block,
                                    props.language.data[0][block].clientEditable,
                                    props.language.data[0][block].linguistEditable, 1)}>
                                Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                props.addRowToStyleGuideLanguageBlock(props.language.code, block,
                                    props.language.data[0][block].clientEditable,
                                    props.language.data[0][block].linguistEditable, 2)}>
                                Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                        </div>
                    </div>
                )
            }
        }

        return content
    }

    return(
        <div key={props.language.code} className="row">
            <div className="col-sm-12">
                { getBlockData(props.language.data[0]) }
            </div>
        </div>
    )
}