import React, {useEffect, useState} from "react";
import {Col, Nav, Row, Table, Tab, Tabs} from "react-bootstrap";
import TableInfo from "./TableInfo"
import UncreatedTableInfo from "./UncreatedTableInfo"

const styles = {
    progressBar: {
        margin: '10rem 10rem'
    },
}

export default function ProjectBibleReport(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [pbData, setPBData] = useState([{
        data: [],
        uncreatedPB: [],
        statistics: {}
    }])
    const [stagesList, setStagesList] = useState([{
        data: []
    }])
    const authorizationCookieName = "b24_authorization_log"
    const authorizationCookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + authorizationCookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    const employeeId = authorizationCookieMatches ?
        +JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).userId :
        window.location.href.split("/")[2] === "localhost:3000" ? 191 : 0

    useEffect(async () => {
        if (checkCookie()) {
            deleteRedirectCookie()

            await fetch("/proxy/project_bible_template/getCreatedPB", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    async (createdPB) => {
                        await fetch("/proxy/project_bible_template/getPBWithFilledTextValues", {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(res => res.json())
                            .then(
                                async (pbWithFilledTextValues) => {
                                    await fetch("/proxy/project_bible_template/getPBWithFilledBoolValues", {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (pbWithFilledBoolValues) => {
                                                await fetch("/proxy/project_bible_template/getCreatedStyleGuide", {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    }
                                                })
                                                    .then(res => res.json())
                                                    .then(
                                                        async (createdSG) => {
                                                            await fetch("/proxy/project_bible_template/getCreatedPS", {
                                                                method: 'GET',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                }
                                                            })
                                                                .then(res => res.json())
                                                                .then(
                                                                    async (createdPS) => {
                                                                        await fetch("/proxy/project_bible_template/getFilledPS", {
                                                                            method: 'GET',
                                                                            headers: {
                                                                                'Content-Type': 'application/json'
                                                                            }
                                                                        })
                                                                            .then(res => res.json())
                                                                            .then(
                                                                                async (filledPS) => {
                                                                                    await fetch("/proxy/project_bible_template/getPBDataForCalculateReportKPI", {
                                                                                        method: 'GET',
                                                                                        headers: {
                                                                                            'Content-Type': 'application/json'
                                                                                        }
                                                                                    })
                                                                                        .then(res => res.json())
                                                                                        .then(
                                                                                            async (dataForCalculateKPI) => {
                                                                                                await fetch("/proxy/project_bible_template/projectBibleTemplateRowsColumns", {
                                                                                                    method: 'POST',
                                                                                                    headers: {
                                                                                                        'Content-Type': 'application/json'
                                                                                                    }
                                                                                                })
                                                                                                    .then(res => res.json())
                                                                                                    .then(
                                                                                                        async (templateRows) => {
                                                                                                            await fetch("/proxy/project_bible_template/getPBDataToCalculateFillByStages", {
                                                                                                                method: 'GET',
                                                                                                                headers: {
                                                                                                                    'Content-Type': 'application/json'
                                                                                                                }
                                                                                                            })
                                                                                                                .then(res => res.json())
                                                                                                                .then(
                                                                                                                    async (dataToCalculateFillByStages) => {
                                                                                                                        const response = await fetch('/proxy/getActiveProjectB', {
                                                                                                                            method: 'POST',
                                                                                                                            headers: {'Content-Type': 'application/json'}
                                                                                                                        });
                                                                                                                        let result = await response.json();
                                                                                                                        let listOfStages = []

                                                                                                                        for (let i = 0; i < dataToCalculateFillByStages.stagesList.length; i++) {
                                                                                                                            let stageTitle = dataToCalculateFillByStages.stagesList[i].value
                                                                                                                            let acceptedStages = [
                                                                                                                                "Production", "LQA", "Editing", "General", "L10n", "Pre-Sale"
                                                                                                                            ]

                                                                                                                            if (acceptedStages.includes(stageTitle)) {
                                                                                                                                listOfStages.push(stageTitle)
                                                                                                                            }
                                                                                                                        }

                                                                                                                        setStagesList(stagesList.map(info => {
                                                                                                                            info.data = listOfStages

                                                                                                                            return info
                                                                                                                        }))

                                                                                                                        console.log("dataToCalculateFillByStages.stagesList", dataToCalculateFillByStages.stagesList, stagesList)

                                                                                                                        fillData(createdPB,
                                                                                                                            pbWithFilledTextValues,
                                                                                                                            pbWithFilledBoolValues,
                                                                                                                            createdSG,
                                                                                                                            createdPS,
                                                                                                                            filledPS,
                                                                                                                            convertDataForCalculateKPIToMap(templateRows, dataForCalculateKPI),
                                                                                                                            result.response,
                                                                                                                            dataToCalculateFillByStages
                                                                                                                        )
                                                                                                                    },
                                                                                                                    (error) => {
                                                                                                                        // setIsLoaded(true);
                                                                                                                        setError(error);
                                                                                                                    }
                                                                                                                )
                                                                                                        },
                                                                                                        (error) => {
                                                                                                            // setIsLoaded(true);
                                                                                                            setError(error);
                                                                                                        }
                                                                                                    )
                                                                                            },
                                                                                            (error) => {
                                                                                                // setIsLoaded(true);
                                                                                                setError(error);
                                                                                            }
                                                                                        )
                                                                                },
                                                                                (error) => {
                                                                                    // setIsLoaded(true);
                                                                                    setError(error);
                                                                                }
                                                                            )
                                                                    },
                                                                    (error) => {
                                                                        // setIsLoaded(true);
                                                                        setError(error);
                                                                    }
                                                                )
                                                        },
                                                        (error) => {
                                                            // setIsLoaded(true);
                                                            setError(error);
                                                        }
                                                    )
                                            },
                                            (error) => {
                                                // setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                },
                                (error) => {
                                    // setIsLoaded(true);
                                    setError(error);
                                }
                            )
                    },
                    (error) => {
                        // setIsLoaded(true);
                        setError(error);
                    }
                )
        } else {
            setRedirectCookie()
            window.location.replace('https://b24.allcorrectgames.com');
        }
    }, [])

    function checkCookie() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        } else {
            return employeeId > 0
        }
    }

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(window.location.href)
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function convertDataForCalculateKPIToMap(templateRows, data) {
        let convertedData = {
            "templateRows": [],
            "templateBool": {},
            "bool": {},
            "text": {}
        }

        templateRows.rows.map(value => {
            convertedData.templateRows.push(value.code)
        })

        for (let i = 0; i < data.templateBool.length; i++) {
            if (data.templateBool[i]["value"]) {
                if (!(data.templateBool[i]["row_code"] in convertedData.templateBool)) {
                    convertedData.templateBool[data.templateBool[i]["row_code"]] = {}
                }

                convertedData.templateBool[data.templateBool[i]["row_code"]][data.templateBool[i]["col_code"]] = data.templateBool[i]["value"]
            }
        }

        for (let i = 0; i < data.bool.length; i++) {
            if (!(data.bool[i]["client_name"] in convertedData.bool)) {
                convertedData.bool[data.bool[i]["client_name"]] = {}
            }

            if (!(data.bool[i]["project_name"] in convertedData.bool[data.bool[i]["client_name"]])) {
                convertedData.bool[data.bool[i]["client_name"]][data.bool[i]["project_name"]] = {}
            }

            if (!(data.bool[i]["project_code"] in convertedData.bool[data.bool[i]["client_name"]][data.bool[i]["project_name"]])) {
                convertedData.bool[data.bool[i]["client_name"]][data.bool[i]["project_name"]][data.bool[i]["project_code"]] = {}
            }

            if (!(data.bool[i]["row_code"] in convertedData.bool[data.bool[i]["client_name"]][data.bool[i]["project_name"]][data.bool[i]["project_code"]])) {
                convertedData.bool[data.bool[i]["client_name"]][data.bool[i]["project_name"]][data.bool[i]["project_code"]][data.bool[i]["row_code"]] = {}
            }

            convertedData.bool[data.bool[i]["client_name"]][data.bool[i]["project_name"]][data.bool[i]["project_code"]][data.bool[i]["row_code"]][data.bool[i]["col_code"]] =
                data.bool[i]["value"]
        }

        for (let i = 0; i < data.text.length; i++) {
            let textValue = data.text[i]["value"]

            textValue = textValue.replace(/[\n\r]+/g, '');
            textValue = textValue.replace(/\s{1,10}/g, '');

            if (!(data.text[i]["client_name"] in convertedData.text)) {
                convertedData.text[data.text[i]["client_name"]] = {}
            }

            if (!(data.text[i]["project_name"] in convertedData.text[data.text[i]["client_name"]])) {
                convertedData.text[data.text[i]["client_name"]][data.text[i]["project_name"]] = {}
            }

            if (!(data.text[i]["project_code"] in convertedData.text[data.text[i]["client_name"]][data.text[i]["project_name"]])) {
                convertedData.text[data.text[i]["client_name"]][data.text[i]["project_name"]][data.text[i]["project_code"]] = {}
            }

            if (textValue) {
                convertedData.text[data.text[i]["client_name"]][data.text[i]["project_name"]][data.text[i]["project_code"]][data.text[i]["row_code"]] =
                    data.text[i]["value"]
            }
        }

        return convertedData
    }

    function checkFilledInfoColumnInRow(rowsWithFilledInfoCol, clientName, projectName, projectCode, rowCode) {
        //существует ли заполненная ячейка Info
        if (clientName in rowsWithFilledInfoCol &&
            projectName in rowsWithFilledInfoCol[clientName] &&
            projectCode in rowsWithFilledInfoCol[clientName][projectName] &&
            rowCode in rowsWithFilledInfoCol[clientName][projectName][projectCode]) {

            return true
        }

        return false
    }

    function getActualPBRows(clientName, projectName, projectCode, pbRows, dataForCalculateKPI) {
        let templateRows = dataForCalculateKPI.templateRows
        let rowsWithFilledInfoCol = dataForCalculateKPI.text
        let projectRows = []

        for (let i = 0; i < pbRows.length; i++) {
            //строка актуальна в шаблоне
            if (templateRows.includes(pbRows[i].code)) {
                projectRows.push(pbRows[i].code)
            //строка была удалена из шаблона, требуется проверка на заполненность у строки колонки Info
            } else if (clientName in rowsWithFilledInfoCol &&
                projectName in rowsWithFilledInfoCol[clientName] &&
                projectCode in rowsWithFilledInfoCol[clientName][projectName] &&
                pbRows[i].code in rowsWithFilledInfoCol[clientName][projectName][projectCode]) {
                projectRows.push(pbRows[i].code)
            }
        }

        for (let i = 0; i < templateRows.length; i++) {
            //проверка на отсутвие в pb новой актуальной строки, которая была добавлена в шаблоне
            if (!projectRows.includes(templateRows[i])) {
                projectRows.push(templateRows[i])
            }
        }

        return projectRows
    }

    function fillData(createdPB, pbWithFilledTextValues, pbWithFilledBoolValues, createdSG, createdPS, filledPS,
                      dataForCalculateKPI, activePB, dataToCalculateFillByStages) {
        let resultPB = []
        let uncreatedPB = []
        let statistics = {
            sumKpi: 0.00,
            createdSG: 0,
            createdWorkflow: 0
        }
        let templateBoolDataForCalculateStagesFill = {}
        let boolDataForCalculateStagesFill = {}
        let templateTextDataForCalculateStagesFill = {}
        let textDataForCalculateStagesFill = {}

        for (let activePBIndex = 0; activePBIndex < activePB.length; activePBIndex++) {
            let activePBClientName = decodeURIComponent(activePB[activePBIndex].client_name)
            let activePBProjectName = decodeURIComponent(activePB[activePBIndex].project_name)
            let activePBProjectCode = decodeURIComponent(activePB[activePBIndex].project_code)

            if (!(activePBClientName in uncreatedPB)) {
                uncreatedPB[activePBClientName] = []
            }

            if (!(activePBProjectName in uncreatedPB[activePBClientName])) {
                uncreatedPB[activePBClientName][activePBProjectName] = []
            }

            if (!(activePBProjectCode in uncreatedPB[activePBClientName][activePBProjectName])) {
                uncreatedPB[activePBClientName][activePBProjectName][activePBProjectCode] = {
                    created: false
                }
            }
        }

        for (let i = 0; i < dataToCalculateFillByStages.text.length; i++) {
            let clientName = dataToCalculateFillByStages.text[i]["client_name"]
            let projectName = dataToCalculateFillByStages.text[i]["project_name"]
            let projectCode = dataToCalculateFillByStages.text[i]["project_code"]
            let rowCode = dataToCalculateFillByStages.text[i]["row_code"]
            let colCode = dataToCalculateFillByStages.text[i]["col_code"]
            let value = dataToCalculateFillByStages.text[i]["value"]

            if (!(clientName in textDataForCalculateStagesFill)) {
                textDataForCalculateStagesFill[clientName] = {}
            }

            if (!(projectName in textDataForCalculateStagesFill[clientName])) {
                textDataForCalculateStagesFill[clientName][projectName] = {}
            }

            if (!(projectCode in textDataForCalculateStagesFill[clientName][projectName])) {
                textDataForCalculateStagesFill[clientName][projectName][projectCode] = {}
            }

            if (!(rowCode in textDataForCalculateStagesFill[clientName][projectName][projectCode])) {
                textDataForCalculateStagesFill[clientName][projectName][projectCode][rowCode] = {}
            }

            textDataForCalculateStagesFill[clientName][projectName][projectCode][rowCode][colCode] = value
        }

        for (let i = 0; i < dataToCalculateFillByStages.bool.length; i++) {
            let clientName = dataToCalculateFillByStages.bool[i]["client_name"]
            let projectName = dataToCalculateFillByStages.bool[i]["project_name"]
            let projectCode = dataToCalculateFillByStages.bool[i]["project_code"]
            let rowCode = dataToCalculateFillByStages.bool[i]["row_code"]
            let colCode = dataToCalculateFillByStages.bool[i]["col_code"]
            let value = dataToCalculateFillByStages.bool[i]["value"]

            if (!(clientName in boolDataForCalculateStagesFill)) {
                boolDataForCalculateStagesFill[clientName] = {}
            }

            if (!(projectName in boolDataForCalculateStagesFill[clientName])) {
                boolDataForCalculateStagesFill[clientName][projectName] = {}
            }

            if (!(projectCode in boolDataForCalculateStagesFill[clientName][projectName])) {
                boolDataForCalculateStagesFill[clientName][projectName][projectCode] = {}
            }

            if (!(rowCode in boolDataForCalculateStagesFill[clientName][projectName][projectCode])) {
                boolDataForCalculateStagesFill[clientName][projectName][projectCode][rowCode] = {}
            }

            boolDataForCalculateStagesFill[clientName][projectName][projectCode][rowCode][colCode] = value
        }

        for (let i = 0; i < dataToCalculateFillByStages.templateBool.length; i++) {
            let rowCode = dataToCalculateFillByStages.templateBool[i]["row_code"]
            let colCode = dataToCalculateFillByStages.templateBool[i]["col_code"]
            let value = dataToCalculateFillByStages.templateBool[i]["value"]

            if (!(rowCode in templateBoolDataForCalculateStagesFill)) {
                templateBoolDataForCalculateStagesFill[rowCode] = {}
            }

            templateBoolDataForCalculateStagesFill[rowCode][colCode] = value
        }

        for (let i = 0; i < dataToCalculateFillByStages.templateText.length; i++) {
            let rowCode = dataToCalculateFillByStages.templateText[i]["row_code"]
            let colCode = dataToCalculateFillByStages.templateText[i]["col_code"]
            let value = dataToCalculateFillByStages.templateText[i]["value"]

            if (!(rowCode in templateTextDataForCalculateStagesFill)) {
                templateTextDataForCalculateStagesFill[rowCode] = {}
            }

            templateTextDataForCalculateStagesFill[rowCode][colCode] = value
        }

        for (let i = 0; i < createdPB.length; i++) {
            let clientName = createdPB[i]["client_name"]
            let projectName = createdPB[i]["project_name"]
            let projectCode = createdPB[i]["project_code"]
            let rows = getActualPBRows(clientName, projectName, projectCode, createdPB[i]["rows"], dataForCalculateKPI)
            let fillingByStages = calculateStagesFillIn(clientName, projectName, projectCode,
                rows, dataToCalculateFillByStages.checkboxColumns, templateBoolDataForCalculateStagesFill,
                boolDataForCalculateStagesFill, templateTextDataForCalculateStagesFill, textDataForCalculateStagesFill)

            if (clientName in uncreatedPB &&
                projectName in uncreatedPB[clientName] &&
                projectCode in uncreatedPB[clientName][projectName]) {
                uncreatedPB[clientName][projectName][projectCode].created = true
            }

            let value = {
                clientName: clientName,
                projectName: projectName,
                projectCode: projectCode,
                filled: false,
                fillingByStage: {
                    all: fillingByStages.all,
                    byStages: fillingByStages.stages
                },
                kpi: 0.00,
                sg: false,
                ps: {
                    value: false,
                    filled: false,
                    project: "",
                    order: "",
                    service: ""
                }
            }
            let countCheckedRows = 0
            let countFilledCells = 0
            let kpi = 0.00
            let roundedKpi = 0.00

            // if (clientName === /*"Akupara Games"*/"test" && projectCode === /*"6216"*/"test") {
            for (let j = 0; j < rows.length; j++) {
                let rowCode = rows[j]

                //существует запись на эту строку в таблице project_bible_bool
                if (clientName in dataForCalculateKPI.bool &&
                    projectName in dataForCalculateKPI.bool[clientName] &&
                    projectCode in dataForCalculateKPI.bool[clientName][projectName] &&
                    rowCode in dataForCalculateKPI.bool[clientName][projectName][projectCode]) {
                    let rowData = dataForCalculateKPI.bool[clientName][projectName][projectCode][rowCode]

                    if (Object.keys(rowData).length === 2) {
                        for (let column in rowData) {
                            let columnCheckValue = rowData[column]

                            //есть колонка со значением true в таблице project_bible_bool
                            if (columnCheckValue) {
                                countCheckedRows++

                                if (checkFilledInfoColumnInRow(dataForCalculateKPI.text, clientName, projectName, projectCode, rowCode)) {
                                    countFilledCells++
                                }

                                break
                            }
                        }
                    } else if (Object.keys(rowData).length === 1) {
                        let checkedRow = false
                        let columnFromProjectBibleBool = ""

                        for (let column in rowData) {
                            let columnCheckValue = rowData[column]

                            columnFromProjectBibleBool = column

                            //есть колонка со значением true в таблице project_bible_bool
                            if (columnCheckValue) {
                                countCheckedRows++

                                if (checkFilledInfoColumnInRow(dataForCalculateKPI.text, clientName, projectName, projectCode, rowCode)) {
                                    countFilledCells++
                                }

                                checkedRow = true

                                break
                            } /*else if (rowCode in dataForCalculateKPI.templateBool &&
                                    column in dataForCalculateKPI.templateBool[rowCode]) {
                                    if (dataForCalculateKPI.templateBool[rowCode][column]) {
                                        countCheckedRows++

                                        if (checkFilledInfoColumnInRow(dataForCalculateKPI.text, clientName, projectName, projectCode, rowCode)) {
                                            countFilledCells++
                                        }
                                    }
                                }*/
                        }

                        if (!checkedRow) {
                            if (rowCode in dataForCalculateKPI.templateBool) {
                                for (let column in dataForCalculateKPI.templateBool[rowCode]) {
                                    if (column !== columnFromProjectBibleBool &&
                                        dataForCalculateKPI.templateBool[rowCode][column]) {

                                        countCheckedRows++

                                        if (checkFilledInfoColumnInRow(dataForCalculateKPI.text, clientName, projectName, projectCode, rowCode)) {
                                            countFilledCells++
                                        }

                                        break
                                    }
                                }
                            }
                        }
                    }
                    //записи в таблице project_bible_bool не было, проверяем есть ли запись на эту строку в шаблоне
                } else if (rowCode in dataForCalculateKPI.templateBool) {
                    for (let column in dataForCalculateKPI.templateBool[rowCode]) {
                        let columnCheckValue = dataForCalculateKPI.templateBool[rowCode][column]

                        //есть колонка со значением true в шаблоне
                        if (columnCheckValue) {
                            countCheckedRows++

                            if (checkFilledInfoColumnInRow(dataForCalculateKPI.text, clientName, projectName, projectCode, rowCode)) {
                                countFilledCells++
                            }

                            break
                        }
                    }
                }
            }

            kpi = countFilledCells * 100 / countCheckedRows
            // }
            roundedKpi = +kpi.toFixed(2);
            value.kpi = roundedKpi;

            for (let j = 0; j < pbWithFilledTextValues.length; j++) {
                if (pbWithFilledTextValues[j]["client_name"] === clientName &&
                    pbWithFilledTextValues[j]["project_name"] === projectName &&
                    pbWithFilledTextValues[j]["project_code"] === projectCode) {
                    value.filled = true

                    break
                }
            }

            if (!value.filled) {
                for (let j = 0; j < pbWithFilledBoolValues.length; j++) {
                    if (pbWithFilledBoolValues[j]["client_name"] === clientName &&
                        pbWithFilledBoolValues[j]["project_name"] === projectName &&
                        pbWithFilledBoolValues[j]["project_code"] === projectCode) {
                        value.filled = true

                        break
                    }
                }
            }

            // if (value.filled) {
                for (let j = 0; j < createdSG.length; j++) {
                    if (createdSG[j]["client_name"] === clientName &&
                        createdSG[j]["project_name"] === projectName &&
                        createdSG[j]["project_code"] === projectCode) {
                        value.sg = true
                        statistics.sumKpi += roundedKpi
                        statistics.createdSG++

                        break
                    }
                }

                for (let j = 0; j < createdPS.length; j++) {
                    if (createdPS[j]["client_name"] === clientName &&
                        createdPS[j]["project_name"] === projectName &&
                        createdPS[j]["project_code"] === projectCode) {
                        let searchProject = createdPS[j]["project"]
                        let searchOrder = createdPS[j]["order"]
                        let searchService = createdPS[j]["service"]

                        value.ps.project = searchProject
                        value.ps.order = searchOrder
                        value.ps.service = searchService

                        for (let k = 0; k < filledPS.blockData.length; k++) {
                            if (searchProject === filledPS.blockData[k]["project"] &&
                                searchOrder === filledPS.blockData[k]["order"] &&
                                searchService === filledPS.blockData[k]["service"] &&
                                filledPS.blockData[k]["comment_value"] !== "") {
                                value.ps.filled = true

                                break
                            }
                        }

                        if (!value.ps.filled) {
                            for (let k = 0; k < filledPS.blockRowDataAccept.length; k++) {
                                if (searchProject === filledPS.blockRowDataAccept[k]["project"] &&
                                    searchOrder === filledPS.blockRowDataAccept[k]["order"] &&
                                    searchService === filledPS.blockRowDataAccept[k]["service"] &&
                                    filledPS.blockRowDataAccept[k]["accept_value"]) {
                                    value.ps.filled = true

                                    break
                                }
                            }
                        }

                        if (!value.ps.filled) {
                            for (let k = 0; k < filledPS.blockRowDataComment.length; k++) {
                                if (searchProject === filledPS.blockRowDataComment[k]["project"] &&
                                    searchOrder === filledPS.blockRowDataComment[k]["order"] &&
                                    searchService === filledPS.blockRowDataComment[k]["service"] &&
                                    filledPS.blockRowDataComment[k]["comment_value"] !== "") {
                                    value.ps.filled = true

                                    break
                                }
                            }
                        }

                        if (!value.ps.filled) {
                            for (let k = 0; k < filledPS.blockRowResponsible.length; k++) {
                                if (searchProject === filledPS.blockRowResponsible[k]["project"] &&
                                    searchOrder === filledPS.blockRowResponsible[k]["order"] &&
                                    searchService === filledPS.blockRowResponsible[k]["service"] &&
                                    filledPS.blockRowResponsible[k]["responsible"] !== "") {
                                    value.ps.filled = true

                                    break
                                }
                            }
                        }

                        value.ps.value = true
                        statistics.createdWorkflow++

                        break
                    }
                }

                resultPB.push(value)
            // }
        }

        setPBData(
            pbData.map(info => {
                info.data = resultPB
                info.uncreatedPB = uncreatedPB
                info.statistics = statistics

                return info
            })
        )

        setIsLoaded(true)
    }

    function calculateStagesFillIn(clientName, projectName, projectCode, rows, checkboxColumns, templateBool, bool, templateText, text) {
        let allRowsCount = 0
        let filledRowsCount = 0
        let stages = {}

        for (let i = 0; i < rows.length; i++) {
            let rowCode = rows[i]
            let isChecked = false

            for (let j = 0; j < checkboxColumns.length; j++) {
                let colCode = checkboxColumns[j].code
                let colName = checkboxColumns[j].name
                let colTemplate = checkboxColumns[j].template
                let colEditable = checkboxColumns[j].editable

                if (!isChecked) {
                    if (colTemplate && rowCode in templateBool && colCode in templateBool[rowCode]) {
                        isChecked = templateBool[rowCode][colCode]
                    }

                    if (colEditable &&
                        clientName in bool &&
                        projectName in bool[clientName] &&
                        projectCode in bool[clientName][projectName] &&
                        rowCode in bool[clientName][projectName][projectCode] &&
                        colCode in bool[clientName][projectName][projectCode][rowCode]) {
                        isChecked = bool[clientName][projectName][projectCode][rowCode][colCode]
                    }
                }
            }

            if (isChecked) {
                //columns:
                //Info = u6ea3ffqrpg054v-1erslv5r-1j8zo288jby
                //Stage = k4bzjhzsefhxarjxes8p1agk9z6w2mnzk0om
                let stageTitle = ""

                allRowsCount++

                if (rowCode in templateText) {
                    let rowData = templateText[rowCode]

                    if ("k4bzjhzsefhxarjxes8p1agk9z6w2mnzk0om" in rowData) {
                        stageTitle = rowData["k4bzjhzsefhxarjxes8p1agk9z6w2mnzk0om"]

                        if (!(stageTitle in stages)) {
                            stages[stageTitle] = {
                                allRowsCount: 0,
                                filledRowsCount: 0,
                                percentage: 0
                            }
                        }

                        stages[stageTitle].allRowsCount++

                        if (clientName in text &&
                            projectName in text[clientName] &&
                            projectCode in text[clientName][projectName] &&
                            rowCode in text[clientName][projectName][projectCode]) {
                            let rowData = text[clientName][projectName][projectCode][rowCode]

                            if ("u6ea3ffqrpg054v-1erslv5r-1j8zo288jby" in rowData) {
                                let valueWithoutSpaces = rowData["u6ea3ffqrpg054v-1erslv5r-1j8zo288jby"]

                                valueWithoutSpaces = valueWithoutSpaces.trim()
                                    .replace(/^\s+/, '')
                                    .replace(/\s+$/, '')
                                    .replace(/[\n\r]+/g, '')
                                    .replace(/\s{2,}/g, '')
                                    .replace(/^\s+|\s+$/, '')
                                    .replace(/&lt;br&gt;/g, '')
                                    .replace(/\<br\>/g, '');

                                if (valueWithoutSpaces !== "") {
                                    filledRowsCount++
                                    stages[stageTitle].filledRowsCount++
                                }
                            }
                        }
                    }
                }
            }
        }

        for (let stage in stages) {
            let percent = Math.round(stages[stage].filledRowsCount * 100 / stages[stage].allRowsCount)

            stages[stage].percentage = percent
        }

        if (clientName === "test" && projectName === "test" && projectCode === "test") {
            console.log("allRowsCount, filledRowsCount", allRowsCount, filledRowsCount, stages)
        }

        return {
            all: Math.round(filledRowsCount * 100 / allRowsCount),
            stages: stages
        }
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Error: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="row" style={styles.progressBar}>
                <div className="col-sm-12 center">
                    <h4>Loading...</h4>
                </div>
            </div>
        )
    } else {
        return(
            <div className="main-block-div-dark">
                <Tab.Container defaultActiveKey={"active"}>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" className="nav-custom-grey">
                                <Nav.Item>
                                    <Nav.Link eventKey={"active"}>
                                        Created
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"uncreated"}>
                                        Uncreated
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey={"active"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        <TableInfo rows={pbData[0].data} statistics={pbData[0].statistics}
                                                   stagesList={stagesList[0].data}/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={"uncreated"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        <UncreatedTableInfo rows={pbData[0].uncreatedPB}/>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        )
    }
}