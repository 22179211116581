import React, {useEffect, useState} from "react";
import {Button, Form, ProgressBar, Tabs} from "react-bootstrap";
import queryString from 'query-string';
import "../css/tableFixHeader.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagic} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "bootstrap";
import StyleGuideTabs from "./StyleGuideLinguistTabs";
import AddLinksAndImagesModal from "./AddLinksAndImagesModal";
import OpenInModalAddedImage from "./OpenInModalAddedImage";
import OpenInTooltipAddedImage from "./OpenInTooltipAddedImage";
import AlertModal from "./AlertModal";

const styles = {
    blockView: {
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '5px',
        margin: '0px'
    },

    progressBar: {
        margin: '10rem 10rem'
    },
}

export default function StyleGuide(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false)
    const [isLogOfNewBatches, setIsLogOfNewBatches] = useState(false)
    const [isCharacterCommunicationLoaded, setIsCharacterCommunicationLoaded] = useState(false)
    const [isLinguistCharacterCommunicationLoaded, setIsLinguistCharacterCommunicationLoaded] = useState(false)
    const [progressPercent, setProgressPercent] = useState(0)
    const [logOfNewBatches, setLogOfNewBatches] = useState(false)
    const [communicationBetweenCharacters, setCommunicationBetweenCharacters] = useState(false)
    const [languages, setLanguages] = useState([{
        data: []
    }])
    const [columns, setColumns] = useState([{
        data: []
    }])
    const [rows, setRows] = useState([{
        data: []
    }])
    const [characterCommunications, setCharacterCommunications] = useState([{
        characters: [],
        // baseStyleGuideData: [],
        templateData: [],
        languagesData: []
    }])
    const [logOfNewBatchesData, setLogOfNewBatchesData] = useState([{
        headers: [],
        rowsCount: 0,
        rowsData: []
    }])
    const [acceptedData, setAcceptedData] = useState([{
        accept: [],
        languagePreferences: [],
        communicationBetweenCharacters: []
    }])
    const [bufferedData, setBufferedData] = useState([{
        languagePreferences: [],
        communicationBetweenCharacters: []
    }])
    const [modalAddLinksAndImagesActive, setModalAddLinksAndImagesActive] = useState(false)
    const [modalOpenAddedImageActive, setModalOpenAddedImageActive] = useState(false)
    const [tooltipImageData, setTooltipImageData] = useState([{
        src: '',
        title: '',
        element: null,
        justUploaded: false
    }])
    const [modalAddLinksAndImagesData, setModalAddLinksAndImagesData] = useState([{
        links: [],
        images: [],
        bufferedImages: [],
        value: '',
        saveMethod: '',
        identificationData: []
    }])
    const [modalOpenAddedImageSrc, setModalOpenAddedImageSrc] = useState('')
    // const [submit, setSubmit] = useState(false)
    // let submitValue = true
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const cookieName = "autorization_log"
    const cookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + cookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    // const linguistCode = cookieMatches ? JSON.parse(decodeURIComponent(escape(window.atob(cookieMatches[1])))).login : window.location.href.split("/")[2] === "localhost:3000" ? /*"arisova.darya@yandex.ru"*/"darisova" : ""
    // const linguistName = cookieMatches ? JSON.parse(decodeURIComponent(escape(window.atob(cookieMatches[1])))).username : window.location.href.split("/")[2] === "localhost:3000" ? /*"arisova.darya@yandex.ru"*/"Test Darisova" : ""
    const linguistCode = cookieMatches ? JSON.parse(decodeURIComponent(escape(window.atob(cookieMatches[1])))).login : window.location.href.split("/")[2] === "localhost:3000" ? /*"arisova.darya@yandex.ru"*/"mgeletin" : ""
    const linguistName = cookieMatches ? JSON.parse(decodeURIComponent(escape(window.atob(cookieMatches[1])))).username : window.location.href.split("/")[2] === "localhost:3000" ? /*"arisova.darya@yandex.ru"*/"Test Test" : ""
    // const linguistCode = cookieMatches ? JSON.parse(decodeURIComponent(escape(window.atob(cookieMatches[1])))).login : window.location.href.split("/")[2] === "localhost:3000" ? /*"arisova.darya@yandex.ru"*/"hr" : ""
    // const linguistName = cookieMatches ? JSON.parse(decodeURIComponent(escape(window.atob(cookieMatches[1])))).username : window.location.href.split("/")[2] === "localhost:3000" ? /*"arisova.darya@yandex.ru"*/"Test HR" : ""
    const languagesToShow = cookieMatches ? JSON.parse(decodeURIComponent(escape(window.atob(cookieMatches[1])))).languages : window.location.href.split("/")[2] === "localhost:3000" ? [1, 15, 20, 25, 30] : []

    let cellAllCount = 0
    let cellOnCount = 0
    let languagesAllCount = 0
    let languageCount = 0
    let charactersDataCount = 0
    let linguistCharactersDataCount = 0
    const [modalAlertActive, setModalAlertActive] = useState(false)
    const [alertCase, setAlertCase] = useState(0)

    useEffect(async () => {
        document.title = projectName + " (SG)";

        console.log("setLinguistCode", linguistCode, "languagesToShow", languagesToShow, linguistCode && languagesToShow)

        if (checkQueryStringParams() && checkCookieEnabled()) {
            if (linguistCode && languagesToShow) {
                console.log("languagesToShow.length", languagesToShow.length)

                if (languagesToShow.length) {
                    console.log("getAutorizationCookie TRUE")
                    deleteRedirectCookie()

                    await fetch("/proxy/project_bible_template/projectBibleStyleGuide", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode
                        }),
                    })
                        .then(res => res.json())
                        .then(
                            async (styleGuideresult) => {
                                await fetch("/proxy/project_bible_template/projectBibleInfo", {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "clientName": clientName,
                                        "projectName": projectName,
                                        "projectCode": projectCode
                                    }),
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (result) => {
                                            await fetch("/proxy/project_bible_template/projectBibleTemplateRowsColumns", {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                }
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    async (resultTemplate) => {
                                                        await fetch("/proxy/project_bible_template/styleGuideGetCharacters", {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                                "clientName": clientName,
                                                                "projectName": projectName,
                                                                "projectCode": projectCode
                                                            }),
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                async (resultCharacters) => {
                                                                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesList", {
                                                                        method: 'GET',
                                                                        headers: {
                                                                            'Content-Type': 'application/json'
                                                                        },
                                                                    })
                                                                        .then(res => res.json())
                                                                        .then(
                                                                            async (resultStyleGuideLanguages) => {
                                                                                console.log("linguistCode", linguistCode)

                                                                                await fetch("/proxy/project_bible_template/styleGuideLinguistByCode", {
                                                                                    method: 'POST',
                                                                                    headers: {
                                                                                        'Content-Type': 'application/json'
                                                                                    },
                                                                                    body: JSON.stringify({
                                                                                        "clientName": clientName,
                                                                                        "projectName": projectName,
                                                                                        "projectCode": projectCode,
                                                                                        "linguistId": linguistCode
                                                                                    })
                                                                                })
                                                                                    .then(res => res.json())
                                                                                    .then(
                                                                                        async (resultStyleGuideLinguistByCode) => {
                                                                                            let notMainLanguagesList = [104, 105, 106, 119]

                                                                                            console.log("resultStyleGuideLinguistByCode", resultStyleGuideLinguistByCode)

                                                                                            if (!styleGuideresult[0].languages.includes(3) &&
                                                                                                languagesToShow.includes(3) &&
                                                                                                !languagesToShow.includes(119) /*&&
                                                                                                (languagesToShow.includes(8) || languagesToShow.includes(20) ||
                                                                                                    languagesToShow.includes(6))*/) {
                                                                                                languagesToShow.push(119)
                                                                                            }

                                                                                            if (!languagesToShow.includes(3)) {
                                                                                                for (let i = 0; i < notMainLanguagesList.length; i++) {
                                                                                                    if (languagesToShow.includes(notMainLanguagesList[i]) &&
                                                                                                        !styleGuideresult[0].languages.includes(notMainLanguagesList[i])) {
                                                                                                        languagesToShow.push(3)

                                                                                                        break;
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            if (!styleGuideresult[0].languages.some(ai => languagesToShow.includes(ai))) {
                                                                                                setAlertCase(1)
                                                                                                setModalAlertActive(true)
                                                                                            }

                                                                                            let otherLinguistsAcceptData = await getOtherLinguistsAcceptData(languagesToShow)

                                                                                            console.log("OTHER LINGUIST otherLinguistsAcceptData", otherLinguistsAcceptData)

                                                                                            if (resultStyleGuideLinguistByCode.length) {
                                                                                                await fetch("/proxy/project_bible_template/styleGuideLinguistUpdateLanguages", {
                                                                                                    method: 'POST',
                                                                                                    headers: {
                                                                                                        'Content-Type': 'application/json'
                                                                                                    },
                                                                                                    body: JSON.stringify({
                                                                                                        "clientName": clientName,
                                                                                                        "projectName": projectName,
                                                                                                        "projectCode": projectCode,
                                                                                                        "linguistId": linguistCode,
                                                                                                        "languages": languagesToShow
                                                                                                    })
                                                                                                })
                                                                                                    .then(res => res.json())
                                                                                                    .then(
                                                                                                        async (resultStyleGuideLinguistInsert) => {
                                                                                                            await fetch("/proxy/project_bible_template/getAllStyleGuideLinguistAcceptChanges", {
                                                                                                                method: 'POST',
                                                                                                                headers: {
                                                                                                                    'Content-Type': 'application/json'
                                                                                                                },
                                                                                                                body: JSON.stringify({
                                                                                                                    "clientName": clientName,
                                                                                                                    "projectName": projectName,
                                                                                                                    "projectCode": projectCode,
                                                                                                                    "linguistId": linguistCode
                                                                                                                })
                                                                                                            })
                                                                                                                .then(res => res.json())
                                                                                                                .then(
                                                                                                                    async (resultStyleGuideLinguistAllAcceptedChanges) => {
                                                                                                                        console.log("resultStyleGuideLinguistAllAcceptedChanges", resultStyleGuideLinguistAllAcceptedChanges)

                                                                                                                        for (let i = 0; i < resultStyleGuideLinguistAllAcceptedChanges.length; i++) {
                                                                                                                            console.log("LANGUAGE", resultStyleGuideLinguistAllAcceptedChanges[i].language_code)

                                                                                                                            setAcceptedData(
                                                                                                                                acceptedData.map(info => {
                                                                                                                                    info.accept[resultStyleGuideLinguistAllAcceptedChanges[i].language_code] =
                                                                                                                                        resultStyleGuideLinguistAllAcceptedChanges[i].accept
                                                                                                                                    info.languagePreferences[resultStyleGuideLinguistAllAcceptedChanges[i].language_code] =
                                                                                                                                        resultStyleGuideLinguistAllAcceptedChanges[i].accept_language_preferences
                                                                                                                                    info.communicationBetweenCharacters[resultStyleGuideLinguistAllAcceptedChanges[i].language_code] =
                                                                                                                                        resultStyleGuideLinguistAllAcceptedChanges[i].accept_communication_between_characters

                                                                                                                                    return info
                                                                                                                                })
                                                                                                                            )

                                                                                                                            setBufferedData(
                                                                                                                                bufferedData.map(info => {
                                                                                                                                    info.languagePreferences[resultStyleGuideLinguistAllAcceptedChanges[i].language_code] =
                                                                                                                                        resultStyleGuideLinguistAllAcceptedChanges[i].buffered_changed_language_preferences
                                                                                                                                    info.communicationBetweenCharacters[resultStyleGuideLinguistAllAcceptedChanges[i].language_code] =
                                                                                                                                        resultStyleGuideLinguistAllAcceptedChanges[i].buffered_changed_communication_between_characters

                                                                                                                                    return info
                                                                                                                                })
                                                                                                                            )
                                                                                                                        }

                                                                                                                        /*if (resultStyleGuideLinguistByCode[0].submit) {
                                                                                                                            setSubmit(true)

                                                                                                                            submitValue = true
                                                                                                                        }*/

                                                                                                                        fillData(styleGuideresult, result, resultTemplate, resultCharacters, resultStyleGuideLanguages, otherLinguistsAcceptData)
                                                                                                                    },
                                                                                                                    (error) => {
                                                                                                                        setIsLoaded(true);
                                                                                                                        setError(error);
                                                                                                                    }
                                                                                                                )
                                                                                                        },
                                                                                                        (error) => {
                                                                                                            setIsLoaded(true);
                                                                                                            setError(error);
                                                                                                        }
                                                                                                    )
                                                                                            } else {
                                                                                                await fetch("/proxy/project_bible_template/styleGuideLinguistInsert", {
                                                                                                    method: 'POST',
                                                                                                    headers: {
                                                                                                        'Content-Type': 'application/json'
                                                                                                    },
                                                                                                    body: JSON.stringify({
                                                                                                        "clientName": clientName,
                                                                                                        "projectName": projectName,
                                                                                                        "projectCode": projectCode,
                                                                                                        "linguistId": linguistCode,
                                                                                                        "linguist": linguistName,
                                                                                                        "languages": languagesToShow
                                                                                                    })
                                                                                                })
                                                                                                    .then(res => res.json())
                                                                                                    .then(
                                                                                                        async (resultStyleGuideLinguistInsert) => {
                                                                                                            fillData(styleGuideresult, result, resultTemplate, resultCharacters, resultStyleGuideLanguages, otherLinguistsAcceptData)
                                                                                                        },
                                                                                                        (error) => {
                                                                                                            setIsLoaded(true);
                                                                                                            setError(error);
                                                                                                        }
                                                                                                    )
                                                                                            }
                                                                                        },
                                                                                        (error) => {
                                                                                            setIsLoaded(true);
                                                                                            setError(error);
                                                                                        }
                                                                                    )
                                                                            },
                                                                            (error) => {
                                                                                setIsLoaded(true);
                                                                                setError({
                                                                                    message: "Не удалось получить данные о языках стайлгайда. Пожалуйста перезагрузите страницу, чтобы запустить процесс снова"
                                                                                });
                                                                            }
                                                                        )
                                                                },
                                                                (error) => {
                                                                    setIsLoaded(true);
                                                                    setError(error);
                                                                }
                                                            )
                                                    },
                                                    (error) => {
                                                        setIsLoaded(true);
                                                        setError(error);
                                                    }
                                                )
                                        },
                                        (error) => {
                                            setIsLoaded(true);
                                            setError(error);
                                        }
                                    )
                            },
                            (error) => {
                                setIsLoaded(true);
                                setError(error);
                            }
                        )
                } else {
                    setAlertCase(0)
                    setModalAlertActive(true)
                }
            } else {
                setRedirect(true)
            }
        }
    }, [])

    function setRedirect(isSetRedirectCookie) {
        if (isSetRedirectCookie) {
            setRedirectCookie()
        }

        window.location.replace('https://portal.allcorrectgames.com');
    }

    function checkQueryStringParams() {
        if (!clientName || !projectName || !projectCode) {
            alert("Missed URL parameters")

            return false
        }

        return true
    }

    function checkCookieEnabled() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        console.log("Check Cookie Enabled", matches)

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        }

        return true
    }

    async function fillData(styleGuideresult, result, resultTemplate, resultCharacters, allStyleGuideLanguages, otherLinguistsAcceptData) {
        let styleGuideLanguages = styleGuideresult[0]["languages"]
        let rowsMap = new Map()
        let templateRowsMap = new Map()

        console.log("general tab data", result)
        console.log("projectBibleStyleGuide", styleGuideresult)
        console.log("allStyleGuideLanguages", allStyleGuideLanguages)

        setLogOfNewBatches(styleGuideresult[0]["log_of_new_batches"])
        setCommunicationBetweenCharacters(styleGuideresult[0]["communication_between_characters"])

        setLanguages(
            languages.map(value => {
                for (let i = 0; i < allStyleGuideLanguages.languages.length; i++) {
                    if (styleGuideLanguages.includes(allStyleGuideLanguages.languages[i].language_code) &&
                        languagesToShow.includes(allStyleGuideLanguages.languages[i].language_code)) {
                        value.data.push({
                            title: allStyleGuideLanguages.languages[i].language,
                            code: allStyleGuideLanguages.languages[i].language_code,
                            // value: styleGuideresult[0].languages.includes(allStyleGuideLanguages.languages[i].language_code),
                            data: []
                        })

                        setCharacterCommunications(
                            characterCommunications.map(info => {
                                /*info.templateData[allStyleGuideLanguages.languages[i].language_code] = {
                                    // title: value.title,
                                    data: []
                                }*/

                                info.languagesData[allStyleGuideLanguages.languages[i].language_code] = {
                                    // title: value.title,
                                    data: [],
                                    otherLinguistAcceptedData: [],
                                    currentLinguistData: []
                                }

                                return info
                            })
                        )

                        languagesAllCount++
                        console.log("languagesAllCount", languagesAllCount)
                    }
                }

                return value
            })
        )

        for (let i = 0; i < resultCharacters.length; i++) {
            let characterId = +resultCharacters[i].id
            let characterNum = resultCharacters[i].num
            let characterName = resultCharacters[i].name

            setCharacterCommunications(
                characterCommunications.map(info => {
                    info.characters[characterId] = {
                        "id": characterId,
                        "num": characterNum,
                        "name": characterName
                    }

                    info.templateData[characterNum - 1] = {
                        "id": characterId,
                        "name": characterName,
                        "data": []
                    }

                    return info
                })
            )
        }

        console.log("set languages", languages)

        resultTemplate.rows.map(value => {
            templateRowsMap.set(value.code, value.code)
        })

        result[0].rows.map(value => {
            rowsMap.set(value.code, value.code)
        })

        templateRowsMap.forEach((value, key) => {
            if (!rowsMap.has(key)) {
                result[0].rows.push({
                    code: key
                })
            }
        });

        result[0].columns.map(value => {
            if (value["col_styleguide"] || value["col_styleguide_filter"]) {
                setColumns(
                    columns.map(info => {
                        info.data.push({
                            "code": value.code,
                            "name": value.name,
                            "type": value.type,
                            "editable": value.editable,
                            "template": value.template,
                            "col_styleguide": value["col_styleguide"],
                            "col_styleguide_filter": value["col_styleguide_filter"],
                            "filter": {
                                "show": true,
                                "showAll": {
                                    "value": true,
                                    "showFilledCells": true,
                                    "showEmptyCells": true,
                                    "data": new Map(),
                                    "selectedData": []
                                }
                            }
                        });

                        return info
                    })
                )
            }

            return value
        })

        result[0].rows.map(value => {
            setRows(
                rows.map(info => {
                    info.data.push({
                        "code": value.code,
                        "show": true,
                        "data": [],
                        "template": []
                    });

                    return info
                })
            )

            return value
        })

        cellAllCount = columns[0].data.length * rows[0].data.length

        fillDataForHeaders()
        fillDataForLanguages(otherLinguistsAcceptData)

        if (styleGuideresult[0]["log_of_new_batches"]) {
            fillLogOfNewBatches()
        }

        if (styleGuideresult[0]["communication_between_characters"]) {
            await fillCharacterCommunication()
            await fillCharacterCommunicationFromLinguists()
        }
    }

    async function getOtherLinguistsAcceptData(linguistLanguages) {
        let otherLinguistData = []

        await fetch("/proxy/project_bible_template/getStyleGuideOtherLinguistAcceptChanges", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguistCode
            })
        })
            .then(res => res.json())
            .then(
                async (acceptedChanges) => {
                    for (let i = 0; i < acceptedChanges.length; i++) {
                        let otherLinguistLanguageCode = acceptedChanges[i]["language_code"]
                        let otherLinguistId = acceptedChanges[i]["linguist_id"]
                        let otherLinguistLanguagePreferences = acceptedChanges[i]["accept_language_preferences"]
                        let otherLinguistCommunicationBetweenCharacters = acceptedChanges[i]["communication_between_characters"]

                        if (linguistLanguages.includes(otherLinguistLanguageCode)) {
                            if (!(otherLinguistLanguageCode in otherLinguistData)) {
                                otherLinguistData[otherLinguistLanguageCode] = []
                            }

                            otherLinguistData[otherLinguistLanguageCode][otherLinguistId] = []
                            otherLinguistData[otherLinguistLanguageCode][otherLinguistId]["language_preferences"] = otherLinguistLanguagePreferences
                            otherLinguistData[otherLinguistLanguageCode][otherLinguistId]["communication_between_characters"] = otherLinguistCommunicationBetweenCharacters
                        }
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

        return otherLinguistData
    }

    function getAutorizationCookie() {
        console.log("cookieMatches", cookieMatches)

        if (cookieMatches) {
            // linguistCode = decodeURIComponent(escape(window.atob(matches[1])))
            // languagesToShow = JSON.parse(decodeURIComponent(queryStringParams.languages))

            console.log("setLinguistCode", linguistCode, "languagesToShow", languagesToShow)
            deleteRedirectCookie()

            return true
        }

        return false
    }

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(unescape(encodeURIComponent(window.location.href)))
        // let date = new Date(Date.now() + 86400e3);
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        console.log("ENCODED LINK", cookieValue, options)

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        console.log("setRedirectCookie", cookieValue, updatedCookie)

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;

        console.log("DELETE COOKIE", updatedCookie)
    }

    function fillDataForHeaders() {
        setRows(rows.map(rowsData => {
            rowsData.data.map(async row => {
                columns.map(columnsData => {
                    columnsData.data.map(async column => {
                        let queryLinkTemplate = '/proxy/project_bible_template/'
                        let queryLinkEditable = '/proxy/project_bible_template/'

                        if (column.type === "input") {
                            // queryLinkTemplate += 'projectBibleTemplateTextByName'
                            queryLinkTemplate += 'projectBibleTemplateTextByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellTextByName'
                        } else if (column.type === "checkbox") {
                            // queryLinkTemplate += 'projectBibleTemplateBoolByName'
                            queryLinkTemplate += 'projectBibleTemplateBoolByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellBoolByName'
                        } else if (column.type === "tags_list") {
                            queryLinkTemplate += 'projectBibleTemplateTagJsonByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellBoolByName'
                        }

                        if (column.template && column.editable) {
                            await fetch(queryLinkTemplate, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultTemplate) => {
                                        await fetch(queryLinkEditable, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                "clientName": clientName,
                                                "projectName": projectName,
                                                "projectCode": projectCode,
                                                "rowCode": row.code,
                                                "colCode": column.code,
                                            })
                                        })
                                            .then(res => res.json())
                                            .then(
                                                async (resultEditable) => {
                                                    let value

                                                    if (resultEditable.length) {
                                                        value = resultEditable[0].value
                                                    } else {
                                                        if (resultTemplate.length) {
                                                            value = resultTemplate[0].value
                                                        } else if (column.type === "input") {
                                                            value = ""
                                                        } else if (column.type === "checkbox") {
                                                            value = false
                                                        } else if (column.type === "tags_list") {
                                                            value = []
                                                        }
                                                    }

                                                    if (column["col_styleguide_filter"]) {
                                                        if (column.type === "input") {
                                                            if (value === "") {
                                                                row.show = false
                                                            }
                                                        } else if (column.type === "checkbox") {
                                                            if (value === false) {
                                                                row.show = false
                                                            }
                                                        }
                                                    }

                                                    if (column["col_styleguide"]) {
                                                        row.data[column.code] = value
                                                    }

                                                    cellOnCount++
                                                    console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                                    setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                                    if (cellOnCount === cellAllCount && !error) {
                                                        setIsLoaded(true);
                                                        console.log("IS LOADED", isLoaded)
                                                    }
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        } else if (column.template) {
                            await fetch(queryLinkTemplate, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {
                                        let value

                                        if (result.length) {
                                            value = result[0].value
                                        } else if (column.type === "input") {
                                            value = ""
                                        } else if (column.type === "checkbox") {
                                            value = false
                                        } else if (column.type === "tags_list") {
                                            value = []
                                        }

                                        if (column["col_styleguide_filter"]) {
                                            if (column.type === "input") {
                                                if (value === "") {
                                                    row.show = false
                                                }
                                            } else if (column.type === "checkbox") {
                                                if (value === false) {
                                                    row.show = false
                                                }
                                            }
                                        }

                                        if (column["col_styleguide"]) {
                                            row.data[column.code] = value
                                        }

                                        cellOnCount++
                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                        if (cellOnCount === cellAllCount && !error) {
                                            setIsLoaded(true);
                                            console.log("IS LOADED", isLoaded)
                                        }
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        } else if (column.editable) {
                            await fetch(queryLinkEditable, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {
                                        let value

                                        if (result.length) {
                                            value = result[0].value
                                        } else if (column.type === "input") {
                                            value = ""
                                        } else if (column.type === "checkbox") {
                                            value = false
                                        } else if (column.type === "tags_list") {
                                            value = []
                                        }

                                        if (column["col_styleguide_filter"]) {
                                            if (column.type === "input") {
                                                if (value === "") {
                                                    row.show = false
                                                }
                                            } else if (column.type === "checkbox") {
                                                if (value === false) {
                                                    row.show = false
                                                }
                                            }
                                        }

                                        if (column["col_styleguide"]) {
                                            row.data[column.code] = value
                                        }

                                        cellOnCount++
                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                        if (cellOnCount === cellAllCount && !error) {
                                            setIsLoaded(true);
                                            console.log("IS LOADED", isLoaded)
                                        }
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        }
                    })

                    return columnsData
                })
            })

            return rowsData
        }))
    }

    async function fillDataForLanguages(otherLinguistsAcceptData) {
        console.log("fillDataForLanguages !!!", languages)

        setLanguages(
            languages.map(info => {
                info.data.map(async language => {
                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesListByCode", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "code": language.code,
                            "projectManagementInfo": false
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLanguageTemplate) => {
                                await fetch("/proxy/project_bible_template/styleGuideGetLanguagesExtraRows", {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "clientName": clientName,
                                        "projectName": projectName,
                                        "projectCode": projectCode,
                                        "languageCode": language.code
                                    })
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (resultLanguageExtraRows) => {
                                            await fetch("/proxy/project_bible_template/styleGuideLinguistGetLanguagesExtraRows", {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    "clientName": clientName,
                                                    "projectName": projectName,
                                                    "projectCode": projectCode,
                                                    "id": linguistCode,
                                                    "languageCode": language.code
                                                })
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    async (resultLinguistLanguageExtraRows) => {
                                                        await fetch("/proxy/project_bible_template/styleGuideOtherLinguistGetLanguagesExtraRows", {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                                "clientName": clientName,
                                                                "projectName": projectName,
                                                                "projectCode": projectCode,
                                                                "id": linguistCode,
                                                                "languageCode": language.code
                                                            })
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                async (resultOtherLinguistLanguageExtraRows) => {
                                                                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesFilledData", {
                                                                        method: 'POST',
                                                                        headers: {
                                                                            'Content-Type': 'application/json'
                                                                        },
                                                                        body: JSON.stringify({
                                                                            "clientName": clientName,
                                                                            "projectName": projectName,
                                                                            "projectCode": projectCode,
                                                                            "languageCode": language.code
                                                                        })
                                                                    })
                                                                        .then(res => res.json())
                                                                        .then(
                                                                            async (resultLanguageFilledData) => {
                                                                                await fetch("/proxy/project_bible_template/styleGuideLinguistGetLanguagesFilledData", {
                                                                                    method: 'POST',
                                                                                    headers: {
                                                                                        'Content-Type': 'application/json'
                                                                                    },
                                                                                    body: JSON.stringify({
                                                                                        "clientName": clientName,
                                                                                        "projectName": projectName,
                                                                                        "projectCode": projectCode,
                                                                                        "id": linguistCode,
                                                                                        "languageCode": language.code
                                                                                    })
                                                                                })
                                                                                    .then(res => res.json())
                                                                                    .then(
                                                                                        async (resultLinguistLanguageFilledData) => {
                                                                                            await fetch("/proxy/project_bible_template/styleGuideOtherLinguistGetLanguagesFilledData", {
                                                                                                method: 'POST',
                                                                                                headers: {
                                                                                                    'Content-Type': 'application/json'
                                                                                                },
                                                                                                body: JSON.stringify({
                                                                                                    "clientName": clientName,
                                                                                                    "projectName": projectName,
                                                                                                    "projectCode": projectCode,
                                                                                                    "id": linguistCode,
                                                                                                    "languageCode": language.code
                                                                                                })
                                                                                            })
                                                                                                .then(res => res.json())
                                                                                                .then(
                                                                                                    async (resultOtherLinguistLanguageFilledData) => {
                                                                                                        console.log("fillDataForLanguages", resultLanguageTemplate, resultLanguageFilledData)

                                                                                                        let pushedRows = []
                                                                                                        let acceptedDataForLanguage = {}
                                                                                                        let bufferedDataForLanguage = {}
                                                                                                        let otherLinguistAcceptedFilledData = []
                                                                                                        let currentLinguistAcceptedFilledData = []
                                                                                                        let currentLinguistExtraRows = []

                                                                                                        if (language.code in acceptedData[0].languagePreferences) {
                                                                                                            acceptedDataForLanguage = acceptedData[0].languagePreferences[language.code]
                                                                                                        }

                                                                                                        if (language.code in bufferedData[0].languagePreferences) {
                                                                                                            bufferedDataForLanguage = bufferedData[0].languagePreferences[language.code]
                                                                                                        }

                                                                                                        console.log("acceptedDataForLanguage and bufferedDataForLanguage", language.code,
                                                                                                            acceptedDataForLanguage, Object.keys(acceptedDataForLanguage).length,
                                                                                                            bufferedDataForLanguage, Object.keys(bufferedDataForLanguage).length,
                                                                                                            resultLinguistLanguageFilledData)

                                                                                                        for (let i = 0; i < resultLanguageExtraRows.length; i++) {
                                                                                                            let rowToPush = []

                                                                                                            for (let j = 0; j < resultLanguageExtraRows[i].columns.length; j++) {
                                                                                                                rowToPush.push({
                                                                                                                    "code": resultLanguageExtraRows[i].columns[j],
                                                                                                                    "text": "",
                                                                                                                    "styleGuideEditable": true,
                                                                                                                    "linguistEditable": resultLanguageExtraRows[i].linguist_editable
                                                                                                                })
                                                                                                            }

                                                                                                            if (resultLanguageExtraRows[i].block in resultLanguageTemplate[0]) {
                                                                                                                resultLanguageTemplate[0][resultLanguageExtraRows[i].block].data.push({
                                                                                                                    "row": rowToPush,
                                                                                                                    "code": resultLanguageExtraRows[i]["row_code"],
                                                                                                                    "colspan": resultLanguageExtraRows[i].colspan
                                                                                                                })
                                                                                                            }

                                                                                                            pushedRows.push(resultLanguageExtraRows[i]["row_code"])
                                                                                                        }

                                                                                                        for (let i = 0; i < resultOtherLinguistLanguageExtraRows.length; i++) {
                                                                                                            let otherLinguistExtraRowData = resultOtherLinguistLanguageExtraRows[i]
                                                                                                            let otherLinguisId = otherLinguistExtraRowData["linguist_id"]
                                                                                                            let otherLinguistBlock = otherLinguistExtraRowData["block"]
                                                                                                            let otherLinguistRow = otherLinguistExtraRowData["row_code"]
                                                                                                            let otherLinguistColumns = otherLinguistExtraRowData["columns"]
                                                                                                            let otherLinguistEditable = otherLinguistExtraRowData["linguist_editable"]
                                                                                                            let otherLinguistColspan = otherLinguistExtraRowData["colspan"]
                                                                                                            let columnsData = []

                                                                                                            if (!(otherLinguisId in otherLinguistAcceptedFilledData)) {
                                                                                                                otherLinguistAcceptedFilledData[otherLinguisId] = []
                                                                                                            }

                                                                                                            if (!(otherLinguistBlock in otherLinguistAcceptedFilledData[otherLinguisId])) {
                                                                                                                otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock] = {
                                                                                                                    extra: [], //его экстра строки
                                                                                                                    otherRows: [] //какие-то другие любые строки
                                                                                                                }
                                                                                                            }

                                                                                                            for (let j = 0; j < otherLinguistColumns.length; j++) {
                                                                                                                columnsData[otherLinguistColumns[j]] = ""
                                                                                                                /*columnsData[otherLinguistColumns[j]] = {
                                                                                                                    value: ""
                                                                                                                }*/
                                                                                                            }

                                                                                                            otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock].extra[otherLinguistRow] = {
                                                                                                                columns: columnsData, //для получения значения колонки по ключу
                                                                                                                columnsSequence: otherLinguistColumns,  //последовательность колонок
                                                                                                                linguist_editable: otherLinguistEditable,
                                                                                                                colspan: otherLinguistColspan
                                                                                                            }
                                                                                                        }

                                                                                                        for (let i = 0; i < resultLinguistLanguageFilledData.length; i++) {
                                                                                                            let currentLinguistFilledData = resultLinguistLanguageFilledData[i]
                                                                                                            let currentLinguistBlock = currentLinguistFilledData["block"]
                                                                                                            let currentLinguistRow = currentLinguistFilledData["row_code"]
                                                                                                            let currentLinguistColumn = currentLinguistFilledData["col_code"]
                                                                                                            let currentLinguistValue = currentLinguistFilledData["value"]

                                                                                                            if (!(currentLinguistBlock in currentLinguistAcceptedFilledData)) {
                                                                                                                currentLinguistAcceptedFilledData[currentLinguistBlock] = []
                                                                                                            }

                                                                                                            if (!(currentLinguistRow in currentLinguistAcceptedFilledData[currentLinguistBlock])) {
                                                                                                                currentLinguistAcceptedFilledData[currentLinguistBlock][currentLinguistRow] = []
                                                                                                            }

                                                                                                            currentLinguistAcceptedFilledData[currentLinguistBlock][currentLinguistRow][currentLinguistColumn] = currentLinguistValue
                                                                                                        }

                                                                                                        console.log("CURRENT LINGUIST FILLED DATA", currentLinguistAcceptedFilledData)

                                                                                                        for (let i = 0; i < resultOtherLinguistLanguageFilledData.length; i++) {
                                                                                                            let otherLinguistFilledData = resultOtherLinguistLanguageFilledData[i]
                                                                                                            let otherLinguisId = otherLinguistFilledData["linguist_id"]
                                                                                                            let otherLinguistBlock = otherLinguistFilledData["block"]
                                                                                                            let otherLinguistRow = otherLinguistFilledData["row_code"]
                                                                                                            let otherLinguistColumn = otherLinguistFilledData["col_code"]
                                                                                                            let otherLinguistValue = otherLinguistFilledData["value"]

                                                                                                            if (!(otherLinguisId in otherLinguistAcceptedFilledData)) {
                                                                                                                otherLinguistAcceptedFilledData[otherLinguisId] = []
                                                                                                            }

                                                                                                            if (!(otherLinguistBlock in otherLinguistAcceptedFilledData[otherLinguisId])) {
                                                                                                                // otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock] = []
                                                                                                                otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock] = {
                                                                                                                    extra: [],
                                                                                                                    otherRows: []
                                                                                                                }
                                                                                                            }

                                                                                                            /*if (!(otherLinguistRow in otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock])) {
                                                                                                                otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock][otherLinguistRow] = []
                                                                                                            }

                                                                                                            otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock][otherLinguistRow][otherLinguistColumn] = otherLinguistValue*/

                                                                                                            /*if (otherLinguisId in otherLinguistAcceptedFilledData &&
                                                                                                                otherLinguistBlock in otherLinguistAcceptedFilledData[otherLinguisId]) {*/
                                                                                                                let otherLinguistBlockData = otherLinguistAcceptedFilledData[otherLinguisId][otherLinguistBlock]

                                                                                                                //если это экстра строка, созданная этим другим лингвистом
                                                                                                                if (otherLinguistRow in otherLinguistBlockData.extra) {
                                                                                                                    let otherLinguistColumnsData = otherLinguistBlockData.extra[otherLinguistRow].columns

                                                                                                                    for (let otherLinguistConcreteColumn in otherLinguistColumnsData) {
                                                                                                                        if (otherLinguistConcreteColumn === otherLinguistColumn) {
                                                                                                                            otherLinguistColumnsData[otherLinguistConcreteColumn] = otherLinguistValue
                                                                                                                            /*otherLinguistColumnsData[otherLinguistConcreteColumn] = {
                                                                                                                                value: otherLinguistValue
                                                                                                                            }*/
                                                                                                                        }
                                                                                                                    }
                                                                                                                } else {
                                                                                                                    if (!(otherLinguistRow in otherLinguistBlockData.otherRows)) {
                                                                                                                        otherLinguistBlockData.otherRows[otherLinguistRow] = []
                                                                                                                    }

                                                                                                                    otherLinguistBlockData.otherRows[otherLinguistRow][otherLinguistColumn] = otherLinguistValue
                                                                                                                    /*otherLinguistBlockData.otherRows[otherLinguistRow][otherLinguistColumn] = {
                                                                                                                        value: otherLinguistValue
                                                                                                                    }*/
                                                                                                                }
                                                                                                            /*}*/
                                                                                                        }

                                                                                                        for (let i = 0; i < resultLinguistLanguageExtraRows.length; i++) {
                                                                                                            if (!(resultLinguistLanguageExtraRows[i].block in currentLinguistExtraRows)) {
                                                                                                                currentLinguistExtraRows[resultLinguistLanguageExtraRows[i].block] = []
                                                                                                            }

                                                                                                            if (!(resultLinguistLanguageExtraRows[i]["row_code"] in currentLinguistExtraRows[resultLinguistLanguageExtraRows[i].block])) {
                                                                                                                currentLinguistExtraRows[resultLinguistLanguageExtraRows[i].block][resultLinguistLanguageExtraRows[i]["row_code"]] = []
                                                                                                            }

                                                                                                            currentLinguistExtraRows[resultLinguistLanguageExtraRows[i].block][resultLinguistLanguageExtraRows[i]["row_code"]] = {
                                                                                                                "columnsSequence": resultLinguistLanguageExtraRows[i].columns,
                                                                                                                "linguistEditable": resultLinguistLanguageExtraRows[i].linguist_editable,
                                                                                                                "code": resultLinguistLanguageExtraRows[i]["row_code"],
                                                                                                                "colspan": resultLinguistLanguageExtraRows[i].colspan
                                                                                                            }
                                                                                                        }

                                                                                                        console.log("currentLinguistExtraRows", language.code, currentLinguistExtraRows)

                                                                                                        if (language.code in otherLinguistsAcceptData) {
                                                                                                            for (let otherLinguist in otherLinguistsAcceptData[language.code]) {
                                                                                                                let otherLinguistData = otherLinguistsAcceptData[language.code][otherLinguist].language_preferences

                                                                                                                for (let otherLinguistBlock in otherLinguistData) {
                                                                                                                    let defaultRowsData = resultLanguageTemplate[0][otherLinguistBlock].data

                                                                                                                    //переписываем дефолтные строки, если они были приняты у какого-то лингвиста
                                                                                                                    for (let i = 0; i < defaultRowsData.length; i++) {
                                                                                                                        let otherLinguistDefaultRowCode = defaultRowsData[i].code

                                                                                                                        if (otherLinguistData[otherLinguistBlock].default.includes(otherLinguistDefaultRowCode)) {
                                                                                                                            let defaultRowData = defaultRowsData[i].row
                                                                                                                            let rewriteStringData = otherLinguistAcceptedFilledData[otherLinguist][otherLinguistBlock].otherRows[otherLinguistDefaultRowCode]

                                                                                                                            for (let j = 0; j < defaultRowData.length; j++) {
                                                                                                                                if (defaultRowData[j].code in rewriteStringData) {
                                                                                                                                    defaultRowData[j].text = rewriteStringData[defaultRowData[j].code]/*.value*/
                                                                                                                                    defaultRowData[j]["filledFromOtherLinguist"] = true
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    //добавляем принятые экстра строки другого лингвиста
                                                                                                                    for (let i = 0; i < otherLinguistData[otherLinguistBlock].extra.length; i++) {
                                                                                                                        let otherLinguistExtraRowCode = otherLinguistData[otherLinguistBlock].extra[i]
                                                                                                                        let rowToPush = []
                                                                                                                        let otherLinguistExtraRowData = otherLinguistAcceptedFilledData[otherLinguist][otherLinguistBlock].extra[otherLinguistExtraRowCode]

                                                                                                                        for (let j = 0; j < otherLinguistExtraRowData.columnsSequence.length; j++) {
                                                                                                                            let columnData = otherLinguistExtraRowData.columns[otherLinguistExtraRowData.columnsSequence[j]]

                                                                                                                            rowToPush.push({
                                                                                                                                "code": otherLinguistExtraRowData.columnsSequence[j],
                                                                                                                                "text": columnData/*.value*/,
                                                                                                                                "styleGuideEditable": true,
                                                                                                                                "linguistEditable": otherLinguistExtraRowData["linguist_editable"]
                                                                                                                            })
                                                                                                                        }

                                                                                                                        resultLanguageTemplate[0][otherLinguistBlock].data.push({
                                                                                                                            "row": rowToPush,
                                                                                                                            "code": otherLinguistExtraRowCode,
                                                                                                                            "colspan": otherLinguistExtraRowData.colspan,
                                                                                                                            "linguistExtraRow": true,
                                                                                                                            "extraFromOtherLinguist": otherLinguist
                                                                                                                        })

                                                                                                                        pushedRows.push(otherLinguistExtraRowCode)
                                                                                                                    }

                                                                                                                    //добавляем принятые экстра строки, которые были созданы другим лингвистом, отличным от рассматриваемого другого лингвиста
                                                                                                                    if ("otherLinguistExtra" in otherLinguistData[otherLinguistBlock]) {
                                                                                                                        for (let i = 0; i < otherLinguistData[otherLinguistBlock].otherLinguistExtra.length; i++) {
                                                                                                                            let acceptRowFromOtherLinguist = otherLinguistData[otherLinguistBlock].otherLinguistExtra[i]

                                                                                                                            for (let j = 0; j < acceptRowFromOtherLinguist.rows.length; j++) {
                                                                                                                                let rowToPush = []

                                                                                                                                if (acceptRowFromOtherLinguist.linguist === linguistCode) {
                                                                                                                                    let otherLinguistExtraRowData = currentLinguistExtraRows[otherLinguistBlock][acceptRowFromOtherLinguist.rows[j]]

                                                                                                                                    console.log("otherLinguistExtraRowData 1", otherLinguistExtraRowData, otherLinguistBlock, acceptRowFromOtherLinguist.rows[j], otherLinguistAcceptedFilledData)

                                                                                                                                    for (let k = 0; k < otherLinguistExtraRowData.columnsSequence.length; k++) {
                                                                                                                                        if (otherLinguistExtraRowData.columnsSequence[k] in otherLinguistAcceptedFilledData[otherLinguist][otherLinguistBlock].otherRows[acceptRowFromOtherLinguist.rows[j]]) {
                                                                                                                                            let columnData = otherLinguistAcceptedFilledData[otherLinguist][otherLinguistBlock].otherRows[acceptRowFromOtherLinguist.rows[j]][otherLinguistExtraRowData.columnsSequence[k]]

                                                                                                                                            rowToPush.push({
                                                                                                                                                "code": otherLinguistExtraRowData.columnsSequence[k],
                                                                                                                                                "text": columnData,
                                                                                                                                                "styleGuideEditable": true,
                                                                                                                                                "linguistEditable": otherLinguistExtraRowData["linguist_editable"],
                                                                                                                                                "filledByOtherLinguist": true
                                                                                                                                            })
                                                                                                                                        } else {
                                                                                                                                            rowToPush.push({
                                                                                                                                                "code": otherLinguistExtraRowData.columnsSequence[k],
                                                                                                                                                "text": "",
                                                                                                                                                "styleGuideEditable": true,
                                                                                                                                                "linguistEditable": otherLinguistExtraRowData["linguist_editable"]
                                                                                                                                            })
                                                                                                                                        }

                                                                                                                                        resultLanguageTemplate[0][otherLinguistBlock].data.push({
                                                                                                                                            "row": rowToPush,
                                                                                                                                            "code": acceptRowFromOtherLinguist.rows[j],
                                                                                                                                            "colspan": otherLinguistExtraRowData.colspan,
                                                                                                                                            "linguistExtraRow": true,
                                                                                                                                            "extraFromOtherLinguist": otherLinguist
                                                                                                                                        })

                                                                                                                                        pushedRows.push(acceptRowFromOtherLinguist.rows[j])
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    let otherLinguistExtraRowData = otherLinguistAcceptedFilledData[acceptRowFromOtherLinguist.linguist][otherLinguistBlock].extra[acceptRowFromOtherLinguist.rows[j]]

                                                                                                                                    console.log("otherLinguistExtraRowData 2", otherLinguistExtraRowData, otherLinguistBlock, acceptRowFromOtherLinguist.rows[j], otherLinguistAcceptedFilledData)

                                                                                                                                    for (let k = 0; k < otherLinguistExtraRowData.columnsSequence.length; k++) {
                                                                                                                                        let columnData = otherLinguistExtraRowData.columns[otherLinguistExtraRowData.columnsSequence[k]]

                                                                                                                                        if (otherLinguistExtraRowData.columnsSequence[k] in otherLinguistAcceptedFilledData[otherLinguist][otherLinguistBlock].otherRows[acceptRowFromOtherLinguist.rows[j]]) {
                                                                                                                                            columnData = otherLinguistAcceptedFilledData[otherLinguist][otherLinguistBlock].otherRows[acceptRowFromOtherLinguist.rows[j]][otherLinguistExtraRowData.columnsSequence[k]]
                                                                                                                                        }

                                                                                                                                        rowToPush.push({
                                                                                                                                            "code": otherLinguistExtraRowData.columnsSequence[k],
                                                                                                                                            "text": columnData/*.value*/,
                                                                                                                                            "styleGuideEditable": true,
                                                                                                                                            "linguistEditable": otherLinguistExtraRowData["linguist_editable"]
                                                                                                                                        })
                                                                                                                                    }

                                                                                                                                    resultLanguageTemplate[0][otherLinguistBlock].data.push({
                                                                                                                                        "row": rowToPush,
                                                                                                                                        "code": acceptRowFromOtherLinguist.rows[j],
                                                                                                                                        "colspan": otherLinguistExtraRowData.colspan,
                                                                                                                                        "linguistExtraRow": true,
                                                                                                                                        "extraFromOtherLinguist": otherLinguist
                                                                                                                                    })

                                                                                                                                    pushedRows.push(acceptRowFromOtherLinguist.rows[j])
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        console.log("ACCEPTED OTHER", otherLinguistsAcceptData, otherLinguistAcceptedFilledData, resultLanguageTemplate[0])

                                                                                                        //если текущий лингвист изменил принятую строку от другого лингвиста
                                                                                                        // и его изменения приняли, то эту строку нужно добавить
                                                                                                        /*if (submitValue) {*/
                                                                                                        //todo
                                                                                                        for (let acceptedBlock in acceptedDataForLanguage) {
                                                                                                            if ("otherLinguistExtra" in acceptedDataForLanguage[acceptedBlock]) {
                                                                                                                let otherLinguistsExtraAcceptedData = acceptedDataForLanguage[acceptedBlock]["otherLinguistExtra"]

                                                                                                                for (let i = 0; i < otherLinguistsExtraAcceptedData.length; i++) {
                                                                                                                    let anotherLinguist = otherLinguistsExtraAcceptedData[i].linguist
                                                                                                                    let anotherLinguistRows = otherLinguistsExtraAcceptedData[i].rows

                                                                                                                    for (let j = 0; j < anotherLinguistRows.length; j++) {
                                                                                                                        let otherLinguistExtraRowCode = anotherLinguistRows[j]
                                                                                                                        let rowToPush = []
                                                                                                                        let otherLinguistExtraRowData = otherLinguistAcceptedFilledData[anotherLinguist][acceptedBlock].extra[otherLinguistExtraRowCode]
                                                                                                                        let currentLinguistRowFromAnotherLinguistData = currentLinguistAcceptedFilledData[acceptedBlock][otherLinguistExtraRowCode]

                                                                                                                        for (let k = 0; k < otherLinguistExtraRowData.columnsSequence.length; k++) {
                                                                                                                            let columnData = otherLinguistExtraRowData.columns[otherLinguistExtraRowData.columnsSequence[k]]

                                                                                                                            if (otherLinguistExtraRowData.columnsSequence[k] in currentLinguistRowFromAnotherLinguistData) {
                                                                                                                                columnData = currentLinguistRowFromAnotherLinguistData[otherLinguistExtraRowData.columnsSequence[k]]
                                                                                                                            }

                                                                                                                            rowToPush.push({
                                                                                                                                "code": otherLinguistExtraRowData.columnsSequence[k],
                                                                                                                                "text": columnData/*.value*/,
                                                                                                                                "styleGuideEditable": true,
                                                                                                                                "linguistEditable": otherLinguistExtraRowData["linguist_editable"]
                                                                                                                            })
                                                                                                                        }

                                                                                                                        /*for (let k = 0; k < rowToPush.length; k++) {
                                                                                                                            if (rowToPush[k].code in currentLinguistRowFromAnotherLinguistData) {
                                                                                                                                let columnData = currentLinguistRowFromAnotherLinguistData[rowToPush[k].code]

                                                                                                                                rowToPush[k].text = columnData
                                                                                                                            }
                                                                                                                        }*/

                                                                                                                        if (acceptedBlock in resultLanguageTemplate[0]) {
                                                                                                                            resultLanguageTemplate[0][acceptedBlock].data.push({
                                                                                                                                "row": rowToPush,
                                                                                                                                "code": otherLinguistExtraRowCode,
                                                                                                                                "colspan": otherLinguistExtraRowData.colspan,
                                                                                                                                "linguistExtraRow": true,
                                                                                                                                "extraFromOtherLinguist": anotherLinguist
                                                                                                                            })
                                                                                                                        }

                                                                                                                        pushedRows.push(otherLinguistExtraRowCode)
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        for (let bufferedBlock in bufferedDataForLanguage) {
                                                                                                            if ("otherLinguistExtra" in bufferedDataForLanguage[bufferedBlock]) {
                                                                                                                let otherLinguistsExtraBufferedData = bufferedDataForLanguage[bufferedBlock]["otherLinguistExtra"]

                                                                                                                for (let i = 0; i < otherLinguistsExtraBufferedData.length; i++) {
                                                                                                                    let anotherLinguist = otherLinguistsExtraBufferedData[i].linguist
                                                                                                                    let anotherLinguistRows = otherLinguistsExtraBufferedData[i].rows

                                                                                                                    for (let j = 0; j < anotherLinguistRows.length; j++) {
                                                                                                                        let otherLinguistExtraRowCode = anotherLinguistRows[j]
                                                                                                                        let rowToPush = []

                                                                                                                        if (otherLinguistExtraRowCode in otherLinguistAcceptedFilledData[anotherLinguist][bufferedBlock].extra) {
                                                                                                                            let otherLinguistExtraRowData = otherLinguistAcceptedFilledData[anotherLinguist][bufferedBlock].extra[otherLinguistExtraRowCode]
                                                                                                                            let currentLinguistRowFromAnotherLinguistData = currentLinguistAcceptedFilledData[bufferedBlock][otherLinguistExtraRowCode]

                                                                                                                            console.log("otherLinguistExtraRowData 3", otherLinguistAcceptedFilledData, anotherLinguist, bufferedBlock, otherLinguistExtraRowCode)

                                                                                                                            for (let k = 0; k < otherLinguistExtraRowData.columnsSequence.length; k++) {
                                                                                                                                let columnData = otherLinguistExtraRowData.columns[otherLinguistExtraRowData.columnsSequence[k]]

                                                                                                                                if (otherLinguistExtraRowData.columnsSequence[k] in currentLinguistRowFromAnotherLinguistData) {
                                                                                                                                    columnData = currentLinguistRowFromAnotherLinguistData[otherLinguistExtraRowData.columnsSequence[k]]
                                                                                                                                }

                                                                                                                                rowToPush.push({
                                                                                                                                    "code": otherLinguistExtraRowData.columnsSequence[k],
                                                                                                                                    "text": columnData/*.value*/,
                                                                                                                                    "styleGuideEditable": true,
                                                                                                                                    "linguistEditable": otherLinguistExtraRowData["linguist_editable"]
                                                                                                                                })
                                                                                                                            }

                                                                                                                            if (!pushedRows.includes(otherLinguistExtraRowCode)) {
                                                                                                                                if (bufferedBlock in resultLanguageTemplate[0]) {
                                                                                                                                    resultLanguageTemplate[0][bufferedBlock].data.push({
                                                                                                                                        "row": rowToPush,
                                                                                                                                        "code": otherLinguistExtraRowCode,
                                                                                                                                        "colspan": otherLinguistExtraRowData.colspan,
                                                                                                                                        "linguistExtraRow": true,
                                                                                                                                        "extraFromOtherLinguist": anotherLinguist
                                                                                                                                    })
                                                                                                                                }

                                                                                                                                pushedRows.push(otherLinguistExtraRowCode)
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        /*}*/

                                                                                                        //экстра строки текущего лингвиста
                                                                                                        for (let currentLinguistExtraRowsBlock in currentLinguistExtraRows) {
                                                                                                            let blockData = currentLinguistExtraRows[currentLinguistExtraRowsBlock]

                                                                                                            for (let currentLinguistExtraRow in blockData) {
                                                                                                                let isCurrentLinguistExtraRowAccepted = currentLinguistExtraRowsBlock in acceptedDataForLanguage &&
                                                                                                                    acceptedDataForLanguage[currentLinguistExtraRowsBlock].extra.includes(currentLinguistExtraRow)
                                                                                                                let isCurrentLinguistExtraRowBuffered = currentLinguistExtraRowsBlock in bufferedDataForLanguage &&
                                                                                                                    bufferedDataForLanguage[currentLinguistExtraRowsBlock].extra.includes(currentLinguistExtraRow)

                                                                                                                if (isCurrentLinguistExtraRowAccepted || isCurrentLinguistExtraRowBuffered) {
                                                                                                                    let rowToPush = []

                                                                                                                    for (let i = 0; i < blockData[currentLinguistExtraRow].columnsSequence/* resultLinguistLanguageExtraRows[i].columns*/.length; i++) {
                                                                                                                        rowToPush.push({
                                                                                                                            "code": blockData[currentLinguistExtraRow].columnsSequence[i],
                                                                                                                            "text": "",
                                                                                                                            "styleGuideEditable": true,
                                                                                                                            "linguistEditable": blockData[currentLinguistExtraRow].linguist_editable,
                                                                                                                            "isBuffered": isCurrentLinguistExtraRowBuffered
                                                                                                                        })
                                                                                                                    }

                                                                                                                    resultLanguageTemplate[0][currentLinguistExtraRowsBlock].data.push({
                                                                                                                        "row": rowToPush,
                                                                                                                        "code": currentLinguistExtraRow,
                                                                                                                        "colspan": blockData[currentLinguistExtraRow].colspan,
                                                                                                                        "linguistExtraRow": true
                                                                                                                    })

                                                                                                                    pushedRows.push(currentLinguistExtraRow)
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        /*for (let i = 0; i < resultLinguistLanguageExtraRows.length; i++) {
                                                                                                            let addProperty = false

                                                                                                            if (!submitValue) {
                                                                                                                addProperty = true
                                                                                                            } else if (submitValue && resultLinguistLanguageExtraRows[i].block in acceptedDataForLanguage) {
                                                                                                                if (acceptedDataForLanguage[resultLinguistLanguageExtraRows[i].block].extra.includes(resultLinguistLanguageExtraRows[i]["row_code"])) {
                                                                                                                    addProperty = true
                                                                                                                }
                                                                                                            } /!*else {
                                                                                                                addProperty = true //todo ?
                                                                                                            }*!/

                                                                                                            if (addProperty) {
                                                                                                                let rowToPush = []

                                                                                                                for (let j = 0; j < resultLinguistLanguageExtraRows[i].columns.length; j++) {
                                                                                                                    rowToPush.push({
                                                                                                                        "code": resultLinguistLanguageExtraRows[i].columns[j],
                                                                                                                        "text": "",
                                                                                                                        "styleGuideEditable": true,
                                                                                                                        "linguistEditable": resultLinguistLanguageExtraRows[i].linguist_editable
                                                                                                                    })
                                                                                                                }

                                                                                                                resultLanguageTemplate[0][resultLinguistLanguageExtraRows[i].block].data.push({
                                                                                                                    "row": rowToPush,
                                                                                                                    "code": resultLinguistLanguageExtraRows[i]["row_code"],
                                                                                                                    "colspan": resultLinguistLanguageExtraRows[i].colspan,
                                                                                                                    "linguistExtraRow": true
                                                                                                                })
                                                                                                            }
                                                                                                        }*/

                                                                                                        for (let i = 0; i < resultLanguageFilledData.length; i++) {
                                                                                                            if (resultLanguageFilledData[i].block in resultLanguageTemplate[0]) {
                                                                                                                let elData = resultLanguageTemplate[0][resultLanguageFilledData[i].block].data

                                                                                                                for (let j = 0; j < elData.length; j++) {
                                                                                                                    if (elData[j].code === resultLanguageFilledData[i].row_code) {
                                                                                                                        for (let k = 0; k < elData[j].row.length; k++) {
                                                                                                                            if (elData[j].row[k].code === resultLanguageFilledData[i].col_code) {
                                                                                                                                if (!("filledFromOtherLinguist" in elData[j].row[k]) ||
                                                                                                                                    ("filledFromOtherLinguist" in elData[j].row[k] &&
                                                                                                                                        !elData[j].row[k]["filledFromOtherLinguist"])) {
                                                                                                                                    elData[j].row[k].text = resultLanguageFilledData[i].value
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                resultLanguageTemplate[0][resultLanguageFilledData[i].block].data = elData
                                                                                                            }
                                                                                                        }

                                                                                                        for (let i = 0; i < resultLinguistLanguageFilledData.length; i++) {
                                                                                                            let refillProperty = false
                                                                                                            let isBuffered = false

                                                                                                            /*if (!submitValue) {
                                                                                                                refillProperty = true
                                                                                                            } else*/ if (/*submitValue && */resultLinguistLanguageFilledData[i].block in acceptedDataForLanguage) {
                                                                                                                if (acceptedDataForLanguage[resultLinguistLanguageFilledData[i].block].default.includes(resultLinguistLanguageFilledData[i].row_code) ||
                                                                                                                    acceptedDataForLanguage[resultLinguistLanguageFilledData[i].block].extra.includes(resultLinguistLanguageFilledData[i].row_code)) {
                                                                                                                    refillProperty = true
                                                                                                                } else if ("otherLinguistExtra" in acceptedDataForLanguage[resultLinguistLanguageFilledData[i].block]) {
                                                                                                                    for (let j = 0; j < acceptedDataForLanguage[resultLinguistLanguageFilledData[i].block].otherLinguistExtra.length; j++) {
                                                                                                                        let otherRows = acceptedDataForLanguage[resultLinguistLanguageFilledData[i].block].otherLinguistExtra[j].rows

                                                                                                                        if (otherRows.includes(resultLinguistLanguageFilledData[i].row_code)) {
                                                                                                                            refillProperty = true
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }


                                                                                                            if (/*submitValue && */resultLinguistLanguageFilledData[i].block in bufferedDataForLanguage) {
                                                                                                                if (bufferedDataForLanguage[resultLinguistLanguageFilledData[i].block].default.includes(resultLinguistLanguageFilledData[i].row_code) ||
                                                                                                                    bufferedDataForLanguage[resultLinguistLanguageFilledData[i].block].extra.includes(resultLinguistLanguageFilledData[i].row_code)) {
                                                                                                                    refillProperty = true
                                                                                                                    isBuffered = true
                                                                                                                } else if ("otherLinguistExtra" in bufferedDataForLanguage[resultLinguistLanguageFilledData[i].block]) {
                                                                                                                    for (let j = 0; j < bufferedDataForLanguage[resultLinguistLanguageFilledData[i].block].otherLinguistExtra.length; j++) {
                                                                                                                        let otherRows = bufferedDataForLanguage[resultLinguistLanguageFilledData[i].block].otherLinguistExtra[j].rows

                                                                                                                        if (otherRows.includes(resultLinguistLanguageFilledData[i].row_code)) {
                                                                                                                            refillProperty = true
                                                                                                                            isBuffered = true
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            if (refillProperty) {
                                                                                                                if (resultLinguistLanguageFilledData[i].block in resultLanguageTemplate[0]) {
                                                                                                                    let elData = resultLanguageTemplate[0][resultLinguistLanguageFilledData[i].block].data

                                                                                                                    for (let j = 0; j < elData.length; j++) {
                                                                                                                        if (elData[j].code === resultLinguistLanguageFilledData[i].row_code) {
                                                                                                                            for (let k = 0; k < elData[j].row.length; k++) {
                                                                                                                                if (elData[j].row[k].code === resultLinguistLanguageFilledData[i].col_code) {
                                                                                                                                    if (!("filledByOtherLinguist" in elData[j].row[k] && elData[j].row[k].filledByOtherLinguist)) {
                                                                                                                                        elData[j].row[k].text = resultLinguistLanguageFilledData[i].value
                                                                                                                                        elData[j].row[k]["isBuffered"] = isBuffered
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    resultLanguageTemplate[0][resultLinguistLanguageFilledData[i].block].data = elData
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        language.data = resultLanguageTemplate

                                                                                                        languageCount++

                                                                                                        if (languageCount === languagesAllCount) {
                                                                                                            setIsLanguageLoaded(true);
                                                                                                        }
                                                                                                    },
                                                                                                    (error) => {
                                                                                                        setIsLanguageLoaded(true);
                                                                                                        setError(error);
                                                                                                    }
                                                                                                )
                                                                                        },
                                                                                        (error) => {
                                                                                            setIsLanguageLoaded(true);
                                                                                            setError(error);
                                                                                        }
                                                                                    )
                                                                            },
                                                                            (error) => {
                                                                                setIsLanguageLoaded(true);
                                                                                setError(error);
                                                                            }
                                                                        )
                                                                },
                                                                (error) => {
                                                                    setIsLanguageLoaded(true);
                                                                    setError(error);
                                                                }
                                                            )
                                                    },
                                                    (error) => {
                                                        setIsLanguageLoaded(true);
                                                        setError(error);
                                                    }
                                                )
                                        },
                                        (error) => {
                                            setIsLanguageLoaded(true);
                                            setError(error);
                                        }
                                    )
                            },
                            (error) => {
                                setIsLanguageLoaded(true);
                                setError(error);
                            }
                        )

                    return language
                })

                return info
            })
        )
    }

    async function fillLogOfNewBatches() {
        console.log("FILL log of new batches")

        await fetch("/proxy/project_bible_template/styleGuideLogOfNewBatchesHeaders", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(
                async (resultLogOfNewBatchesHeaders) => {
                    await fetch("/proxy/project_bible_template/styleGuideLogOfNewBatchesRowsText", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLogOfNewBatchesRows) => {
                                resultLogOfNewBatchesHeaders.headers.map(value => {
                                    setLogOfNewBatchesData(
                                        logOfNewBatchesData.map(info => {
                                            info.headers.push(value)

                                            return info
                                        })
                                    )

                                    return value
                                })

                                console.log("resultLogOfNewBatchesHeaders", resultLogOfNewBatchesHeaders)
                                console.log("resultLogOfNewBatchesRows", resultLogOfNewBatchesRows)

                                setLogOfNewBatchesData(
                                    logOfNewBatchesData.map(info => {
                                        let rowsCount = resultLogOfNewBatchesRows.count.rowCount !== 0 ? resultLogOfNewBatchesRows.count.rows[0].num : 0

                                        info.rowsCount = rowsCount

                                        for (let i = 0; i < rowsCount; i++) {
                                            info.rowsData[i] = []

                                            resultLogOfNewBatchesHeaders.headers.map(value => {
                                                info.rowsData[i][value.code] = ""
                                            })
                                        }

                                        resultLogOfNewBatchesRows.rows.map(value => {
                                            info.rowsData[value.num - 1][value.col_code] = value.value

                                            return value
                                        })

                                        return info
                                    })
                                )

                                console.log("logOfNewBatchesData", logOfNewBatchesData)

                                setIsLogOfNewBatches(true)
                            },
                            (error) => {
                                setIsLogOfNewBatches(true);
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setIsLogOfNewBatches(true);
                    setError(error);
                }
            )
    }

    async function fillCharacterCommunication() {
        console.log("fillCharacterCommunication", characterCommunications)

        await fetch("/proxy/project_bible_template/styleGuideGetCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    if (result.length) {
                        for (let i = 0; i < result.length; i++) {
                            let languageCode = result[i].language_code
                            let firstCharacter = result[i].character_first
                            let secondCharacter = result[i].character_second
                            let firstCharacterSequence = getCharacterSequence(firstCharacter) - 1
                            let secondCharacterSequence = getCharacterSequence(secondCharacter) - 1

                            setCharacterCommunications(
                                characterCommunications.map(info => {
                                    if (languageCode === 0) {
                                        info.templateData[firstCharacterSequence].data[secondCharacterSequence] = result[i].value
                                    } else {
                                        if (languageCode in info.languagesData) {
                                            if (!(firstCharacterSequence in info.languagesData[languageCode].data)) {
                                                info.languagesData[languageCode].data[firstCharacterSequence] = []
                                            }

                                            info.languagesData[languageCode].data[firstCharacterSequence][secondCharacterSequence] = result[i].value
                                        }
                                    }

                                    return info
                                })
                            )

                            charactersDataCount++

                            if (charactersDataCount === result.length) {
                                setIsCharacterCommunicationLoaded(true);
                            }
                        }
                    } else {
                        setIsCharacterCommunicationLoaded(true);
                    }


                    /*await fetch("/proxy/project_bible_template/styleGuideLinguistGetCharactersData", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "linguistId": linguistCode
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLinguist) => {
                                if (result.length) {
                                    for (let i = 0; i < result.length; i++) {
                                        setCharacterCommunications(
                                            characterCommunications.map(info => {
                                                if (result[i].language_code !== 0) {
                                                    if (result[i].language_code in info.templateData) {
                                                        if (!(result[i].character_first - 1 in info.templateData[result[i].language_code].data)) {
                                                            info.templateData[result[i].language_code].data[result[i].character_first - 1] = []
                                                        }

                                                        info.templateData[result[i].language_code].data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                                    }
                                                } else {
                                                    if (!(result[i].character_first - 1 in info.baseStyleGuideData)) {
                                                        info.baseStyleGuideData[result[i].character_first - 1] = []
                                                    }

                                                    info.baseStyleGuideData[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                                }

                                                return info
                                            })
                                        )

                                        charactersDataCount++

                                        console.log("charactersDataCount", charactersDataCount)

                                        if (charactersDataCount === result.length) {
                                            setIsCharacterCommunicationLoaded(true);
                                        }
                                    }
                                } else {
                                    setIsCharacterCommunicationLoaded(true);
                                    console.log("IS CHARACTER COMMUNICATION LOADED", isCharacterCommunicationLoaded)
                                }

                                if (resultLinguist.length) {
                                    for (let i = 0; i < resultLinguist.length; i++) {
                                        setCharacterCommunications(
                                            characterCommunications.map(info => {
                                                if (resultLinguist[i].language_code !== 0 && (!submitValue || (submitValue && resultLinguist[i].language_code in acceptedData[0].communicationBetweenCharacters && acceptedData[0].communicationBetweenCharacters[resultLinguist[i].language_code]))) {
                                                    if (resultLinguist[i].language_code in info.languagesData) {
                                                        if (!(resultLinguist[i].character_first - 1 in info.languagesData[resultLinguist[i].language_code].data)) {
                                                            info.languagesData[resultLinguist[i].language_code].data[resultLinguist[i].character_first - 1] = []
                                                        }

                                                        info.languagesData[resultLinguist[i].language_code].data[resultLinguist[i].character_first - 1][resultLinguist[i].character_second - 1] = resultLinguist[i].value
                                                    }
                                                }

                                                return info
                                            })
                                        )

                                        linguistCharactersDataCount++

                                        if (linguistCharactersDataCount === resultLinguist.length) {
                                            setIsLinguistCharacterCommunicationLoaded(true)
                                        }
                                    }
                                } else {
                                    setIsLinguistCharacterCommunicationLoaded(true)
                                }
                            },
                            (error) => {
                                setIsCharacterCommunicationLoaded(true);
                                setIsLinguistCharacterCommunicationLoaded(true)
                                setError(error);
                            }
                        )*/
                },
                (error) => {
                    setIsCharacterCommunicationLoaded(true);
                    setIsLinguistCharacterCommunicationLoaded(true)
                    setError(error);
                }
            )
    }

    async function fillCharacterCommunicationFromLinguists() {
        await fetch("/proxy/project_bible_template/styleGuideOtherLinguistGetAcceptedCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguistCode
            })
        })
            .then(res => res.json())
            .then(
                async (resultOtherLinguists) => {
                    await fetch("/proxy/project_bible_template/styleGuideLinguistGetCharactersData", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "linguistId": linguistCode
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultCurrentLinguist) => {
                                setCharacterCommunications(
                                    characterCommunications.map(info => {
                                        for (let i = 0; i < resultOtherLinguists.length; i++) {
                                            let languageCode = resultOtherLinguists[i]["language_code"]
                                            let characterFirst = resultOtherLinguists[i]["character_first"]
                                            let characterSecond = resultOtherLinguists[i]["character_second"]
                                            let acceptedValue = resultOtherLinguists[i]["accepted_value"]
                                            let firstCharacterSequence = getCharacterSequence(characterFirst) - 1
                                            let secondCharacterSequence = getCharacterSequence(characterSecond) - 1

                                            if (languageCode in info.languagesData) {
                                                if (!(firstCharacterSequence in info.languagesData[languageCode].otherLinguistAcceptedData)) {
                                                    info.languagesData[languageCode].otherLinguistAcceptedData[firstCharacterSequence] = []
                                                }

                                                info.languagesData[languageCode].otherLinguistAcceptedData[firstCharacterSequence][secondCharacterSequence] = {
                                                    value: acceptedValue
                                                }
                                            }
                                        }

                                        for (let i = 0; i < resultCurrentLinguist.length; i++) {
                                            let languageCode = resultCurrentLinguist[i]["language_code"]
                                            let characterFirst = resultCurrentLinguist[i]["character_first"]
                                            let characterSecond = resultCurrentLinguist[i]["character_second"]
                                            let lastValue = resultCurrentLinguist[i]["value"]
                                            let isBuffered = resultCurrentLinguist[i]["is_buffered"]
                                            let acceptedValue = resultCurrentLinguist[i]["accepted_value"]
                                            let isAccepted = resultCurrentLinguist[i]["is_accepted"]
                                            let firstCharacterSequence = getCharacterSequence(characterFirst) - 1
                                            let secondCharacterSequence = getCharacterSequence(characterSecond) - 1

                                            if (languageCode in info.languagesData) {
                                                if (!(firstCharacterSequence in info.languagesData[languageCode].currentLinguistData)) {
                                                    info.languagesData[languageCode].currentLinguistData[firstCharacterSequence] = []
                                                }

                                                info.languagesData[languageCode].currentLinguistData[firstCharacterSequence][secondCharacterSequence] = {
                                                    value: lastValue,
                                                    isBuffered: isBuffered,
                                                    isAccepted: isAccepted,
                                                    acceptedValue: acceptedValue
                                                }
                                            }
                                        }

                                        return info
                                    })
                                )
                            },
                            (error) => {
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setError(error);
                }
            )
    }

    function getCharacterSequence(id) {
        let num = 0

        characterCommunications.map(info => {
            num = info.characters[id].num

            return info
        })

        return num
    }

    async function addRowToStyleGuideLanguageBlock(languageCode, block, linguistEditable, cellCount) {
        await fetch("/proxy/project_bible_template/styleGuideLinguistGenerateLanguagesExtraRowsCode", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "id": linguistCode,
                "languageCode": languageCode,
                "block": block
            }),
        })
            .then(res => res.json())
            .then(
                async (resultGenerate) => {
                    if (!resultGenerate.exist.length) {
                        let columnsCodes = generateCodes(cellCount, [])

                        await fetch("/proxy/project_bible_template/styleGuideLinguistAddLanguagesExtraRows", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "id": linguistCode,
                                "languageCode": languageCode,
                                "block": block,
                                "rowCode": resultGenerate.individualCode,
                                "columns": columnsCodes,
                                "linguistEditable": linguistEditable,
                                "colspan": cellCount === 2 ? false : true
                            })
                        })
                            .then(res => res.json())
                            .then(
                                (resultAdd) => {
                                    setLanguages(
                                        languages.map(info => {
                                            info.data.map(async language => {
                                                if (language.code === languageCode) {
                                                    let rowData = []

                                                    for (let i = 0; i < columnsCodes.length; i++) {
                                                        rowData.push({
                                                            "code": columnsCodes[i],
                                                            "text": "",
                                                            "styleGuideEditable": true,
                                                            "linguistEditable": true
                                                        })
                                                    }

                                                    language.data[0][block].data.push({
                                                        "row": rowData,
                                                        "code": resultGenerate.individualCode,
                                                        "colspan": cellCount === 2 ? false : true,
                                                        "linguistExtraRow": true
                                                    })

                                                    if (/*submit && */acceptedData[0].accept[languageCode]) {
                                                        setAcceptedData(
                                                            acceptedData.map(info => {
                                                                if (!(languageCode in info.languagePreferences)) {
                                                                    info.languagePreferences[languageCode] = []
                                                                }

                                                                if (!(block in info.languagePreferences[languageCode])) {
                                                                    info.languagePreferences[languageCode][block] = {
                                                                        "default": [],
                                                                        "extra": []
                                                                    }
                                                                }

                                                                info.accept[languageCode] = true
                                                                info.languagePreferences[languageCode][block].extra.push(resultGenerate.individualCode)

                                                                return info
                                                            })
                                                        )

                                                        setBufferedData(
                                                            bufferedData.map(info => {
                                                                if (!(languageCode in info.languagePreferences)) {
                                                                    info.languagePreferences[languageCode] = []
                                                                }

                                                                if (!(block in info.languagePreferences[languageCode])) {
                                                                    info.languagePreferences[languageCode][block] = {
                                                                        "default": [],
                                                                        "extra": []
                                                                    }
                                                                }

                                                                info.languagePreferences[languageCode][block].extra.push(resultGenerate.individualCode)

                                                                setBufferedLanguagePreferencesData(languageCode, info.languagePreferences[languageCode])

                                                                return info
                                                            })
                                                        )
                                                    }
                                                }

                                                return language
                                            })

                                            return info
                                        })
                                    )

                                    /*if (submit && acceptedData[0].accept[languageCode]) {
                                        setBufferedData(
                                            bufferedData.map(info => {
                                                if (!(languageCode in info.languagePreferences)) {
                                                    info.languagePreferences[languageCode] = []
                                                }

                                                if (!(block in info.languagePreferences[languageCode])) {
                                                    info.languagePreferences[languageCode][block] = {
                                                        "default": [],
                                                        "extra": []
                                                    }
                                                }

                                                info.languagePreferences[languageCode][block].extra.push(resultGenerate.individualCode)

                                                setBufferedLanguagePreferencesData(languageCode, info.languagePreferences[languageCode])

                                                return info
                                            })
                                        )
                                    }*/
                                },
                                (error) => {
                                    //todo придумать какой-то текст ошибки
                                }
                            )
                    } else {
                        addRowToStyleGuideLanguageBlock(languageCode, block, linguistEditable, cellCount)
                    }
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function setBufferedLanguagePreferencesUpdatedCellData(languageCode, block, row, linguistExtraRow, extraFromOtherLinguist) {
        setBufferedData(
            bufferedData.map(info => {
                if (!(languageCode in info.languagePreferences)) {
                    info.languagePreferences[languageCode] = {}
                }

                if (!(block in info.languagePreferences[languageCode])) {
                    info.languagePreferences[languageCode][block] = {
                        "default": [],
                        "extra": []
                    }
                }

                if (linguistExtraRow) {
                    if (extraFromOtherLinguist) {
                        if (!("otherLinguistExtra" in info.languagePreferences[languageCode][block])) {
                            info.languagePreferences[languageCode][block]["otherLinguistExtra"] = []
                            info.languagePreferences[languageCode][block]["otherLinguistExtra"].push({
                                "linguist": extraFromOtherLinguist,
                                "rows": [row]
                            })
                        } else {
                            let parsedData = []
                            let otherLinguistExtra = info.languagePreferences[languageCode][block]["otherLinguistExtra"]

                            for (let i = 0; i < otherLinguistExtra.length; i++) {
                                parsedData[otherLinguistExtra[i].linguist] = {
                                    "index": i,
                                    "rows": otherLinguistExtra[i].rows
                                }
                            }

                            if (extraFromOtherLinguist in parsedData) {
                                if (!(parsedData[extraFromOtherLinguist].rows.includes(row))) {
                                    info.languagePreferences[languageCode][block]["otherLinguistExtra"][parsedData[extraFromOtherLinguist].index].rows.push(row)
                                }
                            } else {
                                info.languagePreferences[languageCode][block]["otherLinguistExtra"].push({
                                    "linguist": extraFromOtherLinguist,
                                    "rows": [row]
                                })
                            }
                        }

                        console.log("extraFromOtherLinguist", extraFromOtherLinguist, info.languagePreferences[languageCode], row)
                    } else {
                        if (!info.languagePreferences[languageCode][block].extra.includes(row)) {
                            info.languagePreferences[languageCode][block].extra.push(row)
                        }
                    }
                } else {
                    if (!info.languagePreferences[languageCode][block].default.includes(row)) {
                        info.languagePreferences[languageCode][block].default.push(row)
                    }
                }

                removeRowFromAcceptedIfExist(languageCode, block, row, linguistExtraRow, extraFromOtherLinguist)
                setBufferedLanguagePreferencesData(languageCode, info.languagePreferences/*[languageCode]*/)

                return info
            })
        )
    }

    async function setBufferedLanguagePreferencesData(languageCode, data) {
        let queryLinkExist = '/proxy/project_bible_template/getByLanguageCodeStyleGuideLinguistBufferedChanges'
        let queryUpdateCell = '/proxy/project_bible_template/updateStyleGuideLinguistBufferedLanguagePreferencesChanges'
        let queryInsertCell = '/proxy/project_bible_template/insertStyleGuideLinguistAcceptAndBufferedChanges'

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguistCode,
                "languageCode": languageCode
            })
        })
            .then(res => res.json())
            .then(
                async (resultExist) => {
                    if (resultExist.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "linguistId": linguistCode,
                                "languageCode": languageCode,
                                "bufferedChangedLanguagePreferences": data[languageCode]
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (result) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "linguistId": linguistCode,
                                "languageCode": languageCode,
                                "acceptLanguagePreferences": {},
                                "acceptCommunicationBetweenCharacters": false,
                                "bufferedLanguagePreferences": data[languageCode],
                                "bufferedCommunicationBetweenCharacters": true,
                                "accept": false
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (result) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function removeRowFromAcceptedIfExist(languageCode, block, row, linguistExtraRow, extraFromOtherLinguist) {
        await fetch('/proxy/project_bible_template/getByLanguageCodeStyleGuideLinguistAcceptChanges', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguistCode,
                "languageCode": languageCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    console.log("removeRowFromAcceptedIfExist", result)

                    if (result.length && result[0].accept) {
                        let languagePreferencesAcceptData = result[0]["accept_language_preferences"]
                        let communicationBetweenCharactersAcceptData = result[0]["accept_communication_between_characters"]
                        let updateAcceptData = false

                        if (block in languagePreferencesAcceptData) {
                            if (linguistExtraRow) {
                                if (extraFromOtherLinguist && "otherLinguistExtra" in languagePreferencesAcceptData[block]) {
                                    let otherLinguistExtraData = languagePreferencesAcceptData[block].otherLinguistExtra

                                    for (let i = 0; i < otherLinguistExtraData.length; i++) {
                                        if (otherLinguistExtraData[i].linguist === extraFromOtherLinguist) {
                                            let index = otherLinguistExtraData[i].rows.indexOf(row);

                                            if (index !== -1) {
                                                otherLinguistExtraData[i].rows.splice(index, 1);
                                                updateAcceptData = true
                                            }
                                        }
                                    }
                                } else {
                                    let index = languagePreferencesAcceptData[block].extra.indexOf(row);

                                    if (index !== -1) {
                                        languagePreferencesAcceptData[block].extra.splice(index, 1);
                                        updateAcceptData = true
                                    }
                                }
                            } else {
                                let index = languagePreferencesAcceptData[block].default.indexOf(row);

                                if (index !== -1) {
                                    languagePreferencesAcceptData[block].default.splice(index, 1);
                                    updateAcceptData = true
                                }
                            }
                        }

                        if (updateAcceptData) {
                            console.log("removeRowFromAcceptedIfExist NEW", languagePreferencesAcceptData)

                            await fetch('/proxy/project_bible_template/updateStyleGuideLinguistAcceptedLanguagePreferencesChanges', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "linguistId": linguistCode,
                                    "languageCode": languageCode,
                                    "acceptLanguagePreferences": languagePreferencesAcceptData
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {

                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        }
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    function generateCodes(count, codes) {
        let data = codes
        let codesLeft = count - data.length
        let abc = "abcdefghijklmnopqrstuvwxyz1234567890-";

        // if (data.length !== count) {
        for (let i = 0; i < codesLeft; i++) {
            let individualCode = "";

            while (individualCode.length < 38) {
                individualCode += abc[Math.floor(Math.random() * abc.length)];
            }

            if (!data.includes(individualCode)) {
                if (data.length !== count) {
                    data.push(individualCode)
                }
            } else {
                generateCodes(count, data)
            }
        }
        // }

        return data
    }

    /*async function submitStyleGuideLinguist() {
        console.log("submitStyleGuideLinguist", clientName, projectName, projectCode, linguistCode)

        let isError = false
        let bufferedData = []
        let currentLinguistFilledRows = []
        let defaultAndOtherLinguistExtraRows = []

        await fetch("/proxy/project_bible_template/getStyleGuideLinguistAllLanguagesRowsWithFilledData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguistCode
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    for (let i = 0; i < result.length; i++) {
                        let languageCode = result[i].language_code
                        let block = result[i].block
                        let rowCode = result[i].row_code

                        if (!(languageCode in currentLinguistFilledRows)) {
                            currentLinguistFilledRows[languageCode] = []
                        }

                        if (!(block in currentLinguistFilledRows[languageCode])) {
                            currentLinguistFilledRows[languageCode][block] = [rowCode]
                        } else {
                            if (!currentLinguistFilledRows[languageCode][block].includes(rowCode)) {
                                currentLinguistFilledRows[languageCode][block].push(rowCode)
                            }
                        }
                    }
                },
                (error) => {
                    isError = true
                }
            )

        if (/!*Object.keys(currentLinguistFilledRows).length && *!/!isError) {
            await fetch("/proxy/project_bible_template/styleGuideDefaultGetAllLanguagesExtraRows", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode
                }),
            })
                .then(res => res.json())
                .then(
                    async (result) => {
                        for (let i = 0; i < result.length; i++) {
                            let languageCode = result[i].language_code
                            let block = result[i].block
                            let rowCode = result[i].row_code

                            if (!(languageCode in defaultAndOtherLinguistExtraRows)) {
                                defaultAndOtherLinguistExtraRows[languageCode] = []
                            }

                            if (!(block in defaultAndOtherLinguistExtraRows[languageCode])) {
                                defaultAndOtherLinguistExtraRows[languageCode][block] = []
                            }

                            defaultAndOtherLinguistExtraRows[languageCode][block][rowCode] = {
                                "isDefaultRow": true
                            }
                        }
                    },
                    (error) => {
                        isError = true
                    }
                )

            await fetch("/proxy/project_bible_template/styleGuideAllLinguistGetAllLanguagesExtraRows", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode
                }),
            })
                .then(res => res.json())
                .then(
                    async (result) => {
                        //todo allLinguistExtraRows

                        for (let i = 0; i < result.length; i++) {
                            let linguist = result[i].linguist_id
                            let languageCode = result[i].language_code
                            let block = result[i].block
                            let rowCode = result[i].row_code

                            if (linguist !== linguistCode) {
                                if (!(languageCode in defaultAndOtherLinguistExtraRows)) {
                                    defaultAndOtherLinguistExtraRows[languageCode] = []
                                }

                                if (!(block in defaultAndOtherLinguistExtraRows[languageCode])) {
                                    defaultAndOtherLinguistExtraRows[languageCode][block] = []
                                }

                                defaultAndOtherLinguistExtraRows[languageCode][block][rowCode] = {
                                    "isDefaultRow": false,
                                    "linguist": linguist
                                }
                            }
                        }
                    },
                    (error) => {
                        isError = true
                    }
                )

            if (!isError) {
                for (let languageCode in currentLinguistFilledRows) {
                    if (languageCode in defaultAndOtherLinguistExtraRows) {
                        if (!(languageCode in bufferedData)) {
                            bufferedData[languageCode] = []
                        }

                        for (let block in currentLinguistFilledRows[languageCode]) {
                            if (block in defaultAndOtherLinguistExtraRows[languageCode]) {
                                if (!(block in bufferedData[languageCode])) {
                                    bufferedData[languageCode][block] = {
                                        "extra": [],
                                        "default": []
                                    }
                                }

                                for (let i = 0; i < currentLinguistFilledRows[languageCode][block].length; i++) {
                                    let rowCode = currentLinguistFilledRows[languageCode][block][i]

                                    if (rowCode in defaultAndOtherLinguistExtraRows[languageCode][block]) {
                                        //происхождение строки - либо default, либо от другого лингвиста
                                        let rowOrigin = defaultAndOtherLinguistExtraRows[languageCode][block][rowCode]

                                        if (rowOrigin.isDefaultRow) {
                                            bufferedData[languageCode][block].default.push(rowCode)
                                        } else {
                                            if (!("otherLinguistExtra" in bufferedData[languageCode][block])) {
                                                bufferedData[languageCode][block]["otherLinguistExtra"] = []
                                            }

                                            if (!(rowOrigin.linguist in bufferedData[languageCode][block]["otherLinguistExtra"])) {
                                                bufferedData[languageCode][block]["otherLinguistExtra"][rowOrigin.linguist] = [rowCode]
                                            } else {
                                                bufferedData[languageCode][block]["otherLinguistExtra"][rowOrigin.linguist].push(rowCode)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let isInsertSuccess = await insertBufferedDataForCurrentLinguist(bufferedData)

                if (isInsertSuccess) {
                    await fetch("/proxy/project_bible_template/styleGuideLinguistSetSubmit", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "linguistId": linguistCode,
                            "value": true
                        }),
                    })
                        .then(res => res.json())
                        .then(
                            async (result) => {
                                setSubmit(true)
                            },
                            (error) => {
                                alert("Error on submit. Try again")
                            }
                        )
                }
            } else {
                alert("Error on submit. Try again")
            }
        }
    }*/

    /*async function insertBufferedDataForCurrentLinguist(bufferedData) {
        let isSuccess = true

        for (let languageCode in bufferedData) {
            let dataForInsert = {}

            for (let block in bufferedData[languageCode]) {
                if ("otherLinguistExtra" in bufferedData[languageCode][block]) {
                    dataForInsert[block] = {
                        "default": bufferedData[languageCode][block].default,
                        "extra": [],
                        "otherLinguistExtra": []
                    }

                    for (let otherLinguist in bufferedData[languageCode][block].otherLinguistExtra) {
                        dataForInsert[block].otherLinguistExtra.push({
                            rows: bufferedData[languageCode][block].otherLinguistExtra[otherLinguist],
                            linguist: otherLinguist
                        })
                    }
                } else {
                    dataForInsert[block] = bufferedData[languageCode][block]
                }
            }

            await fetch('/proxy/project_bible_template/insertStyleGuideLinguistAcceptAndBufferedChanges', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode,
                    "linguistId": linguistCode,
                    "languageCode": languageCode,
                    "acceptLanguagePreferences": {},
                    "acceptCommunicationBetweenCharacters": false,
                    "bufferedLanguagePreferences": dataForInsert,
                    "bufferedCommunicationBetweenCharacters": true,
                    "accept": false
                })
            })
                .then(res => res.json())
                .then(
                    async (result) => {

                    },
                    (error) => {
                        isSuccess = false
                        alert("Error on submit. Try again")
                    }
                )
        }

        return isSuccess
    }*/

    function getCharacterId(num) {
        let id= 0

        characterCommunications.map(info => {
            info.characters.map(character => {
                if (character.num === num) {
                    id = character.id
                }

                return character
            })

            return info
        })

        return id
    }

    async function oninputLinguistCharacterCommunicationCell(language, /*firstCharacterNum,*/
                                                             firstCharacterId, /*secondCharacterNum,*/
                                                             secondCharacterId, type, e/*, oldValue*/) {
        // console.log("UPDATE CELL", language, firstCharacterNum, secondCharacterNum, type, e/*, oldValue*/)

        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let value
        // let firstCharacterId = getCharacterId(firstCharacterNum)
        // let secondCharacterId = getCharacterId(secondCharacterNum)

        console.log("UPDATE CELL CHARACTER ID", firstCharacterId, secondCharacterId, characterCommunications)

        if (type === "input") {
            queryLinkExist += 'styleGuideLinguistGetCharactersDataByCharacters'
            queryUpdateCell += 'styleGuideLinguistUpdateCharactersData'
            queryInsertCell += 'styleGuideLinguistAddCharactersData'
            value = e.target.innerText
        }

        // if (value !== oldValue) {
            await fetch(queryLinkExist, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode,
                    "linguistId": linguistCode,
                    "languageCode": language,
                    "firstCharacter": firstCharacterId,
                    "secondCharacter": secondCharacterId
                })
            })
                .then(res => res.json())
                .then(
                    async (resultEditable) => {
                        if (resultEditable.length) {
                            await fetch(queryUpdateCell, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "linguistId": linguistCode,
                                    "languageCode": language,
                                    "firstCharacter": firstCharacterId,
                                    "secondCharacter": secondCharacterId,
                                    "value": value
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultUpdate) => {
                                        // console.log("Buffer", submit, submitValue, acceptedData[0].accept[language])

                                        /*if (submit && acceptedData[0].accept[language]) {
                                            setBufferedCommunicationBetweenCharactersData(language)
                                        }*/
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        } else {
                            await fetch(queryInsertCell, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "linguistId": linguistCode,
                                    "languageCode": language,
                                    "firstCharacter": firstCharacterId,
                                    "secondCharacter": secondCharacterId,
                                    "value": value
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultInsert) => {
                                        // console.log("Buffer", submit, submitValue, acceptedData[0].accept[language])

                                        /*if (submit && acceptedData[0].accept[language]) {
                                            setBufferedCommunicationBetweenCharactersData(language)
                                        }*/
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        }
                    },
                    (error) => {
                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                            "верное значение")
                    }
                )
        // }
    }

    async function setBufferedCommunicationBetweenCharactersData(language) {
        await fetch('/proxy/project_bible_template/updateStyleGuideLinguistBufferedCommunicationBetweenCharactersChanges', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguistCode,
                "languageCode": language,
                "bufferedChangedCommunicationBetweenCharacters": true
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setBufferedData(
                        bufferedData.map(info => {
                            info.communicationBetweenCharacters[language] = true

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    function openModalToAddLinksAndImages(value, method, identificationData) {
        setModalAddLinksAndImagesActive(true)

        console.log("SEARCH", value)

        setModalAddLinksAndImagesData(
            modalAddLinksAndImagesData.map(info => {
                let regexLink = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;
                let regexTagLink = /(<a[^>]*?href=\"((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))\">(.*?)<\/a>)/g;
                let regexLinkTitle = /<a[^>]*?href=\"(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\">(.*?)<\/a>/;
                let linkArray = value.match(regexLink) || []
                let tagLinkArray = value.match(regexTagLink) || []
                let associativeLinks = []
                let regexImg = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\".*?\">.*?<\/button>/g;
                let regexSrcTitle = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\"(.*?)\">(.*?)<\/button>/
                let imgArray = value.match(regexImg) || []
                let associativeImages = []

                for (let i = 0; i < tagLinkArray.length; i++) {
                    let link = tagLinkArray[i].match(regexLinkTitle)[1]

                    for (let j = 0; j < linkArray.length; j++) {
                        if (linkArray[j].includes(link)) {
                            linkArray[j] = link
                        }
                    }
                }

                for (let i = 0; i < linkArray.length; i++) {
                    associativeLinks[linkArray[i]] = {
                        "link": linkArray[i],
                        "title": "",
                        "tagExist": ""
                    }
                }

                for (let i = 0; i < imgArray.length; i++) {
                    let srcValue = imgArray[i].match(regexSrcTitle)[1]
                    let titleValue =  imgArray[i].match(regexSrcTitle)[2]

                    associativeImages[i] = {
                        "src": srcValue,
                        "title": titleValue
                    }
                }

                for (let i = 0; i < tagLinkArray.length; i++) {
                    let link = tagLinkArray[i].match(regexLinkTitle)[1]
                    let linkTitle =  tagLinkArray[i].match(regexLinkTitle)[2]

                    associativeLinks[link].tagExist = tagLinkArray[i]
                    associativeLinks[link].title = linkTitle
                }

                console.log("associativeImages", associativeImages)

                info.value = value
                info.saveMethod = method
                info.identificationData = identificationData
                info.links = associativeLinks
                info.images = associativeImages
                info.bufferedImages = associativeImages

                return info
            })
        )
    }

    async function languageTabSavePreferencesCellValue(languageCode, block, column, row, type, value, linguistExtraRow, refillCell, extraFromOtherLinguist) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'

        if (type === "input") {
            queryLinkExist += 'styleGuideLinguistLanguagesFilledCellTextByCode'
            queryUpdateCell += 'styleGuideLinguistLanguagesOninputUpdateTextCell'
            queryInsertCell += 'styleGuideLinguistLanguagesOninputInsertTextCell'
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "id": linguistCode,
                "languageCode": languageCode,
                "block": block,
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "id": linguistCode,
                                "languageCode": languageCode,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value)
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    // if (submit/* && acceptedData[0].accept[languageCode]*/) {
                                        setBufferedLanguagePreferencesUpdatedCellData(languageCode, block, row, linguistExtraRow, extraFromOtherLinguist)
                                    // }

                                    if (refillCell) {
                                        setLanguages(
                                            languages.map(info => {
                                                info.data.map(languageValue => {
                                                    if (languageValue.code === languageCode) {
                                                        languageValue.data[0][block].data.map(rowData => {
                                                            if (rowData.code === row) {
                                                                rowData.row.map(columnData => {
                                                                    if (columnData.code === column) {
                                                                        columnData.text = value

                                                                        triggerOpenInModalAddedImage()
                                                                    }

                                                                    return columnData
                                                                })
                                                            }

                                                            return rowData
                                                        })
                                                    }

                                                    return languageValue
                                                })

                                                return info
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                },
                                (error) => {
                                    alert("Error on cell save")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "id": linguistCode,
                                "languageCode": languageCode,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value)
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    // if (submit/* && acceptedData[0].accept[languageCode]*/) {
                                        setBufferedLanguagePreferencesUpdatedCellData(languageCode, block, row, linguistExtraRow, extraFromOtherLinguist)
                                    // }

                                    if (refillCell) {
                                        setLanguages(
                                            languages.map(info => {
                                                info.data.map(languageValue => {
                                                    if (languageValue.code === languageCode) {
                                                        languageValue.data[0][block].data.map(rowData => {
                                                            if (rowData.code === row) {
                                                                rowData.row.map(columnData => {
                                                                    if (columnData.code === column) {
                                                                        columnData.text = value

                                                                        triggerOpenInModalAddedImage()
                                                                    }

                                                                    return columnData
                                                                })
                                                            }

                                                            return rowData
                                                        })
                                                    }

                                                    return languageValue
                                                })

                                                return info
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                },
                                (error) => {
                                    alert("Error on cell save")
                                }
                            )
                    }
                }
            )
    }

    function removeDataJustUploadedParam(value) {
        let newValue = value

        newValue = newValue.replace(/data-just-uploaded='1'/g,'')
        newValue = newValue.replace(/data-just-uploaded="1"/g,'')

        return newValue
    }

    function triggerOpenInModalAddedImage() {
        const openInModalAddedImageArray = document.getElementsByClassName("open-in-modal-added-image")

        for (const trigger of openInModalAddedImageArray) {
            trigger.addEventListener('mouseover', (e) => {
                console.log("TRIGGER text", trigger)
                console.log("TRIGGER text trigger.innerHTML", trigger.innerHTML)
                console.log("TRIGGER text trigger.innerText", trigger.innerText)
                console.log("TRIGGER text e.target.dataset.src", e.target.dataset.src)

                setTooltipImageData(
                    tooltipImageData.map(data => {
                        data.src = e.target.dataset.src
                        data.title = trigger.innerText
                        data.element = e
                        data.justUploaded = "justUploaded" in e.target.dataset && e.target.dataset.justUploaded == "1";

                        return data
                    })
                )
            });
        }
    }

    function openInModalAddedImage(path) {
        console.log("CLICK openInModalAddedImage")

        setModalOpenAddedImageSrc(path)
        setModalOpenAddedImageActive(true)
    }

    function setImagesBufferedData(bufferedData) {
        setModalAddLinksAndImagesData(
            modalAddLinksAndImagesData.map(info => {
                info.bufferedImages = bufferedData

                return info
            })
        )
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Error: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded || !isLanguageLoaded || (communicationBetweenCharacters && !isCharacterCommunicationLoaded &&
        !isLinguistCharacterCommunicationLoaded) || (logOfNewBatches && !isLogOfNewBatches)) {
        return (
            <div className="row" style={styles.progressBar}>
                <div className="col-sm-4">

                </div>
                <div className="col-sm-4 center">
                    <h4>Loading...</h4>
                    <ProgressBar now={progressPercent} label={`${progressPercent}%`} />
                </div>
                <div className="col-sm-4">

                </div>
                <AlertModal modalAlertActive={modalAlertActive}
                            setModalAlertActive={setModalAlertActive}
                            alertCase={alertCase}
                            setRedirect={setRedirect}
                />
            </div>
        )
    } else {
        let showLinguistEmail = false

        if (window.location.href.split("/")[2] === "localhost:3000" ||
            (clientName === "_ТестовыйКлиент_" && projectName === "TestProject1" && projectCode === "4584")) {
            showLinguistEmail = true
        }

        return(
            <div className="main-block-div-dark">
                <div className="row">
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicClientName">
                                <Form.Label>Client name</Form.Label>
                                <Form.Control type="clientName" disabled
                                              placeholder={queryStringParams.client_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectName">
                                <Form.Label>Project name</Form.Label>
                                <Form.Control type="projectName" disabled
                                              placeholder={queryStringParams.project_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectCode">
                                <Form.Label>Project code</Form.Label>
                                <Form.Control type="projectCode" disabled
                                              placeholder={queryStringParams.project_code} />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                {
                    showLinguistEmail &&
                    <>
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicProjectCode">
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control type="linguistCode" disabled
                                                      placeholder={linguistCode} />
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </>
                }
                <br />
                <br />
                <StyleGuideTabs logOfNewBatches={logOfNewBatches}
                                logOfNewBatchesHeaders={logOfNewBatchesData[0].headers}
                                logOfNewBatchesRowsCount={logOfNewBatchesData[0].rowsCount}
                                logOfNewBatchesRowsData={logOfNewBatchesData[0].rowsData}
                                rows={rows[0].data} columns={columns[0].data}
                                languages={languages[0].data}
                                acceptedData={acceptedData[0]}
                                bufferedData={bufferedData[0]}
                                addRowToStyleGuideLanguageBlock={addRowToStyleGuideLanguageBlock}
                                characterCommunications={characterCommunications}
                                communicationBetweenCharacters={communicationBetweenCharacters}
                                oninputLinguistCharacterCommunicationCell={oninputLinguistCharacterCommunicationCell}
                                setBufferedLanguagePreferencesUpdatedCellData={setBufferedLanguagePreferencesUpdatedCellData}
                                // submit={submit}
                                linguistCode={linguistCode}
                                modalAddLinksAndImagesActive={modalAddLinksAndImagesActive}
                                modalOpenAddedImageActive={modalOpenAddedImageActive}
                                triggerOpenInModalAddedImage={triggerOpenInModalAddedImage}
                                openModalToAddLinksAndImages={openModalToAddLinksAndImages}
                                languageTabSavePreferencesCellValue={languageTabSavePreferencesCellValue}
                />
                {/*{
                    !submit &&
                        <>
                            <br />
                            <br />
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <Button variant="primary" onClick={(e) => submitStyleGuideLinguist()}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </>
                }*/}
                <AddLinksAndImagesModal modalAddLinksAndImagesActive={modalAddLinksAndImagesActive}
                                        setModalAddLinksAndImagesActive={setModalAddLinksAndImagesActive}
                                        modalAddLinksAndImagesData={modalAddLinksAndImagesData}
                                        setModalAddLinksAndImagesData={setModalAddLinksAndImagesData}
                                        openInModalAddedImage={openInModalAddedImage}
                                        setImagesBufferedData={setImagesBufferedData}
                                        languageTabSavePreferencesCellValue={languageTabSavePreferencesCellValue}
                />
                <OpenInModalAddedImage modalOpenAddedImageActive={modalOpenAddedImageActive}
                                       setModalOpenAddedImageActive={setModalOpenAddedImageActive}
                                       modalOpenAddedImageSrc={modalOpenAddedImageSrc}
                />
                <OpenInTooltipAddedImage tooltipImageSrc={tooltipImageData[0].src}
                                         tooltipImageTitle={tooltipImageData[0].title}
                                         tooltipImageJustUploaded={tooltipImageData[0].justUploaded}
                />
            </div>
        )
    }
}