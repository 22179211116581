import React, {useEffect, useState} from "react";
import "./css/forms.css"
import queryString from "query-string";
import {Button, Form, FormControl, Tab, Tabs} from "react-bootstrap";
import DateInput from 'react-input-date'
import CategoryFieldsForm from "./CategoryFieldsForm"
import AlertMessageModal from "./AlertMessageModal";

const styles = {
    formLabel: {
        marginBottom: '0px'
    }
}

export default function JoinOurTeamForm(props) {
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    // const [selectedCategory, setSelectedCategory] = useState("default")
    const [categoriesData, setCategoriesData] = useState([{
        localization: {
            title: "translator/editor",
            submit: false,
            fields: {
                "freelancer_agency_6": {
                    title: "Are you a freelancer or an agency? (select an option)",
                    attioType: "record",
                    type: "select",
                    selectOptions: {
                        '-Individual freelancer': {
                            attioCode: "Freelancer",
                        },
                        '-Translation agency': {
                            attioCode: "Agency",
                        }
                    },
                    required: true
                },
                "first_name": {
                    title: "First name",
                    attioType: "record",
                    description: "What is your first name?",
                    type: "input",
                    required: true
                },
                "last_name": {
                    title: "Last Name",
                    attioType: "record",
                    description: "What is your last name?",
                    type: "input",
                    required: true
                },
                "gender_7": {
                    title: "Preferred gender pronoun",
                    attioType: "record",
                    description: "Please select the pronoun you prefer to be addressed by.",
                    type: "select",
                    selectOptions: {
                        'Male': {
                            attioCode: "Male",
                        },
                        'Female': {
                            attioCode: "Female",
                        },
                        'Other': {
                            attioCode: "Other"
                        }
                    },
                    required: false
                },
                "email_addresses": {
                    title: "E-mail",
                    attioType: "record",
                    description: "What is your best contact email address?",
                    type: "input",
                    required: true
                },
                "email_addresses_additional": {
                    title: "Secondary e-mail (if used) ",
                    attioType: "record",
                    type: "input",
                    required: false
                },
                "contacts": {
                    title: "Skype / Discord / Telegram / WhatsApp",
                    attioType: "record",
                    description: "Please provide your contact information for any of the following messengers you use.",
                    type: "textarea",
                    required: false
                },
                "availability": {
                    title: "Availability",
                    attioType: "list",
                    description: "Please indicate your available days and hours for work.",
                    type: "textarea",
                    required: false
                },
                "work_on_weekends_4": {
                    title: "Do you work weekends? (select an option)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {
                        'Yes, always': {
                            attioCode: "Yes, always",
                        },
                        'Yes, sometimes': {
                            attioCode: "Yes, sometimes",
                        },
                        'No': {
                            attioCode: "Yes, sometimes",
                        },
                        'Only on Saturdays': {
                            attioCode: "Only on Saturdays",
                        },
                        'Only on Sundays': {
                            attioCode: "Only on Sundays",
                        },
                        'It depends': {
                            attioCode: "It depends",
                        }
                    },
                    required: false
                },
                "cv": {
                    title: "CV",
                    attioType: "record",
                    description: "Please attach your CV.",
                    type: "file",
                    required: true
                },
                "certifications": {
                    title: "Certifications",
                    attioType: "record",
                    description: "Please list any relevant certifications you hold.",
                    type: "textarea",
                    required: false
                },
                "country": {
                    title: "Country",
                    attioType: "record",
                    description: "What country do you live in?",
                    type: "input",
                    required: true
                },
                "city": {
                    title: "City",
                    attioType: "record",
                    description: "What city do you live in?",
                    type: "input",
                    required: true
                },
                "target_language": {
                    title: "Mother language (select an option)",
                    attioType: "record",
                    type: "select",
                    selectOptions: {} /*{
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    }*/,
                    required: true
                },
                "source_languages": {
                    title: "First source language (select options)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {} /*{
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    }*/,
                    required: true
                },
                "source_languages_additional": {
                    title: "Second source language (select options)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {} /*{
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    }*/,
                    required: false
                },
                "memoq": {
                    title: "MemoQ experience",
                    attioType: "list",
                    description: "Please check the box if you are familiar with this translation software.",
                    type: "checkbox",
                    required: false
                },
                "translation_rate": {
                    title: "Translation rate per source word/character",
                    attioType: "list",
                    description: "What is your standard rate for translation?",
                    type: "num",
                    required: true
                },
                "proofreading_rate": {
                    title: "Proofreading rate per source word/character ",
                    attioType: "list",
                    description: "What is your standard rate for proofreading? (Please, leave a dash if you don’t proofread).",
                    type: "num",
                    required: true
                },
                "hourly_rate": {
                    title: "Standard hourly rate",
                    attioType: "list",
                    description: "What is your standard hourly rate?",
                    type: "num",
                    required: true
                },
                "currency": {
                    title: "Currency",
                    attioType: "record",
                    description: "What currency do you prefer to get payment in?",
                    type: "select",
                    selectOptions: {
                        'USD': {
                            attioCode: "USD",
                        },
                        'EUR': {
                            attioCode: "EUR",
                        }
                    },
                    required: true
                },
                "experience": {
                    title: "How many years have you been localizing games?",
                    attioType: "list",
                    type: "num",
                    required: true
                },
                "favorite_genres": {
                    title: "Favorite genres (select options)",
                    attioType: "list",
                    type: "multiselect",
                    selectOptions: {} /*{
                        'Action': {},
                        'Adventure': {},
                        'Role-Playing (RPG)': {},
                        'Simulation': {},
                        'Strategy': {},
                        'Sports': {},
                        'Puzzle': {},
                        'Casual': {},
                        'Arcade': {},
                        'Shooter': {},
                        'Horror': {},
                        'Racing': {},
                        'Fighting': {},
                        'Platformer': {},
                        'Stealth': {},
                        'Survival': {},
                        'Music/Rhythm': {},
                        'Battle Royale': {},
                        'Visual Novel': {},
                        'Sandbox': {}
                    }*/,
                    required: false
                },
                "disapproved_genres": {
                    title: "Genres to avoid (select options)",
                    attioType: "list",
                    type: "multiselect",
                    selectOptions: {} /*{
                        'Action': {},
                        'Adventure': {},
                        'Role-Playing (RPG)': {},
                        'Simulation': {},
                        'Strategy': {},
                        'Sports': {},
                        'Puzzle': {},
                        'Casual': {},
                        'Arcade': {},
                        'Shooter': {},
                        'Horror': {},
                        'Racing': {},
                        'Fighting': {},
                        'Platformer': {},
                        'Stealth': {},
                        'Survival': {},
                        'Music/Rhythm': {},
                        'Battle Royale': {},
                        'Visual Novel': {},
                        'Sandbox': {}
                    }*/,
                    required: false
                },
                "completed_titles" : {
                    title: "Relevant titles you've worked on ",
                    attioType: "record",
                    description: "Please list the relevant projects or titles you have worked on in the past.",
                    type: "textarea",
                    required: false
                },
                "type_of_employment_7": {
                    title: "Type of employment",
                    attioType: "record",
                    type: "select",
                    selectOptions: {
                        'I\'m officially registered as self-employed': {
                            attioCode: "Self-employed",
                        },
                        'I\'m officially registered as a business': {
                            attioCode: "Business",
                        },
                        'Other': {
                            attioCode: "Other",
                        }
                    },
                    required: true
                },
                "cover_letter": {
                    title: "Cover letter",
                    attioType: "list",
                    description: "We'll be happy to get to know you a bit more, so write a few lines about yourself if you'd like to.",
                    type: "textarea",
                    required: false
                },
                "resource_3": {
                    title: "Resource (select options)",
                    attioType: "record",
                    description: "How you heard about us?",
                    type: "select",
                    selectOptions: {
                        'ProZ': {
                            attioCode: "ProZ",
                        },
                        'TranslatorsCafe': {
                            attioCode: "TranslatorsCafe",
                        },
                        'TRWorkShop': {
                            attioCode: "TRWorkShop",
                        },
                        'AllcorrectGames': {
                            attioCode: "AllcorrectGames",
                        },
                        'networking': {
                            attioCode: "networking",
                        },
                        'Linkedin': {
                            attioCode: "Linkedin",
                        },
                        'HeadHunter': {
                            attioCode: "HeadHunter",
                        },
                        'Discord': {
                            attioCode: "Discord",
                        },
                        'JAT': {
                            attioCode: "JAT",
                        },
                        'other': {
                            attioCode: "other",
                        }
                    },
                    required: true
                },
                "data_processing_agreement": {
                    title: "Agreement to personal data processing",
                    attioType: "record",
                    description: "I hereby give consent for my personal data included in the CV to be processed for the purposes of recruitment. I agree with GDPR policy https://allcorrectgames.com/gdpr/",
                    type: "checkbox",
                    required: true
                },
                "send_copy_of_answers": {
                    title: "Send copy of answers to my email",
                    type: "checkbox",
                    required: false
                }
            }
        },
        lqa: {
            title: "linguistic game tester",
            submit: false,
            fields: {
                "freelancer_agency_6": {
                    title: "Are you a freelancer or an agency? (select an option)",
                    attioType: "record",
                    type: "select",
                    selectOptions: {
                        '-Individual freelancer': {
                            attioCode: "Freelancer",
                        },
                        '-Translation agency': {
                            attioCode: "Agency",
                        }
                    },
                    required: true
                },
                "first_name": {
                    title: "First name",
                    attioType: "record",
                    description: "What is your first name?",
                    type: "input",
                    required: true
                },
                "last_name": {
                    title: "Last Name",
                    attioType: "record",
                    description: "What is your last name?",
                    type: "input",
                    required: true
                },
                "gender_7": {
                    title: "Preferred gender pronoun",
                    attioType: "record",
                    description: "Please select the pronoun you prefer to be addressed by.",
                    type: "select",
                    selectOptions: {
                        'Male': {
                            attioCode: "Male",
                        },
                        'Female': {
                            attioCode: "Female",
                        },
                        'Other': {
                            attioCode: "Other",
                        }
                    },
                    required: false
                },
                "email_addresses": {
                    title: "E-mail",
                    attioType: "record",
                    description: "What is your best contact email address?",
                    type: "input",
                    required: true
                },
                "email_addresses_additional": {
                    title: "Secondary e-mail (if used) ",
                    attioType: "record",
                    type: "input",
                    required: false
                },
                "contacts": {
                    title: "Skype / Discord / Telegram / WhatsApp",
                    attioType: "record",
                    description: "Please provide your contact information for any of the following messengers you use.",
                    type: "textarea",
                    required: false
                },
                "availability": {
                    title: "Availability",
                    attioType: "list",
                    description: "Please indicate your available days and hours for work.",
                    type: "textarea",
                    required: false
                },
                "work_on_weekends": {
                    title: "Do you work weekends? (select an option)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {
                        'Yes, always': {
                            attioCode: "Yes, always",
                        },
                        'Yes, sometimes': {
                            attioCode: "Yes, sometimes",
                        },
                        'No': {
                            attioCode: "Yes, sometimes",
                        },
                        'Only on Saturdays': {
                            attioCode: "Only on Saturdays",
                        },
                        'Only on Sundays': {
                            attioCode: "Only on Sundays",
                        },
                        'It depends': {
                            attioCode: "It depends",
                        }
                    },
                    required: false
                },
                "cv": {
                    title: "CV",
                    attioType: "record",
                    description: "Please attach your CV.",
                    type: "file",
                    required: true
                },
                "certifications": {
                    title: "Certifications",
                    attioType: "record",
                    description: "Please list any relevant certifications you hold.",
                    type: "textarea",
                    required: false
                },
                "country": {
                    title: "Country",
                    attioType: "record",
                    description: "What country do you live in?",
                    type: "input",
                    required: true
                },
                "city": {
                    title: "City",
                    attioType: "record",
                    description: "What city do you live in?",
                    type: "input",
                    required: true
                },
                "target_language": {
                    title: "Mother language (select an option)",
                    attioType: "record",
                    type: "select",
                    selectOptions: {} /*{
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    }*/,
                    required: true
                },
                "foreign_languages_1": {
                    title: "First source language (select options)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {} /*{
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    }*/,
                    required: true
                },
                "foreign_languages_additional": {
                    title: "Second source language (select options)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {} /*{
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    }*/,
                    required: false
                },
                "level": {
                    title: "Foreign languages level (select options)",
                    attioType: "list",
                    description: "Select your level of proficiency in foreign languages from the following options.",
                    type: "select",
                    selectOptions: {
                        'Beginner': {
                            attioCode: "Beginner"
                        },
                        'Elementary': {
                            attioCode: "Elementary"
                        },
                        'Intermediate': {
                            attioCode: "Intermediate"
                        },
                        'Upper-Intermediate': {
                            attioCode: "Upper-Intermediate"
                        },
                        'Advanced': {
                            attioCode: "Advanced"
                        },
                        'Proficient': {
                            attioCode: "Proficient"
                        },
                        'Native': {
                            attioCode: "Native"
                        }
                    },
                    required: true
                },
                "hourly_rate": {
                    title: "Standard hourly rate",
                    attioType: "list",
                    description: "What is your standard hourly rate?",
                    type: "num",
                    required: true
                },
                "currency": {
                    title: "Currency",
                    attioType: "record",
                    description: "What currency do you prefer to get payment in?",
                    type: "select",
                    selectOptions: {
                        'USD': {
                            attioCode: "USD",
                        },
                        'EUR': {
                            attioCode: "EUR",
                        }
                    },
                    required: true
                },
                "platform_1": {
                    title: "Platform knowledge (select options)",
                    attioType: "list",
                    description: "Please select the platforms you have knowledge of from the dropdown list.",
                    type: "multiselect",
                    selectOptions: {},
                    required: true
                },
                "devices_os": {
                    title: "What devices do you own to play games? (select options)",
                    attioType: "list",
                    description: "Please select the devices you own for playing games from the dropdown list.",
                    type: "table",
                    tableStyle: [
                        {
                            title: "Device",
                            type: "select",
                            selectOptions: {}
                        },
                        {
                            title: "OS",
                            type: "select",
                            selectOptions: {}
                        },
                        {
                            title: "OS version",
                            type: "input"
                        }
                    ],
                    required: true
                },
                "favorite_genres_7": {
                    title: "Favorite genres (select options)",
                    attioType: "list",
                    type: "multiselect",
                    selectOptions: {},
                    required: false
                },
                "disapproved_genres_7": {
                    title: "Genres to avoid (select options)",
                    attioType: "list",
                    type: "multiselect",
                    selectOptions: {},
                    required: false
                },
                "completed_titles" : {
                    title: "Relevant titles you've worked on ",
                    attioType: "record",
                    description: "Please list the relevant projects or titles you have worked on in the past.",
                    type: "textarea",
                    required: false
                },
                "type_of_employment_7": {
                    title: "Type of employment",
                    attioType: "record",
                    type: "select",
                    selectOptions: {
                        'I\'m officially registered as self-employed': {
                            attioCode: "Self-employed",
                        },
                        'I\'m officially registered as a business': {
                            attioCode: "Business",
                        },
                        'Other': {
                            attioCode: "Other",
                        }
                    },
                    required: true
                },
                "cover_letter": {
                    title: "Cover letter",
                    attioType: "list",
                    description: "We'll be happy to get to know you a bit more, so write a few lines about yourself if you'd like to.",
                    type: "textarea",
                    required: false
                },
                "resource_3": {
                    title: "Resource (select options)",
                    attioType: "record",
                    description: "How you heard about us?",
                    type: "select",
                    selectOptions: {
                        'ProZ': {
                            attioCode: "ProZ",
                        },
                        'TranslatorsCafe': {
                            attioCode: "TranslatorsCafe",
                        },
                        'TRWorkShop': {
                            attioCode: "TRWorkShop",
                        },
                        'AllcorrectGames': {
                            attioCode: "AllcorrectGames",
                        },
                        'networking': {
                            attioCode: "networking",
                        },
                        'Linkedin': {
                            attioCode: "Linkedin",
                        },
                        'HeadHunter': {
                            attioCode: "HeadHunter",
                        },
                        'Discord': {
                            attioCode: "Discord",
                        },
                        'JAT': {
                            attioCode: "JAT",
                        },
                        'other': {
                            attioCode: "other",
                        }
                    },
                    required: true
                },
                "data_processing_agreement": {
                    title: "Agreement to personal data processing",
                    attioType: "record",
                    description: "I hereby give consent for my personal data included in the CV to be processed for the purposes of recruitment. I agree with GDPR policy https://allcorrectgames.com/gdpr/",
                    type: "checkbox",
                    required: true
                },
                "send_copy_of_answers": {
                    title: "Send copy of answers to my email",
                    type: "checkbox",
                    required: false
                }
            }
        },
        art: {
            title: "art",
            submit: false,
            fields: {
                "first_name": {
                    title: "First name",
                    attioType: "record",
                    description: "What is your first name?",
                    type: "input",
                    required: true
                },
                "last_name": {
                    title: "Last Name",
                    attioType: "record",
                    description: "What is your last name?",
                    type: "input",
                    required: true
                },
                "portfolio_link": {
                    title: "Portfolio link",
                    attioType: "list",
                    type: "input",
                    required: true
                },
                "cover_letter": {
                    title: "Cover letter",
                    attioType: "list",
                    description: "Tell us about your skills and experience",
                    type: "textarea",
                    required: false
                },
                "email_addresses": {
                    title: "E-mail",
                    attioType: "record",
                    description: "What is your best contact email address?",
                    type: "input",
                    required: true
                },
                "contacts": {
                    title: "Messenger/Other contact",
                    attioType: "record",
                    description: "Please provide your contact information for any of the following messengers you use.",
                    type: "textarea",
                    required: true
                },
                "country": {
                    title: "Country",
                    attioType: "record",
                    description: "What country do you live in?",
                    type: "input",
                    required: true
                },
                "english_level_6": {
                    title: "English level (select an option)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {
                        'A1-A2': {
                            attioCode: "A1-A2"
                        },
                        'B1': {
                            attioCode: "B1"
                        },
                        'B2': {
                            attioCode: "B2"
                        },
                        'C1': {
                            attioCode: "C1"
                        },
                        'Native': {
                            attioCode: "Native"
                        }
                    },
                    required: true
                },
                "target_language": {
                    title: "Native language (select an option)",
                    attioType: "record",
                    type: "select",
                    selectOptions: {},
                    required: true
                },
                "hourly_rate_7": {
                    title: "Hourly rate (USD)",
                    attioType: "list",
                    description: "",
                    type: "num",
                    required: true
                },
                "experience": {
                    title: "Work experience in years?",
                    attioType: "list",
                    description: "",
                    type: "num",
                    required: true
                },
                "level": {
                    title: "Level (select an option)",
                    attioType: "list",
                    type: "select",
                    selectOptions: {
                        'Junior': {
                            attioCode: "Junior"
                        },
                        'Middle': {
                            attioCode: "Middle"
                        },
                        'Senior': {
                            attioCode: "Senior"
                        }
                    },
                    required: true
                },
                "2d_3d": {
                    title: "2D/3D (select options)",
                    attioType: "list",
                    type: "multiselect",
                    selectOptions: {
                        '2D': {
                            attioCode: "2D"
                        },
                        '3D': {
                            attioCode: "3D"
                        },
                        'Not graphic': {
                            attioCode: "Not graphic"
                        }
                    },
                    required: true
                },
                "graphics": {
                    title: "Graphics (select options)",
                    attioType: "list",
                    type: "multiselect",
                    selectOptions: {
                        '2D Character': {
                            attioCode: "2D Character"
                        },
                        '2D Environment': {
                            attioCode: "2D Environment"
                        },
                        '2D Vector': {
                            attioCode: "2D Vector"
                        },
                        '2D Concept': {
                            attioCode: "2D Concept"
                        },
                        '2D Casual': {
                            attioCode: "2D Casual"
                        },
                        '2D Midcore': {
                            attioCode: "2D Midcore"
                        },
                        '2D Realism': {
                            attioCode: "2D Realism"
                        },
                        '2D Anime': {
                            attioCode: "2D Anime"
                        },
                        '2D Comics': {
                            attioCode: "2D Comics"
                        },
                        '2D Illustration': {
                            attioCode: "2D Illustration"
                        },
                        '2D Pixel Art': {
                            attioCode: "2D Pixel Art"
                        },
                        '2D Animation': {
                            attioCode: "2D Animation"
                        },
                        '2D Spine Animation': {
                            attioCode: "2D Spine Animation"
                        },
                        '3D Character': {
                            attioCode: "3D Character"
                        },
                        '3D Environment': {
                            attioCode: "3D Environment"
                        },
                        '3D Hardsurface': {
                            attioCode: "3D Hardsurface"
                        },
                        '3D Organics': {
                            attioCode: "3D Organics"
                        },
                        '3D Realism': {
                            attioCode: "3D Realism"
                        },
                        '3D Texturing': {
                            attioCode: "3D Texturing"
                        },
                        '3D HandPaint Texture': {
                            attioCode: "3D HandPaint Texture"
                        },
                        '3D Rigging': {
                            attioCode: "3D Rigging"
                        },
                        '3D Animation': {
                            attioCode: "3D Animation"
                        },
                        '3D Tech Art': {
                            attioCode: "3D Tech Art"
                        },
                        '3D VFX': {
                            attioCode: "3D VFX"
                        },
                        'UI / UX Design': {
                            attioCode: "UI / UX Design"
                        },
                        'Playable Ads': {
                            attioCode: "Playable Ads"
                        },
                        'Photobash': {
                            attioCode: "Photobash"
                        },
                        'Graphic/Marketing Design': {
                            attioCode: "Graphic/Marketing Design"
                        },
                        'Storyboards': {
                            attioCode: "Storyboards"
                        },
                        'Other': {
                            attioCode: "Other"
                        }
                    },
                    required: true
                },
                "software": {
                    title: "Software (select options)",
                    attioType: "list",
                    type: "multiselect",
                    selectOptions: {
                        'Adobe Photoshop rastr': {
                            attioCode: "Adobe Photoshop rastr"
                        },
                        'Adobe Photoshop vector': {
                            attioCode: "Adobe Photoshop vector"
                        },
                        'Adobe Illustrator': {
                            attioCode: "Adobe Illustrator"
                        },
                        '3D Max': {
                            attioCode: "3D Max"
                        },
                        'Maya': {
                            attioCode: "Maya"
                        },
                        'Blender': {
                            attioCode: "Blender"
                        },
                        'Zbrush': {
                            attioCode: "Zbrush"
                        },
                        'Marmoset': {
                            attioCode: "Marmoset"
                        },
                        'Substance Painter': {
                            attioCode: "Substance Painter"
                        },
                        'Maya Arnold': {
                            attioCode: "Maya Arnold"
                        },
                        'Blender Cycles': {
                            attioCode: "Blender Cycles"
                        },
                        'VRay': {
                            attioCode: "VRay"
                        },
                        'Keyshot': {
                            attioCode: "Keyshot"
                        },
                        'Spine': {
                            attioCode: "Spine"
                        },
                        'Unity3D': {
                            attioCode: "Unity3D"
                        },
                        'Sketch': {
                            attioCode: "Sketch"
                        },
                        'Other': {
                            attioCode: "Other"
                        },
                    },
                    required: true
                },
                "resource_3": {
                    title: "Resource (select options)",
                    attioType: "record",
                    description: "How you heard about us?",
                    type: "select",
                    selectOptions: {
                        'AllcorrectGames': {
                            attioCode: "AllcorrectGames",
                        },
                        'Linkedin': {
                            attioCode: "Linkedin",
                        },
                        'Twitter': {
                            attioCode: "Twitter",
                        },
                        'Artstation': {
                            attioCode: "Artstation",
                        },
                        'Instagram': {
                            attioCode: "Instagram",
                        },
                        'Ingamejob': {
                            attioCode: "Ingamejob",
                        },
                        'Discord': {
                            attioCode: "Discord",
                        },
                        'Telegram': {
                            attioCode: "Telegram",
                        },
                        'other': {
                            attioCode: "other",
                        }
                    },
                    required: true
                },
                "lead_artist_experience_6": {
                    title: "Do you have an experience as a Lead Artist?",
                    attioType: "list",
                    type: "checkbox",
                    required: false
                },
                "cv": {
                    title: "CV",
                    attioType: "record",
                    description: "Please attach your CV.",
                    type: "file",
                    required: true
                },
                "data_processing_agreement": {
                    title: "Personal data",
                    attioType: "record",
                    description: "I hereby agree to the Terms of processing of my personal data.",
                    type: "checkbox",
                    required: true
                },
                "send_copy_of_answers": {
                    title: "Send copy of answers to my email",
                    type: "checkbox",
                    required: false
                }
            }
        }
    }])
    const [filledValues, setFilledValues] = useState([{
        data: []
    }])
    const [modalAlertActive, setModalAlertActive] = useState(false);
    const [modalAlertData, setModalAlertData] = useState([{
        message: ""
    }]);

    useEffect(async () => {
        const response = await fetch('/proxy/get_list_of_lang_os_genres_platforms', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        let result = await response.json();
        let languagesFrom1C = result.languages
        let languagesList = {}
        let devicesFrom1C = result.devices
        let devicesList = {}
        let osFrom1C = result.os
        let osList = {}
        let genresFrom1C = result.genres
        let genresList = {}
        let platformsFrom1C = result.platforms
        let platformsList = {}

        for (let i = 0; i < languagesFrom1C.length; i++) {
            languagesList[languagesFrom1C[i]] = {
                attioCode: languagesFrom1C[i]
            }
        }

        for (let i = 0; i < devicesFrom1C.length; i++) {
            devicesList[devicesFrom1C[i]] = {
                attioCode: devicesFrom1C[i]
            }
        }

        for (let i = 0; i < osFrom1C.length; i++) {
            osList[osFrom1C[i]] = {
                attioCode: osFrom1C[i]
            }
        }

        for (let i = 0; i < genresFrom1C.length; i++) {
            genresList[genresFrom1C[i]] = {
                attioCode: genresFrom1C[i]
            }
        }

        for (let i = 0; i < platformsFrom1C.length; i++) {
            platformsList[platformsFrom1C[i]] = {
                attioCode: platformsFrom1C[i]
            }
        }

        setCategoriesData(
            categoriesData.map(item => {
                let userLang = navigator.language || navigator.userLanguage;

                if (userLang === "ru") {
                    let ruCurrencyValue = {
                        attioCode: "RUR"
                    }

                    item.localization.fields["currency"].selectOptions["RUR"] = ruCurrencyValue
                    item.lqa.fields["currency"].selectOptions["RUR"] = ruCurrencyValue
                }

                item.localization.fields["target_language"].selectOptions = languagesList
                item.localization.fields["source_languages"].selectOptions = languagesList
                item.localization.fields["source_languages_additional"].selectOptions = languagesList
                item.localization.fields["favorite_genres"].selectOptions = genresList
                item.localization.fields["disapproved_genres"].selectOptions = genresList

                item.lqa.fields["target_language"].selectOptions = languagesList
                item.lqa.fields["foreign_languages_1"].selectOptions = languagesList
                item.lqa.fields["foreign_languages_additional"].selectOptions = languagesList
                item.lqa.fields["platform_1"].selectOptions = platformsList
                item.lqa.fields["devices_os"].tableStyle[0].selectOptions = devicesList
                item.lqa.fields["devices_os"].tableStyle[1].selectOptions = osList
                item.lqa.fields["favorite_genres_7"].selectOptions = genresList
                item.lqa.fields["disapproved_genres_7"].selectOptions = genresList

                item.art.fields["target_language"].selectOptions = languagesList

                return item
            })
        )

        await setNewAttioOptions("objects", "people", "target_language", languagesFrom1C)
        await setNewAttioOptions("lists", "be62d79d-f771-4449-93f7-328f3b40fc92", "source_languages", languagesFrom1C)
        await setNewAttioOptions("lists", "be62d79d-f771-4449-93f7-328f3b40fc92", "favorite_genres", genresFrom1C)
        await setNewAttioOptions("lists", "be62d79d-f771-4449-93f7-328f3b40fc92", "disapproved_genres", genresFrom1C)
        await setNewAttioOptions("lists", "c70caa71-dbf9-4b62-9e0c-fb5792b506c2", "foreign_languages_1", languagesFrom1C)
        await setNewAttioOptions("lists", "c70caa71-dbf9-4b62-9e0c-fb5792b506c2", "platform_1", platformsFrom1C)
        // await setNewAttioOptions("lists", "c70caa71-dbf9-4b62-9e0c-fb5792b506c2", "devices_5", platformsFrom1C)
        await setNewAttioOptions("lists", "c70caa71-dbf9-4b62-9e0c-fb5792b506c2", "favorite_genres_7", genresFrom1C)
        await setNewAttioOptions("lists", "c70caa71-dbf9-4b62-9e0c-fb5792b506c2", "disapproved_genres_7", genresFrom1C)
    },[])

    async function setNewAttioOptions(targetObject, targetObjectId, fieldId, listOfOptions) {
        const response = await fetch('/proxy/set_attio_options_for_lang_os_genres_platforms', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                targetObject: targetObject,
                targetObjectId: targetObjectId,
                fieldId: fieldId,
                listOfOptions: listOfOptions
            })
        });
        let result = await response.json();
    }

    let getCategoryTabs = () => {
        let content = []

        for (let key in categoriesData[0]) {
            let value = categoriesData[0][key]

            content.push(
                <Tab key={key} eventKey={key}
                     title={value.title}>
                    <div className="tab-item-wrapper">
                        <br/>
                        <CategoryFieldsForm
                            category={key}
                            data={categoriesData[0]}
                            setFieldValue={setFieldValue}
                            setTableFieldValue={setTableFieldValue}
                            addTableRow={addTableRow}
                            setAdditionalFieldValue={setAdditionalFieldValue}
                            showAlertMessage={showAlertMessage}
                            validateEmail={validateEmail}
                            filledValues={filledValues[0].data}
                        />
                        <br/>
                        <br/>
                        {
                            !value.submit &&
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <Button
                                        variant="primary" className="btn btn-blue"
                                        onClick={(e) =>
                                            checkFillIn(key)}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </Tab>
            )
        }

        return content
    }

    async function setFieldValue(selectedCategory, id, type, attioType, value) {
        if (id === "send_copy_of_answers") {
            setFilledValues(
                filledValues.map(info => {
                    if (!(selectedCategory in info.data)) {
                        info.data[selectedCategory] = {}
                    }

                    info.data[selectedCategory][id] = value

                    return info
                })
            )
        } else {
            let filledValueToSet = {
                value: value,
                attioType: attioType,
                additionalFields: {}
            }

            if (type === "file") {
                if (value) {
                    const formData = new FormData()
                    let date = new Date();
                    let separator = 'img/folk/cv'
                    let fileDirectoryLocation = separator + '/' + ISODateString(date)

                    formData.append('myFile', value[0])

                    const response = await fetch('/proxy/upload_file?fileDirectoryLocation=' + fileDirectoryLocation + '&separator=' + separator, {
                        method: 'POST',
                        body: formData
                    });
                    let result = await response.json();

                    // filledValueToSet = {
                    //     value: result.fullPath,
                    //     additionalFields: {}
                    // }
                    filledValueToSet.value = result.fullPath
                }
            } else if (type === "num") {
                filledValueToSet.value = +value
            } else {
                // filledValueToSet = {
                //     value: value,
                //     additionalFields: {}
                // }
                filledValueToSet.value = value
            }

            setFilledValues(
                filledValues.map(info => {
                    if (!(selectedCategory in info.data)) {
                        info.data[selectedCategory] = {}
                    }

                    if (!(attioType in info.data[selectedCategory])) {
                        info.data[selectedCategory][attioType] = {}
                    }

                    info.data[selectedCategory][attioType][id] = filledValueToSet

                    return info
                })
            )
        }
    }

    async function setTableFieldValue(selectedCategory, id, type, attioType, indexOfRow, indexOfColumn, value) {
        console.log("setTableFieldValue", selectedCategory, id, type, attioType, indexOfRow, indexOfColumn, value)

        setFilledValues(
            filledValues.map(info => {
                if (!(selectedCategory in info.data)) {
                    info.data[selectedCategory] = {}
                }

                if (!(attioType in info.data[selectedCategory])) {
                    info.data[selectedCategory][attioType] = {}
                }

                if (id in info.data[selectedCategory][attioType]) {
                    info.data[selectedCategory][attioType][id].value[indexOfRow][indexOfColumn] = value
                } else {
                    let row = []

                    for (let i = 0; i < categoriesData[0][selectedCategory].fields[id].tableStyle.length; i++) {
                        row.push(indexOfColumn === i ? value : "")
                    }

                    let filledValueToSet = {
                        value: [row],
                        attioType: attioType,
                        additionalFields: {}
                    }

                    info.data[selectedCategory][attioType][id] = filledValueToSet
                }

                console.log("info.data[selectedCategory][attioType][id]", info.data[selectedCategory][attioType][id])

                return info
            })
        )
    }

    async function addTableRow(selectedCategory, id, type, attioType) {
        setFilledValues(
            filledValues.map(info => {
                let row = []


                for (let i = 0; i < categoriesData[0][selectedCategory].fields[id].tableStyle.length; i++) {
                    row.push("")
                }

                if (!(selectedCategory in info.data)) {
                    info.data[selectedCategory] = {}
                }

                if (!(attioType in info.data[selectedCategory])) {
                    info.data[selectedCategory][attioType] = {}
                }

                if (id in info.data[selectedCategory][attioType]) {
                    info.data[selectedCategory][attioType][id].value.push(row)
                } else {
                    let filledValueToSet = {
                        value: [row, row],
                        attioType: attioType,
                        additionalFields: {}
                    }

                    info.data[selectedCategory][attioType][id] = filledValueToSet
                }

                return info
            })
        )
    }

    async function setAdditionalFieldValue(selectedCategory, id, additionalId, type, attioType, value) {
        setFilledValues(
            filledValues.map(info => {
                info.data[selectedCategory][attioType][id].additionalFields[additionalId] = value

                return info
            })
        )
    }

    function ISODateString(date) {
        function pad(n) {
            return n < 10 ? '0' + n : n
        }
        return date.getUTCFullYear() + '-'
            + pad(date.getUTCMonth() + 1 )+ '-'
            + pad(date.getUTCDate())/* + 'T'
            + pad(date.getUTCHours()) + ':'
            + pad(date.getUTCMinutes()) + ':'
            + pad(date.getUTCSeconds()) + 'Z'*/
    }

    async function checkFillIn(selectedCategory) {
        let isPermissionToSubmit = true
        let listOfFields = categoriesData[0][selectedCategory].fields

        for (let key in listOfFields) {
            let value = listOfFields[key]

            if (value.required) {
                let fieldElId = "field-" + selectedCategory + "-" + key
                let fieldRedTextElId = "red-required-text-" + selectedCategory + "-" + key

                removeRedAlertBorder(fieldElId, fieldRedTextElId)

                if (!isValueFilled(selectedCategory, key, value.attioType, value, value.required)) {
                    isPermissionToSubmit = false

                    showRedAlertBorder(fieldElId, fieldRedTextElId)
                    showAlertMessage("required fields")
                }
            } else if (value.type === "select") {
                if (!isValueFilled(selectedCategory, key, value.attioType, value, value.required)) {
                    isPermissionToSubmit = false

                    showAlertMessage("required fields")
                }
            }
        }

        if (isPermissionToSubmit) {
            await submitForm(selectedCategory)
        }
    }

    function isValueFilled(selectedCategory, key, attioType, value, required) {
        if (value.type === "select" && !required) {
            if (selectedCategory in filledValues[0].data && attioType in filledValues[0].data[selectedCategory]) {
                if (key in filledValues[0].data[selectedCategory][attioType]) {
                    let fieldValue = filledValues[0].data[selectedCategory][attioType][key].value

                    return isSelectAdditionalFieldFilled(selectedCategory, key, attioType, fieldValue, value);
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            if (selectedCategory in filledValues[0].data && attioType in filledValues[0].data[selectedCategory]) {
                if (key in filledValues[0].data[selectedCategory][attioType]) {
                    let fieldValue = filledValues[0].data[selectedCategory][attioType][key].value

                    if (value.type === "input" || value.type === "textarea") {
                        let fieldValueWithoutSpaces = fieldValue.replace(/^\s+/, '').replace(/\s+$/, '');

                        if (fieldValueWithoutSpaces === "") {
                            return false
                        } else {
                            if (key.toLowerCase().indexOf("email") !== -1 && !validateEmail(fieldValue)) {
                                showAlertMessage("email")

                                return false
                            } else {
                                return true
                            }
                        }
                    } else if (value.type === "num") {
                        return fieldValue !== null
                    } else if (value.type === "checkbox") {
                        return !!fieldValue;
                    } else if (value.type === "select") {
                        if (fieldValue === "") {
                            return false
                        } else {
                            return isSelectAdditionalFieldFilled(selectedCategory, key, attioType, fieldValue, value);
                        }
                    } else if (value.type === "multiselect") {
                        return fieldValue.length !== 0;
                    } else if (value.type === "file") {
                        return fieldValue !== "";
                    } else if (value.type === "table") {
                        if (fieldValue.length) {
                            let tableColumnsLayout = categoriesData[0][selectedCategory].fields[key].tableStyle
                            let fillIn = true

                            for (let rowIndex = 0; rowIndex < fieldValue.length; rowIndex++) {
                                for (let colIndex = 0; colIndex < tableColumnsLayout.length; colIndex++) {
                                    if (tableColumnsLayout[colIndex].required && fieldValue[rowIndex][colIndex] === "") {
                                        fillIn = false

                                        break;
                                    }
                                }
                            }

                            return fillIn
                        } else {
                            return false
                        }
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        return true
    }

    function isSelectAdditionalFieldFilled(selectedCategory, key, attioType, fieldValue, value) {
        let selectOptionsValue = {}

        for (let key in value.selectOptions) {
            if (value.selectOptions[key].attioCode === fieldValue) {
                selectOptionsValue = value.selectOptions[key]
            }
        }

        if ("attioSlug" in selectOptionsValue && selectOptionsValue.required) {
            let additionalFieldId = selectOptionsValue.attioSlug
            let additionalFieldType = selectOptionsValue.type
            let additionalFieldElId = "field-" + selectedCategory + "-" + key + "-" + additionalFieldId
            let additionalFieldRedTextElId = "red-required-text-" + selectedCategory + "-" + key + "-" + additionalFieldId

            removeRedAlertBorder(additionalFieldElId, additionalFieldRedTextElId)

            if (additionalFieldId in filledValues[0].data[selectedCategory][attioType][key].additionalFields) {
                let additionalFieldValue = filledValues[0].data[selectedCategory][attioType][key].additionalFields[additionalFieldId]

                if (additionalFieldType === "input") {
                    let additionalFieldValueWithoutSpaces = additionalFieldValue.replace(/^\s+/, '').replace(/\s+$/, '');

                    if (additionalFieldValueWithoutSpaces === "") {
                        showRedAlertBorder(additionalFieldElId, additionalFieldRedTextElId)

                        return false
                    } else {
                        return true
                    }
                }
            } else {
                showRedAlertBorder(additionalFieldElId, additionalFieldRedTextElId)

                return false
            }
        } else {
            return true
        }
    }

    function removeRedAlertBorder(fieldElId, redTextId) {
        document.getElementById(redTextId).style.display = "none"
        document.getElementById(fieldElId).style.borderColor = "rgb(222, 226, 230)"
    }

    function showRedAlertBorder(fieldElId, redTextId) {
        document.getElementById(redTextId).style.display = "inline"
        document.getElementById(fieldElId).style.border = "1px solid #ff0000"
        document.getElementById(fieldElId).style.borderRadius = "5px"
        document.getElementById(fieldElId).focus()
    }

    function validateEmail(value) {
        return EMAIL_REGEXP.test(value)
    }

    async function submitForm(selectedCategory) {
        showAlertMessage("submit waiting")

        const response = await fetch('/proxy/addRecordToAttio', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                category: selectedCategory,
                data: getArrayOfData(selectedCategory)
            })
        });
        let result = await response.json();

        if ("error" in result) {
            showAlertMessage("submit fail")
        } else {
            showAlertMessage("submit success")
            hideSubmitBtn(selectedCategory)
        }
    }

    function hideSubmitBtn(selectedCategory) {
        // categoriesData[selectedCategory].submit = true

        setCategoriesData(
            categoriesData.map(item => {
                item[selectedCategory].submit = true

                return item
            })
        )
    }

    function getArrayOfData(selectedCategory) {
        let arrayOfData = {
            record: {
                "name": {
                    "first_name": "",
                    "last_name": ""
                },
                "email_addresses": []
            },
            list: {},
            sendCopyOfAnswers: false
        }

        if (selectedCategory === "localization") {
            arrayOfData.list["source_languages"] = []
        } else if (selectedCategory === "lqa") {
            arrayOfData.list["foreign_languages_1"] = []
        }

        for (let fieldKey in categoriesData[0][selectedCategory].fields) {
            let value = categoriesData[0][selectedCategory].fields[fieldKey]
            let attioType = value.attioType

            if (value.type === "input" || value.type === "textarea" || value.type === "num" || value.type === "file") {
                let valueToSet = ""

                if (attioType in filledValues[0].data[selectedCategory] &&
                    fieldKey in filledValues[0].data[selectedCategory][attioType]) {
                    valueToSet = filledValues[0].data[selectedCategory][attioType][fieldKey].value
                }

                if (fieldKey.includes("first_name")) {
                    arrayOfData.record.name["first_name"] = valueToSet
                } else if (fieldKey.includes("last_name")) {
                    arrayOfData.record.name["last_name"] = valueToSet
                } else if (fieldKey.includes("email_addresses")) {
                    if (valueToSet !== "") {
                        arrayOfData.record.email_addresses.push(valueToSet)
                    }
                } else {
                    arrayOfData[attioType][fieldKey] = valueToSet
                }
            } else if (value.type === "select") {
                if (attioType in filledValues[0].data[selectedCategory] &&
                    fieldKey in filledValues[0].data[selectedCategory][attioType]) {
                    let currentFieldFilledData = filledValues[0].data[selectedCategory][attioType][fieldKey]
                    let valueToSet = currentFieldFilledData.value

                    if (selectedCategory === "localization" && fieldKey.indexOf("source_languages") !== -1) {
                        if (fieldKey in arrayOfData[attioType]) {
                            arrayOfData[attioType]["source_languages"].push(valueToSet)
                        } else {
                            arrayOfData[attioType]["source_languages"] = [valueToSet]
                        }
                    } else if (selectedCategory === "lqa" && fieldKey.indexOf("foreign_languages") !== -1) {
                        if (fieldKey in arrayOfData[attioType]) {
                            arrayOfData[attioType]["foreign_languages_1"].push(valueToSet)
                        } else {
                            arrayOfData[attioType]["foreign_languages_1"] = [valueToSet]
                        }
                    } else {
                        arrayOfData[attioType][fieldKey] = [valueToSet]
                    }

                    for (let optionTitle in value.selectOptions) {
                        if (value.selectOptions[optionTitle].attioCode === valueToSet) {
                            if ("attioSlug" in value.selectOptions[optionTitle] &&
                                value.selectOptions[optionTitle].attioSlug in currentFieldFilledData.additionalFields) {
                                arrayOfData[attioType][fieldKey].push(currentFieldFilledData.additionalFields[value.selectOptions[optionTitle].attioSlug])
                            }
                        }
                    }
                } /*else {
                    if (fieldKey.indexOf("source_additional") === -1) {
                        arrayOfData[attioType][fieldKey] = []
                    }
                }*/
            } else if (value.type === "multiselect") {
                if (attioType in filledValues[0].data[selectedCategory] &&
                    fieldKey in filledValues[0].data[selectedCategory][attioType]) {
                    arrayOfData[attioType][fieldKey] = filledValues[0].data[selectedCategory][attioType][fieldKey].value
                } else {
                    arrayOfData[attioType][fieldKey] = []
                }
            } else if (value.type === "checkbox") {
                if (attioType in filledValues[0].data[selectedCategory] &&
                    fieldKey in filledValues[0].data[selectedCategory][attioType]) {
                    if (fieldKey === "send_copy_of_answers") {
                        arrayOfData.sendCopyOfAnswers = filledValues[0].data[selectedCategory][attioType][fieldKey].value
                    } else {
                        arrayOfData[attioType][fieldKey] = filledValues[0].data[selectedCategory][attioType][fieldKey].value
                    }
                } else {
                    if (fieldKey === "send_copy_of_answers") {
                        arrayOfData.sendCopyOfAnswers = false
                    } else {
                        arrayOfData[attioType][fieldKey] = false
                    }
                }
            } else if (value.type === "table") {
                let valueToSet = ""

                if (attioType in filledValues[0].data[selectedCategory] &&
                    fieldKey in filledValues[0].data[selectedCategory][attioType]) {
                    let filledValue = filledValues[0].data[selectedCategory][attioType][fieldKey].value
                    let tableColumnsLayout = categoriesData[0][selectedCategory].fields[fieldKey].tableStyle

                    for (let rowIndex = 0; rowIndex < filledValue.length; rowIndex++) {
                        let rowText = ""

                        for (let colIndex = 0; colIndex < filledValue[rowIndex].length; colIndex++) {
                            rowText += tableColumnsLayout[colIndex].title + ": " + filledValue[rowIndex][colIndex] + ", "
                        }

                        valueToSet += rowText + "\n"
                    }
                }

                arrayOfData[attioType][fieldKey] = valueToSet
            }
        }

        arrayOfData.record.name["full_name"] = arrayOfData.record.name["first_name"] + " " +
            arrayOfData.record.name["last_name"]

        return arrayOfData
    }

    function showAlertMessage(type) {
        let message  = ""

        if (type === "email") {
            message = "Please enter a valid email"
        } else if (type === "required fields") {
            message = "Please fill in required fields"
        } else if (type === "submit success") {
            message = "The application form has been successfully submitted."
        } else if (type === "submit fail") {
            message = "An error occurred during submission."
        } else if (type === "submit waiting") {
            message = "Please wait."
        }

        setModalAlertData(
            modalAlertData.map(info => {
                info.message = message

                return info
            })
        )

        setModalAlertActive(true)
    }

    return (
        <div className="formOuterBlock">
        <div className="formBlockView">
            <div className="row formFieldRow">
                <div className="col-sm-12 center">
                    <h2>Join our team!</h2>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    <Tabs defaultActiveKey="localization">
                        { getCategoryTabs() }
                    </Tabs>
                </div>
            </div>
            <AlertMessageModal
                modalAlertActive={modalAlertActive}
                setModalAlertActive={setModalAlertActive}
                message={modalAlertData[0].message} />
        </div>
        </div>
    )
}