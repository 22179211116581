import React, {useEffect, useState} from "react";
import {Accordion, Alert, Badge, Button, Col, Form, Nav, ProgressBar, Row, Tab, Tabs} from "react-bootstrap";
import TableInfo from "../view/TableInfo";
import Filter from "../view/Filter";
import ClientViewList from "../view/ClientViewList";
import queryString from 'query-string';
import "../css/tableFixHeader.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFilePdf,
    faFilter,
    faUserTie,
    faFileExcel,
    faMagic,
    faLink,
    faCopy,
    faBackward,
    faTimes,
    faKeyboard, faExclamation
} from "@fortawesome/free-solid-svg-icons";
import XLSX from "xlsx";
import SaveInputRegexModal from "./SaveInputRegexModal";
import StyleGuideModal from "./StyleGuideModal";
import AddLinksAndImagesModal from "./AddLinksAndImagesModal";
import OpenInModalAddedImage from "./OpenInModalAddedImage";
import TemplatesFilterModal from "./TemplatesFilterModal";
import OpenInTooltipAddedImage from "./OpenInTooltipAddedImage";
import ModalCellDataHistory from "../ModalCellDataHistory";
import AlertModal from "../AlertModal";
import ClientViewAcceptChangesModal from "./ClientViewAcceptChangesModal";

const styles = {
    blockView: {
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '5px',
        margin: '0px'
    },

    progressBar: {
        margin: '10rem 10rem'
    },

    clientBlockView: {
        backgroundColor: '#2c2c2c',
        color: 'white',
        border: '1px solid #2c2c2c',
        padding: '1rem',
        borderRadius: '5px',
        margin: '0px'
    }
}

export default function ProjectBibleView(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoadedTagData, setIsLoadedTagData] = useState(false);
    const [isLoadedClientViews, setIsLoadedClientViews] = useState(false);
    const [isLoadedTemplatesFilter, setIsLoadedTemplatesFilter] = useState(false);
    const [actionColumn, setActionColumn] = useState(true)
    const [progressPercent, setProgressPercent] = useState(0)
    const [bibleFillPercentage, setBibleFillPercentage] = useState(0)
    const [clientViewManagerName, setClientViewManagerName] = useState("")
    const [saveInputRegexModalActive, setSaveInputRegexModalActive] = useState(false)
    const [saveInputRegexModalData, setSaveInputRegexModalData] = useState([{
        links: [],
        value: '',
        oldValue: '',
        row: '',
        column: ''
    }])
    const [createStyleGuideModalActive, setCreateStyleGuideModalActive] = useState(false)
    const [modalAddLinksAndImagesActive, setModalAddLinksAndImagesActive] = useState(false)
    const [modalOpenAddedImageActive, setModalOpenAddedImageActive] = useState(false)
    const [modalOpenAddedImageSrc, setModalOpenAddedImageSrc] = useState('')
    const [modalAddLinksAndImagesData, setModalAddLinksAndImagesData] = useState([{
        links: [],
        images: [],
        bufferedImages: [],
        value: '',
        saveMethod: '',
        identificationData: []
    }])
    const [modalOpenTemplatesFilterEditActive, setModalOpenTemplatesFilterEditActive] = useState(false)
    const [modalOpenTemplatesFilterEditType, setModalOpenTemplatesFilterEditType] = useState("save")
    const [tooltipImageData, setTooltipImageData] = useState([{
        src: '',
        title: '',
        element: null,
        justUploaded: false
    }])
    const [modalOpenCellDataHistoryActive, setModalOpenCellDataHistoryActive] = useState(false);
    const [modalOpenCellDataHistoryData, setModalOpenCellDataHistoryData] = useState([{
        modalTitle: "",
        tableName: "",
        saveMethod: "",
        identityData: [],
        data: []
    }]);
    const [modalAlertActive, setModalAlertActive] = useState(false);
    const [modalAlertData, setModalAlertData] = useState([{
        message: ""
    }]);

    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const [clientViewData, setClientViewData] = useState([{
        code: 0,
        createdBy: {},
        changesExist: false,
        dateCreate: '',
        timeCreate: '',
        additionalComment: "",
        generalTabColumns: [
            {
                "code": "mh8hjffmev2a7jgbjs1ca3ioqxrxk-0t2qrt",
                // "name": "Project Info Type",
                "editableByClient": false
            },
            {
                "code": "22e0jrtmbwzlhnp1g0ymerm7vilayp2xkump",
                // "name": "Description",
                "editableByClient": false
            },
            {
                "code": "yqjjii-ksp7jkg5vlue1om0beh5-sdhq3i25",
                // "name": "Basic kick-off question",
                "editableByClient": false
            },
            {
                "code": "8pz90mzl0yp-fn9q2chl5lxay8x1jbyqf98h",
                // "name": "Special kick-off questions",
                "editableByClient": false
            },
            {
                "code": "u6ea3ffqrpg054v-1erslv5r-1j8zo288jby",
                // "name": "Info",
                "editableByClient": true
            }
        ],
        generalTabRowsList: [],
        generalTabRows: [],
        languageTabData: [],
        languageTabListOfCodes: [],
        languageTabLinguistLinksData: []
    }])
    const [clientViewAcceptDataModalActive, setClientViewAcceptDataModalActive] = useState(false)

    const [columns, setColumns] = useState([{
        data: []
    }])

    const [rows, setRows] = useState([{
        data: []
    }])

    const [clientViewsList, setClientViewsList] = useState([{
        data: []
    }])

    const [templatesFilter, setTemplatesFilter] = useState([{
        isOn: {
            value: false,
            type: '',
            id: 0,
            filter: ''
        },
        filters: {
            default: [],
            project: []
        }
    }])

    const [styleGuideExists, setStyleGuideExists] = useState(false)
    const [styleGuideLanguages, setStyleGuideLanguages] = useState([{
        data: []
    }])

    let cellAllCount = 0
    let cellOnCount = 0
    const authorizationCookieName = "b24_authorization_log"
    const authorizationCookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + authorizationCookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    const employeeId = authorizationCookieMatches ?
        +JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).userId :
        window.location.href.split("/")[2] === "localhost:3000" ? 191 : 0
    const employeeName = authorizationCookieMatches ?
        JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).name :
        window.location.href.split("/")[2] === "localhost:3000" ? "Daria Arisova" : ""

    useEffect(async () => {
        document.title = projectName + " (PB)";

        if (/*await lookIp()*/checkCookie()) {
            deleteRedirectCookie()

            //проверяем создавали ли ранее этот отчет
            await fetch("/proxy/project_bible_template/projectBibleInfo", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode
                }),
            })
                .then(res => res.json())
                .then(
                    async (result) => {
                        await fetch("/proxy/project_bible_template/styleGuideGetLanguagesList", {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        })
                            .then(res => res.json())
                            .then(
                                async (resultStyleGuideLanguages) => {
                                    await fetch("/proxy/project_bible_template/projectBibleTemplateRowsColumns", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (resultTemplate) => {
                                                setStyleGuideLanguages(
                                                    styleGuideLanguages.map(value => {
                                                        for (let i = 0; i < resultStyleGuideLanguages.languages.length; i++) {
                                                            value.data.push({
                                                                title: resultStyleGuideLanguages.languages[i].language,
                                                                code: resultStyleGuideLanguages.languages[i].language_code,
                                                                value: resultStyleGuideLanguages.languages[i].default_check
                                                            })
                                                        }

                                                        return value
                                                    })
                                                )

                                                //этот отчет уже создавали
                                                if (result.length) {
                                                    await fetch("/proxy/project_bible_template/projectBibleStyleGuideExists", {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            "clientName": clientName,
                                                            "projectName": projectName,
                                                            "projectCode": projectCode
                                                        }),
                                                    })
                                                        .then(res => res.json())
                                                        .then(
                                                            async (resultStyleGuide) => {
                                                                let columnInfoToFilterDeletedRowsInTemplate = ""
                                                                let rowsMap = new Map()
                                                                let templateRowsMap = new Map()

                                                                if (resultStyleGuide.count > 0) {
                                                                    setStyleGuideExists(true)
                                                                }

                                                                console.log("we get data", result[0], resultTemplate,
                                                                    "cells count: ", result[0].columns.length,
                                                                    result[0].rows.length)

                                                                // cellAllCount = result[0].columns.length * result[0].rows.length

                                                                result[0].columns.map(value => {
                                                                    let columnName = value.name

                                                                    if (value.name === "Info") {
                                                                        columnInfoToFilterDeletedRowsInTemplate = value.code
                                                                    }

                                                                    resultTemplate.columns.map(templateColumnsValue => {
                                                                        if (templateColumnsValue.code === value.code) {
                                                                            columnName = templateColumnsValue.name
                                                                        }

                                                                        return templateColumnsValue
                                                                    })

                                                                    setColumns(
                                                                        columns.map(info => {
                                                                            info.data.push({
                                                                                "code": value.code,
                                                                                "name": /*value.name*/columnName,
                                                                                "type": value.type,
                                                                                "editable": value.editable,
                                                                                "template": value.template,
                                                                                "filter": {
                                                                                    "show": true,
                                                                                    "showAll": {
                                                                                        "value": true,
                                                                                        "showFilledCells": true,
                                                                                        "showEmptyCells": true,
                                                                                        "data": new Map(),
                                                                                        "selectedData": []
                                                                                    }
                                                                                }
                                                                            });

                                                                            return info
                                                                        })
                                                                    )

                                                                    return value
                                                                })

                                                                resultTemplate.rows.map(value => {
                                                                    templateRowsMap.set(value.code, value.code)
                                                                })

                                                                result[0].rows.map(value => {
                                                                    rowsMap.set(value.code, value.code)
                                                                })

                                                                templateRowsMap.forEach((value, key) => {
                                                                    if (!rowsMap.has(key)) {
                                                                        result[0].rows.push({
                                                                            code: key
                                                                        })
                                                                    }
                                                                });

                                                                console.log("cells count: ", result[0].columns.length,
                                                                    result[0].rows.length)

                                                                cellAllCount = result[0].columns.length * result[0].rows.length

                                                                result[0].rows.map(value => {
                                                                    setRows(
                                                                        rows.map(info => {
                                                                            info.data.push({
                                                                                "code": value.code,
                                                                                "show": true,
                                                                                "data": [],
                                                                                "template": [],
                                                                                "deletedRow": templateRowsMap.has(value.code) ? false : true,
                                                                                "showDeletedRow": true,
                                                                                "columnToFilterDeletedRows": columnInfoToFilterDeletedRowsInTemplate
                                                                            });

                                                                            return info
                                                                        })
                                                                    )

                                                                    return value
                                                                })

                                                                console.log("result rows", rows)

                                                                await fillDataForHeaders()
                                                                await fillTagData()
                                                                await fillDataForOldClientViewsList()
                                                                await fillDataForClientViewsList()
                                                                await fillTemplatesFilter()
                                                                calculateBibleFillPercentage()
                                                            },
                                                            (error) => {
                                                                //todo придумать какой-то текст ошибки
                                                            }
                                                        )
                                                } else {
                                                    console.log("projectBibleTemplateRowsColumns result", resultTemplate.columns, resultTemplate.rows)

                                                    await fetch("/proxy/project_bible_template/projectBibleInfoInsert", {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            "clientName": clientName,
                                                            "projectName": projectName,
                                                            "projectCode": projectCode,
                                                            "columns": resultTemplate.columns,
                                                            "rows": resultTemplate.rows
                                                        }),
                                                    })
                                                        .then(res => res.json())
                                                        .then(
                                                            async (resultInsert) => {
                                                                cellAllCount = resultTemplate.columns.length * resultTemplate.rows.length

                                                                resultTemplate.columns.map(value => {
                                                                    setColumns(
                                                                        columns.map(info => {
                                                                            info.data.push({
                                                                                "code": value.code,
                                                                                "name": value.name,
                                                                                "type": value.type,
                                                                                "editable": value.editable,
                                                                                "template": value.template,
                                                                                "filter": {
                                                                                    "show": true,
                                                                                    "showAll": {
                                                                                        "value": true,
                                                                                        "showFilledCells": true,
                                                                                        "showEmptyCells": true,
                                                                                        "data": new Map(),
                                                                                        "selectedData": []
                                                                                    }
                                                                                }
                                                                            });

                                                                            return info
                                                                        })
                                                                    )

                                                                    return value
                                                                })

                                                                resultTemplate.rows.map(value => {
                                                                    setRows(
                                                                        rows.map(info => {
                                                                            info.data.push({
                                                                                "code": value.code,
                                                                                "show": true,
                                                                                "data": [],
                                                                                "template": []
                                                                            });

                                                                            return info
                                                                        })
                                                                    )

                                                                    return value
                                                                })

                                                                await fillDataForHeaders()
                                                                await fillTagData()
                                                                await fillTemplatesFilter()
                                                                calculateBibleFillPercentage()
                                                                setIsLoadedClientViews(true)
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                }
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError({
                                                    message: "Не удалось получить данные о шаблоне Project Bible. Пожалуйста перезагрузите страницу, чтобы запустить процесс снова"
                                                });
                                            }
                                        )
                                },
                                (error) => {
                                    setIsLoaded(true);
                                    setError({
                                        message: "Не удалось получить данные о языках стайлгайда. Пожалуйста перезагрузите страницу, чтобы запустить процесс снова"
                                    });
                                }
                            )
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        } else {
            // let link = window.location.href.split("/")
            // window.location.replace('http://' + link[2] + '/autorization')
            setRedirectCookie()
            window.location.replace('https://b24.allcorrectgames.com');
        }
    }, [])

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(window.location.href)
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function checkCookie() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        } else {
            return employeeId > 0
        }
    }

    async function fillDataForHeaders() {
        // rows.map(async rowsData => {
        await fetch('/proxy/project_bible_template/getProjectBibleAllTextTemplateValues', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                async (resultTemplateInputValues) => {
                    await fetch('/proxy/project_bible_template/getProjectBibleAllBoolTemplateValues', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(res => res.json())
                        .then(
                            async (resultTemplateCheckboxValues) => {
                                await fetch('/proxy/project_bible_template/getProjectBibleAllTagsTemplateValues', {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (resultTemplateTagsValues) => {
                                            await fetch('/proxy/project_bible_template/getProjectBibleAllTextCellValues', {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    "clientName": clientName,
                                                    "projectName": projectName,
                                                    "projectCode": projectCode
                                                })
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    async (resultEditedInputCellsValue) => {
                                                        await fetch('/proxy/project_bible_template/getProjectBibleAllBoolCellValues', {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                                "clientName": clientName,
                                                                "projectName": projectName,
                                                                "projectCode": projectCode
                                                            })
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                async (resultEditedCheckboxCellsValue) => {
                                                                    fillDataForCells(resultTemplateInputValues,
                                                                        resultTemplateCheckboxValues,
                                                                        resultTemplateTagsValues,
                                                                        resultEditedInputCellsValue,
                                                                        resultEditedCheckboxCellsValue)
                                                                },
                                                                (error) => {
                                                                    setIsLoaded(true);
                                                                    setError(error);
                                                                }
                                                            )
                                                    },
                                                    (error) => {
                                                        setIsLoaded(true);
                                                        setError(error);
                                                    }
                                                )
                                        },
                                        (error) => {
                                            setIsLoaded(true);
                                            setError(error);
                                        }
                                    )
                            },
                            (error) => {
                                setIsLoaded(true);
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    //     return rowsData
    // })
    }

    function fillDataForCells(resultTemplateInputValues, resultTemplateCheckboxValues, resultTemplateTagsValues, resultEditedInputCellsValue, resultEditedCheckboxCellsValue) {
        let templateValuesInput = []
        let templateValuesCheckbox = []
        let templateValuesTags = []
        let editedValuesInput = []
        let editedValuesBool = []

        for (let i = 0; i < resultTemplateInputValues.length; i++) {
            let row = resultTemplateInputValues[i]["row_code"]
            let column = resultTemplateInputValues[i]["col_code"]
            let value = resultTemplateInputValues[i]["value"]

            if (!(row in templateValuesInput)) {
                templateValuesInput[row] = []
            }

            templateValuesInput[row][column] = value
        }

        for (let i = 0; i < resultTemplateCheckboxValues.length; i++) {
            let row = resultTemplateCheckboxValues[i]["row_code"]
            let column = resultTemplateCheckboxValues[i]["col_code"]
            let value = resultTemplateCheckboxValues[i]["value"]

            if (!(row in templateValuesCheckbox)) {
                templateValuesCheckbox[row] = []
            }

            templateValuesCheckbox[row][column] = value
        }

        for (let i = 0; i < resultTemplateTagsValues.length; i++) {
            let row = resultTemplateTagsValues[i]["row_code"]
            let column = resultTemplateTagsValues[i]["col_code"]
            let value = resultTemplateTagsValues[i]["value"]

            if (!(row in templateValuesTags)) {
                templateValuesTags[row] = []
            }

            templateValuesTags[row][column] = value
        }

        for (let i = 0; i < resultEditedInputCellsValue.length; i++) {
            let row = resultEditedInputCellsValue[i]["row_code"]
            let column = resultEditedInputCellsValue[i]["col_code"]
            let value = resultEditedInputCellsValue[i]["value"]

            if (!(row in editedValuesInput)) {
                editedValuesInput[row] = []
            }

            editedValuesInput[row][column] = value
        }

        for (let i = 0; i < resultEditedCheckboxCellsValue.length; i++) {
            let row = resultEditedCheckboxCellsValue[i]["row_code"]
            let column = resultEditedCheckboxCellsValue[i]["col_code"]
            let value = resultEditedCheckboxCellsValue[i]["value"]

            if (!(row in editedValuesBool)) {
                editedValuesBool[row] = []
            }

            editedValuesBool[row][column] = value
        }

        console.log("fillDataForCells", templateValuesInput, templateValuesCheckbox, templateValuesTags,
            editedValuesInput, editedValuesBool)

        setRows(
            rows.map(rowsData => {
                rowsData.data.map(row => {
                    columns.map(columnsData => {
                        columnsData.data.map(column => {
                            let columnValueExists = false

                            if (column.type === "input") {
                                if (row.code in editedValuesInput && column.code in editedValuesInput[row.code]) {
                                    row.data[column.code] = editedValuesInput[row.code][column.code]
                                } else if (row.code in templateValuesInput && column.code in templateValuesInput[row.code]) {
                                    row.data[column.code] = templateValuesInput[row.code][column.code]
                                } else {
                                    row.data[column.code] = ""
                                }

                                if (row.data[column.code]) {
                                    let textValue = row.data[column.code]
                                    let valueWithoutSpaces = ""

                                    // textValue = textValue.replace(/[\n\r]+/g, '');
                                    // textValue = textValue.replace(/\s{1,10}/g, '');

                                    valueWithoutSpaces = textValue.trim()
                                        .replace(/^\s+/, '')
                                        .replace(/\s+$/, '')
                                        .replace(/[\n\r]+/g, '')
                                        .replace(/\s{2,}/g, '')
                                        .replace(/^\s+|\s+$/, '')
                                        .replace(/&lt;br&gt;/g, '')
                                        .replace(/\<br\>/g, '');

                                    if (valueWithoutSpaces !== "") {
                                        setDataForSelectInColumn(column.code, row.data[column.code])
                                        columnValueExists = true
                                    }
                                }
                            } else if (column.type === "checkbox") {
                                if (row.code in editedValuesBool && column.code in editedValuesBool[row.code]) {
                                    row.data[column.code] = editedValuesBool[row.code][column.code]

                                    if (row.code in templateValuesCheckbox && column.code in templateValuesCheckbox[row.code]) {
                                        row.template[column.code] = templateValuesCheckbox[row.code][column.code]
                                    } else {
                                        row.template[column.code] = false
                                    }
                                } else if (row.code in templateValuesCheckbox && column.code in templateValuesCheckbox[row.code]) {
                                    row.data[column.code] = templateValuesCheckbox[row.code][column.code]
                                    row.template[column.code] = templateValuesCheckbox[row.code][column.code]
                                } else {
                                    row.data[column.code] = false
                                    row.template[column.code] = false
                                }

                                /*if (row.data[column.code]) {
                                    setDataForSelectInColumn(column.code, row.data[column.code])
                                    columnValueExists = true
                                }*/
                            } else if (column.type === "tags_list") {
                                /*if (row.code in templateValuesTags && column.code in templateValuesTags[row.code]) {
                                    row.data[column.code] = templateValuesTags[row.code][column.code]
                                }

                                if (row.data[column.code]) {
                                    columnValueExists = true
                                }*/

                                if (row.code in templateValuesTags && column.code in templateValuesTags[row.code]) {
                                    row.data[column.code] = templateValuesTags[row.code][column.code]

                                    /*if (row.data[column.code]) {
                                        columnValueExists = true
                                    }*/
                                } else {
                                    row.data[column.code] = []
                                }
                            }

                            if ("deletedRow" in row && row.deletedRow && column.code === row.columnToFilterDeletedRows) {
                                if (!columnValueExists) {
                                    row.showDeletedRow = false
                                }
                            }

                            cellOnCount++
                            console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                            setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                            if (cellOnCount === cellAllCount && !error) {
                                setIsLoaded(true);
                            }

                            return column
                        })

                        return columnsData
                    })

                    return row
                })

                return rowsData
            })
        )
    }

    function calculateBibleFillPercentage() {
        let displayedRowsCount = 0
        let filledRowsCount = 0

        rows.map(rowsData => {
            rowsData.data.map(row => {
                if (row.show) {
                    let isInfoFilled = false
                    let infoColCode = "u6ea3ffqrpg054v-1erslv5r-1j8zo288jby"
                    let isRowHasCheckedValues = false

                    displayedRowsCount++

                    columns.map(columnsData => {
                        columnsData.data.map(column => {
                            if (column.type === "input") {
                                if (column.code === "mh8hjffmev2a7jgbjs1ca3ioqxrxk-0t2qrt") {
                                    console.log("Project Info Type", row.data[column.code])
                                }

                                if (column.code === infoColCode) {
                                    let valueWithoutSpaces = ""

                                    valueWithoutSpaces = row.data[column.code].trim()
                                        .replace(/^\s+/, '')
                                        .replace(/\s+$/, '')
                                        .replace(/[\n\r]+/g, '')
                                        .replace(/\s{2,}/g, '')
                                        .replace(/^\s+|\s+$/, '')
                                        .replace(/&lt;br&gt;/g, '')
                                        .replace(/\<br\>/g, '');

                                    if (valueWithoutSpaces !== "") {
                                        isInfoFilled = true
                                    }
                                }
                            } else if (column.type === "checkbox") {
                                if (!isRowHasCheckedValues) {
                                    if (column.code in row.template) {
                                        isRowHasCheckedValues = row.template[column.code]
                                    }

                                    if (column.code in row.data) {
                                        isRowHasCheckedValues = row.data[column.code]
                                    }
                                }
                            }

                            return column
                        })

                        return columnsData
                    })

                    if (isRowHasCheckedValues && isInfoFilled) {
                        filledRowsCount++
                    }
                }

                return row
            })

            return rowsData
        })

        console.log("calculate bible fill percentage", filledRowsCount, displayedRowsCount, filledRowsCount * 100 / displayedRowsCount)

        setBibleFillPercentage(Math.round(filledRowsCount * 100 / displayedRowsCount))
    }

    /*function fillDataForHeaders() {
        setRows(rows.map(rowsData => {
            rowsData.data.map(async row => {
                columns.map(columnsData => {
                    columnsData.data.map(async column => {
                        let queryLinkTemplate = '/proxy/project_bible_template/'
                        let queryLinkEditable = '/proxy/project_bible_template/'

                        if (column.type === "input") {
                            // queryLinkTemplate += 'projectBibleTemplateTextByName'
                            queryLinkTemplate += 'projectBibleTemplateTextByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellTextByName'
                        } else if (column.type === "checkbox") {
                            // queryLinkTemplate += 'projectBibleTemplateBoolByName'
                            queryLinkTemplate += 'projectBibleTemplateBoolByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellBoolByName'
                        } else if (column.type === "tags_list") {
                            queryLinkTemplate += 'projectBibleTemplateTagJsonByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellBoolByName'
                        }

                        if (column.template && column.editable) {
                            await fetch(queryLinkTemplate, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultTemplate) => {
                                        await fetch(queryLinkEditable, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                "clientName": clientName,
                                                "projectName": projectName,
                                                "projectCode": projectCode,
                                                "rowCode": row.code,
                                                "colCode": column.code,
                                            })
                                        })
                                            .then(res => res.json())
                                            .then(
                                                async (resultEditable) => {
                                                    // console.log("columns[i].template columns[i].editable", resultTemplate, resultEditable)
                                                    let columnValueExists = false

                                                    if (resultEditable.length) {
                                                        row.data[column.code] = resultEditable[0].value

                                                        if (resultEditable[0].value) {
                                                            columnValueExists = true
                                                        }

                                                        if (column.type === "checkbox") {
                                                            if (resultTemplate.length) {
                                                                console.log("тут должен быть шаблон и он есть", resultTemplate)
                                                                row.template[column.code] = resultTemplate[0].value
                                                            } else {
                                                                console.log("тут должен быть шаблон", resultTemplate)
                                                                row.template[column.code] = false
                                                            }
                                                        }
                                                        /!*if (column.type === "input") {
                                                            row.data[column.code] = resultEditable[0].value
                                                        } else if (column.type === "checkbox") {
                                                            console.log("resultTemplate", row.code, column.code, resultTemplate)

                                                            row.data[column.code] = {
                                                                edit: resultEditable[0].value,
                                                                template: resultTemplate[0].value
                                                            }
                                                        }*!/

                                                        if (column.type !== "tags_list") {
                                                            if (resultEditable[0].value) {
                                                                setDataForSelectInColumn(column.code, resultEditable[0].value)
                                                            }
                                                        }
                                                    } else {
                                                        if (resultTemplate.length) {
                                                            row.data[column.code] = resultTemplate[0].value

                                                            if (resultTemplate[0].value) {
                                                                columnValueExists = true
                                                            }

                                                            if (column.type === "checkbox") {
                                                                row.template[column.code] = resultTemplate[0].value
                                                            }

                                                            if (column.type !== "tags_list") {
                                                                if (resultTemplate[0].value) {
                                                                    setDataForSelectInColumn(column.code, resultTemplate[0].value)
                                                                }
                                                            }
                                                        } else if (column.type === "input") {
                                                            row.data[column.code] = ""
                                                        } else if (column.type === "checkbox") {
                                                            row.data[column.code] = false
                                                            row.template[column.code] = false
                                                            /!*row.data[column.code] = {
                                                                edit: false,
                                                                template: false
                                                            }*!/
                                                        }
                                                    }

                                                    if ("deletedRow" in row && row.deletedRow && column.code === row.columnToFilterDeletedRows) {
                                                        if (!columnValueExists) {
                                                            row.showDeletedRow = false
                                                        }
                                                    }

                                                    cellOnCount++
                                                    console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                                    setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                                    if (cellOnCount === cellAllCount && !error) {
                                                        setIsLoaded(true);
                                                    }
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        } else if (column.template) {
                            await fetch(queryLinkTemplate, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {
                                        let columnValueExists = false
                                        // console.log("columns[i].template", result)

                                        // row.data.push(result)
                                        // row.data[column.code] = result

                                        if (result.length) {
                                            row.data[column.code] = result[0].value

                                            if (result[0].value) {
                                                columnValueExists = true
                                            }

                                            if (column.type !== "tags_list") {
                                                if (result[0].value) {
                                                    setDataForSelectInColumn(column.code, result[0].value)
                                                }
                                            }
                                        } else if (column.type === "input") {
                                            row.data[column.code] = ""
                                        } else if (column.type === "checkbox") {
                                            row.data[column.code] = false
                                        } else if (column.type === "tags_list") {
                                            row.data[column.code] = []
                                        }

                                        if ("deletedRow" in row && row.deletedRow && column.code === row.columnToFilterDeletedRows) {
                                            if (!columnValueExists) {
                                                row.showDeletedRow = false
                                            }
                                        }

                                        cellOnCount++
                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                        if (cellOnCount === cellAllCount && !error) {
                                            setIsLoaded(true);
                                        }
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        } else if (column.editable) {
                            await fetch(queryLinkEditable, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {
                                        let columnValueExists = false
                                        // console.log("columns[i].editable", result)

                                        if (result.length) {
                                            // row.data.push(result[0].value)
                                            row.data[column.code] = result[0].value

                                            if (result[0].value) {
                                                columnValueExists = true
                                            }

                                            if (column.type !== "tags_list") {
                                                if (result[0].value) {
                                                    setDataForSelectInColumn(column.code, result[0].value)
                                                }
                                            }
                                        } else {
                                            if (column.type === "input") {
                                                // row.data.push("")
                                                row.data[column.code] = ""
                                            } else if (column.type === "checkbox") {
                                                // row.data.push(false)
                                                row.data[column.code] = false
                                            } else if (column.type === "tags_list") {
                                                row.data[column.code] = []
                                            }
                                        }

                                        if ("deletedRow" in row && row.deletedRow && column.code === row.columnToFilterDeletedRows) {
                                            if (!columnValueExists) {
                                                row.showDeletedRow = false
                                            }
                                        }

                                        cellOnCount++
                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                        if (cellOnCount === cellAllCount && !error) {
                                            setIsLoaded(true);
                                        }
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        }
                    })

                    return columnsData
                })
            })

            return rowsData
        }))
    }*/

    async function fillTagData() {
        const response = await fetch('/proxy/project_bible_tags', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        let result = await response.json();

        console.log("result tags", result)

        if ('response' in result) {
            setColumns(
                columns.map(info => {
                    info.data.map(value => {
                        console.log("value.type", value.type)

                        if (value.type === "tags_list") {
                            let tags = new Map()

                            let tagGroupsArray = result.response.TechProcess.sort(function(a, b) {
                                return decodeURIComponent(a['Group_tags']) - decodeURIComponent(b['Group_tags']);
                            });

                            for (let i = 0; i < tagGroupsArray.length; i++) {
                                let value = []
                                let tagsArray = tagGroupsArray[i]['Tags'].sort(function(a, b) {
                                    return decodeURIComponent(a.tag) - decodeURIComponent(b.tag);
                                });

                                for (let j = 0; j < tagsArray.length; j++) {
                                    value.push(decodeURIComponent(tagsArray[j].tag))
                                }

                                tags.set(decodeURIComponent(tagGroupsArray[i]['Group_tags']), value)
                            }

                            value.filter.showAll.data = tags
                        }

                        return value
                    })

                    setIsLoadedTagData(true)

                    return info
                })
            )
        } else {
            setIsLoaded(true)
            setIsLoadedTagData(true)
            setError({
                message: "Не удалось загрузить данные. Пожалуйста перезагрузите страницу."
            })
        }
    }

    async function moveUpRow(code, num) {
        if (num !== 1) {
            let value = rows[0].data[num - 1]
            let rowsForUpdate = []

            setRows(
                rows.map(info => {
                    info.data.splice(num - 1, 1)
                    info.data.splice(num - 2, 0, value)

                    info.data.map(value => {
                        rowsForUpdate.push({
                            code: value.code
                        })
                    })

                    return info
                })
            )

            await fetch("/proxy/project_bible_template/projectBibleInfoMoveUpRow", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode,
                    "rows": rowsForUpdate
                })
            })
                .then(res => res.json())
                .then(
                    (resultMoveUp) => {

                    },
                    (error) => {
                        //todo придумать какой-то текст ошибки
                    }
                )
        }
    }

    async function moveDownRow(code, num) {
        let rowsLength = rows[0].data.length

        if (num !== rowsLength) {
            let value = rows[0].data[num - 1]
            let rowsForUpdate = []

            setRows(
                rows.map(info => {
                    info.data.splice(num - 1, 1)
                    info.data.splice(num, 0, value)

                    info.data.map(value => {
                        rowsForUpdate.push({
                            code: value.code
                        })
                    })

                    return info
                })
            )

            await fetch("/proxy/project_bible_template/projectBibleInfoMoveDownRow", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode,
                    "rows": rowsForUpdate
                })
            })
                .then(res => res.json())
                .then(
                    (resultMoveDown) => {

                    },
                    (error) => {
                        //todo придумать какой-то текст ошибки
                    }
                )
        }
    }

    function changeFilter(code, type, field, e) {
        if (field === "show") {
            setColumns(
                columns.map(info => {
                    info.data.map(value => {
                        if (value.code === code) {
                            value.filter.show = e.target.checked
                        }

                        return value
                    })

                    return info
                })
            )
        } else if (field === "showAll") {
            setColumns(
                columns.map(info => {
                    info.data.map(value => {
                        if (value.code === code) {
                            value.filter.showAll.value = e.target.checked
                        }

                        return value
                    })

                    return info
                })
            )
        } else if (field === "showEmptyCells") {
            setColumns(
                columns.map(info => {
                    info.data.map(value => {
                        if (value.code === code) {
                            value.filter.showAll.showEmptyCells = e.target.checked
                        }

                        return value
                    })

                    return info
                })
            )
        } else if (field === "showFilledCells") {
            setColumns(
                columns.map(info => {
                    info.data.map(value => {
                        if (value.code === code) {
                            value.filter.showAll.showFilledCells = e.target.checked
                        }

                        return value
                    })

                    return info
                })
            )
        }
    }

    function setDataForSelectInColumn(code, dataField) {
        setColumns(
            columns.map(info => {
                info.data.map(value => {
                    if (value.code === code) {
                        if (!value.filter.showAll.data.has(dataField)) {
                            value.filter.showAll.data.set(dataField, dataField)
                        }
                    }

                    return value
                })

                return info
            })
        )
    }

    function setSelectedDataForColumnInMultiselect(code, type, selectedItems) {
        console.log("Multiselect", type, selectedItems)

        setColumns(
            columns.map(info => {
                info.data.map(value => {
                    if (value.code === code) {
                        value.filter.showAll.selectedData = selectedItems
                    }

                    return value
                })

                return info
            })
        )
    }

    function filterTable() {
        setActionColumn(false)
        setAllRowsShow()

        columns.map(info => {
            info.data.map((column, index) => {
                if (column.filter.show) {
                    if (!column.filter.showAll.value) {
                        if (column.type === "input") {
                            filterFindInputRowsToRemove(column.code, column.filter.showAll.selectedData, column.filter.showAll.showEmptyCells)
                        } else if (column.type === "checkbox") {
                            filterFindCheckboxRowsToRemove(column.code, column.filter.showAll.showFilledCells)
                        } else if (column.type === "tags_list") {
                            //todo

                            console.log("REMOVE METHOD", column)
                            filterFindTagRowsToRemove(column.code, column.filter.showAll.selectedData, column.filter.showAll.showEmptyCells)
                        }
                    }
                }

                return column
            })

            return info
        })

        calculateBibleFillPercentage()
    }

    function setAllRowsShow() {
        setRows(
            rows.map(info => {
                info.data.map(row => {
                    row.show = true

                    return row
                })

                return info
            })
        )
    }

    function filterFindInputRowsToRemove(code, selectedData, showEmptyCells) {
        setRows(
            rows.map(info => {
                info.data.map(row => {
                    let removeValue = true;

                    selectedData.map(selectedDataValue => {
                        if (row.data[code] === selectedDataValue) {
                            removeValue = false;
                        }
                    })

                    if (showEmptyCells) {
                        if (row.data[code] === "") {
                            //todo наверное стоит сделать удаление всех пробелов в пустой строке, только делать это в
                            // момент сохранения значения ячейки
                            removeValue = false;
                        }
                    }

                    if (removeValue) {
                        row.show = false
                    }

                    return row
                })

                return info
            })
        )
    }

    function filterFindCheckboxRowsToRemove(code, showFilledCells) {
        // console.log("filterFindCheckboxRowsToRemove", rows)

        setRows(
            rows.map(info => {
                info.data.map(row => {
                    if (row.data[code] !== showFilledCells) {
                        row.show = false
                    }

                    return row
                })

                return info
            })
        )
    }

    function filterFindTagRowsToRemove(code, selectedData, showEmptyCells) {
        let associativeSelectedData = {}

        for (let i = 0; i < selectedData.length; i++) {
            if (selectedData[i].group in associativeSelectedData) {
                associativeSelectedData[selectedData[i].group].push(selectedData[i].tag)
            } else {
                associativeSelectedData[selectedData[i].group] = [selectedData[i].tag]
            }
        }

        setRows(
            rows.map(info => {
                info.data.map(row => {
                    // let removeValue = true
                    let removeValue = compareFilterTags(associativeSelectedData, row.data[code])

                    // console.log("row.data[code]", associativeSelectedData, row.data[code], Object.keys(row.data[code]).length)

                    if (showEmptyCells) {
                        console.log("REMOVE", row, row.data, code)

                        if (Object.keys(row.data[code]).length === 0) {
                            removeValue = false;
                        }
                    }

                    if (removeValue) {
                        row.show = false
                    }

                    return row
                })

                return info
            })
        )
    }

    function compareFilterTags(filterData, rowData) {
        let remove = true

        for (let rowDataKey in rowData) {
            if (rowDataKey in filterData) {
                let rowDataValue = rowData[rowDataKey]

                for (let i = 0; i < rowDataValue.length; i++) {
                    if (filterData[rowDataKey].includes(rowDataValue[i])) {
                        remove = false
                    }
                }
            }
        }

        return remove
    }

    function resetFilter() {
        setActionColumn(true)

        setColumns(
            columns.map(info => {
                info.data.map(column => {
                    column.filter.show = true
                    column.filter.showAll.value = true
                    column.filter.showAll.showFilledCells = true
                    column.filter.showAll.showEmptyCells = true
                    column.filter.showAll.selectedData = []

                    return column
                })

                return info
            })
        )

        setRows(
            rows.map(info => {
                info.data.map(row => {
                    row.show = true

                    return row
                })

                return info
            })
        )

        setTemplatesFilter(
            templatesFilter.map(info => {
                info.isOn.value = false

                return info
            })
        )
    }

    async function createClientView() {
        let createdBy = {
            id: employeeId,
            name: employeeName
        }

        await fetch("/proxy/project_bible_template/createPBClientView", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "createdBy": createdBy
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setClientViewData(
                        clientViewData.map(info => {
                            info.code = result.code
                            info.dateCreate = result["date_create"].substring(0, 10)
                            info.timeCreate = result["time_create"].substring(0, 8)
                            info.createdBy = createdBy
                            info.changesExist = false

                            return info
                        })
                    )
                },
                (error) => {
                    alert("An error occurred while creating the client page. Please try again or refresh the page.")
                }
            )
    }

    async function fillDataForClientViewsList() {
        await fetch("/proxy/project_bible_template/getPBClientView", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.length) {
                        setClientViewData(
                            clientViewData.map(info => {
                                info.code = result[0].code
                                info.dateCreate = result[0]["date_create"].substring(0, 10)
                                info.timeCreate = result[0]["time_create"].substring(0, 8)
                                info.createdBy = result[0]["created_by"]
                                info.changesExist = result[0]["changes_exist"]

                                return info
                            })
                        )
                    }
                },
                (error) => {
                    setError(error)
                }
            )
    }

    async function fillTemplatesFilter() {
        await fetch("/proxy/project_bible_template/getProjectBibleFilters", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            }),
        })
            .then(res => res.json())
            .then(
                (resultTemplatesFilter) => {
                    console.log("resultTemplatesFilter", resultTemplatesFilter)

                    setTemplatesFilter(
                        templatesFilter.map(info => {
                            resultTemplatesFilter.defaultFilters.map(filter => {
                                info.filters.default.push(filter)

                                return filter
                            })

                            if (resultTemplatesFilter.projectFilters.length) {
                                resultTemplatesFilter.projectFilters.map(filter => {
                                    info.filters.project.push(filter)

                                    return filter
                                })
                            }

                            return info
                        })
                    )

                    setIsLoadedTemplatesFilter(true)
                },
                (error) => {
                    setIsLoadedTemplatesFilter(false)
                    setError(error)
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function deleteClientView(code, index) {
        await fetch("/proxy/project_bible_template/projectBibleClientViewDelete", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": code
            }),
        })
            .then(res => res.json())
            .then(
                (resultDelete) => {
                    setClientViewsList(
                        clientViewsList.map(info => {
                            info.data.splice(index, 1)

                            return info
                        })
                    )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function returnClientViewToWork(code) {
        await fetch("/proxy/project_bible_template/projectBibleClientViewSubmit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": code,
                "value": false
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setClientViewsList(
                        clientViewsList.map(info => {
                            info.data.map(viewData => {
                                if (viewData.code === code) {
                                    viewData.submit = false
                                }

                                return viewData
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function resetFormUpdateValue(code) {
        await fetch("/proxy/project_bible_template/projectBibleClientViewSetUpdateValue", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": code,
                "value": false
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setClientViewsList(
                        clientViewsList.map(info => {
                            info.data.map(viewData => {
                                if (viewData.code === code) {
                                    viewData.isFormUpdate = false
                                }

                                return viewData
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    function createExcel() {
        let wb = XLSX.utils.book_new()
        let dataForTable = excelData()
        let ws_data = dataForTable.data
        let ws = XLSX.utils.aoa_to_sheet(ws_data)

        ws['!cols'] = dataForTable.colProperties

        wb.Props = {
            Title: "PB",
            Subject: "PB",
            Author: "Allcorrect"
        }

        wb.SheetNames.push("PB")
        wb.Sheets["PB"] = ws
        XLSX.writeFile(wb, clientName + '_' + projectName + '_' + getCurrentDate() + '_PB.xlsx')
    }

    function excelData() {
        let data = []
        let colProperties = []
        let rowCount = 1

        columns.map(info => {
            let headers = []

            headers.push("№")
            colProperties.push({
                width: 10
            })

            info.data.map(column => {
                if (column.filter.show) {
                    headers.push(column.name)

                    if (column.type === "input") {
                        if (column.editable) {
                            colProperties.push({
                                width: 50
                            })
                        } else {
                            colProperties.push({
                                width: 25
                            })
                        }
                    } else if (column.type === "checkbox") {
                        colProperties.push({
                            width: 15
                        })
                    }
                }

                return column
            })

            data.push(headers)

            return info
        })

        rows.map(rowInfo => {
            rowInfo.data.map((row, index) => {
                if (row.show) {
                    let value = []

                    value.push(rowCount++)

                    columns.map(columnInfo => {
                        columnInfo.data.map(column => {
                            if (column.filter.show) {
                                if (column.type === "checkbox") {
                                    value.push(row.data[column.code] ? "🟢" : "❌")
                                } else if (column.type === "input") {
                                    if (column.editable) {
                                        let newCellText = row.data[column.code]

                                        if (index < 3) {
                                            console.log("newCellText", column, newCellText)
                                        }

                                        let regexTagLink = /(<a[^>]*?href=\"((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))\">(.*?)<\/a>)/g;
                                        let regexLinkTitle = /<a[^>]*?href=\"(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\">(.*?)<\/a>/;
                                        let tagLinkArray = newCellText.match(regexTagLink) || []
                                        let regexImg = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\".*?\">.*?<\/button>/g;
                                        let regexSrcTitle = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\"(.*?)\">(.*?)<\/button>/
                                        let imgArray = newCellText.match(regexImg) || []

                                        if (index < 3) {
                                            console.log("tagLinkArray", tagLinkArray)
                                        }

                                        for (let i = 0; i < tagLinkArray.length; i++) {
                                            let link = tagLinkArray[i].match(regexLinkTitle)[1]

                                            newCellText = newCellText.replace(tagLinkArray[i], link)

                                            if (index < 3) {
                                                console.log("tagLinkArray I", tagLinkArray[i], link, newCellText.replace(tagLinkArray[i], link))
                                            }
                                        }

                                        if (index < 3) {
                                            console.log("imgArray", imgArray)
                                        }

                                        for (let i = 0; i < imgArray.length; i++) {
                                            let srcValue = imgArray[i].match(regexSrcTitle)[1]
                                            let titleValue =  imgArray[i].match(regexSrcTitle)[2]

                                            newCellText = newCellText.replace(imgArray[i], "(IMG: " + titleValue + ")")

                                            if (index < 3) {
                                                console.log("imgArray I", imgArray[i], srcValue, titleValue)
                                            }
                                        }

                                        value.push(newCellText)
                                    } else {
                                        value.push(row.data[column.code])
                                    }
                                } else {
                                    value.push(row.data[column.code])
                                }
                            }

                            return column
                        })

                        return columnInfo
                    })

                    data.push(value)
                }

                return row
            })

            return rowInfo
        })

        console.log("excelData", data)
        return {
            data: data,
            colProperties: colProperties
        }
    }

    function getCurrentDate() {
        let today = new Date()
        let day = String(today.getDate()).padStart(2, '0')
        let month = String(today.getMonth() + 1).padStart(2, '0')
        let year = today.getFullYear()

        return day + '.' + month + '.' + year
    }

    function setInputRegexModalValue(active, column, row, value, oldValue) {
        setSaveInputRegexModalActive(active)

        setSaveInputRegexModalData(
            saveInputRegexModalData.map(info => {
                let regexLink = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;
                let regexTagLink = /(<a[^>]*?href=\"((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))\">(.*?)<\/a>)/g;
                let regexLinkTitle = /<a[^>]*?href=\"(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\">(.*?)<\/a>/;
                let regexExtraTags = /">.*?<\/a>/g;
                let linkArray = value.match(regexLink) || []
                let tagLinkArray = value.match(regexTagLink) || []
                let associativeLinks = []

                for (let i = 0; i < tagLinkArray.length; i++) {
                    let link =  tagLinkArray[i].match(regexLinkTitle)[1]
                    let linkTitle =  tagLinkArray[i].match(regexLinkTitle)[2]

                    for (let j = 0; j < linkArray.length; j++) {
                        if (linkArray[j].includes(link)) {
                            linkArray[j] = link
                        }
                    }
                }

                for (let i = 0; i < linkArray.length; i++) {
                    associativeLinks[linkArray[i]] = {
                        "link": linkArray[i],
                        "title": "",
                        "tagExist": ""
                    }
                }

                for (let i = 0; i < tagLinkArray.length; i++) {
                    let link = tagLinkArray[i].match(regexLinkTitle)[1]
                    let linkTitle =  tagLinkArray[i].match(regexLinkTitle)[2]

                    associativeLinks[link].tagExist = tagLinkArray[i]
                    associativeLinks[link].title = linkTitle
                }

                info.row = row
                info.column = column
                info.value = value
                info.oldValue = oldValue
                info.links = associativeLinks

                return info
            })
        )

        console.log("saveInputRegexModalData", saveInputRegexModalData)
    }

    async function saveInputCellWithTag(column, row, value, oldValue) {
        console.log("NEW VALUE", value)

        // if (value !== oldValue) {
            await fetch('/proxy/project_bible_template/projectBibleFilledCellTextByName', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode,
                    "colCode": column,
                    "rowCode": row
                })
            })
                .then(res => res.json())
                .then(
                    async (resultEditable) => {
                        console.log("resultEditable", resultEditable)

                        if (resultEditable.length) {
                            await fetch('/proxy/project_bible_template/projectBibleOninputUpdateTextCell', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "colCode": column,
                                    "rowCode": row,
                                    "value": value,
                                    "changedBy": {
                                        id: employeeId,
                                        name: employeeName
                                    }
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultUpdate) => {
                                        setRows(rows.map(rowsData => {
                                            rowsData.data.map(rowValue => {
                                                console.log("SAVE", rowValue.code, row)

                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = value
                                                }

                                                return rowValue
                                            })

                                            return rowsData
                                        }))

                                        setSaveInputRegexModalData(
                                            saveInputRegexModalData.map(info => {
                                                info.links = []

                                                return info
                                            })
                                        )

                                        setSaveInputRegexModalActive(false)
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        } else {
                            await fetch('/proxy/project_bible_template/projectBibleOninputInsertTextCell', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "colCode": column,
                                    "rowCode": row,
                                    "value": value,
                                    "changedBy": {
                                        id: employeeId,
                                        name: employeeName
                                    }
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultInsert) => {
                                        setRows(rows.map(rowsData => {
                                            rowsData.data.map(rowValue => {
                                                console.log("SAVE", rowValue.code, row, rowValue)

                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = value
                                                }

                                                return rowValue
                                            })

                                            return rowsData
                                        }))

                                        setSaveInputRegexModalData(
                                            saveInputRegexModalData.map(info => {
                                                info.links = []

                                                return info
                                            })
                                        )

                                        setSaveInputRegexModalActive(false)
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        }
                    },
                    (error) => {
                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                            "верное значение")
                    }
                )
        // }
    }

    async function openStyleGuideModalWindow() {
        setCreateStyleGuideModalActive(true)
    }

    async function createStyleGuide(languages, logOfNewBatches, characterCommunication) {
        console.log("createStyleGuide", languages, logOfNewBatches)
        let languagesArray = []

        for (let i = 0; i < languages.length; i++) {
            if (languages[i].value) {
                languagesArray.push(languages[i].code)
            }
        }

        await fetch("/proxy/project_bible_template/projectBibleStyleGuideInsert", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "logOfNewBatches": logOfNewBatches,
                "characterCommunication": characterCommunication,
                "languages": /*languages*/languagesArray
            }),
        })
            .then(res => res.json())
            .then(
                (resultAdd) => {
                    let link = window.location.href.split("/")

                    window.open(
                        'http://' + link[2] + '/managed_quality_on_project/project_bible/styleguide?client_name=' +
                        encodeURIComponent(clientName) + '&project_name=' +
                        encodeURIComponent(projectName) + '&project_code=' +
                        encodeURIComponent(projectCode) +
                        '#', '_blank')

                    setStyleGuideExists(true)
                    setCreateStyleGuideModalActive(false)
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    /*async function deleteStyleGuide() {
        await fetch("/proxy/project_bible_template/projectBibleStyleGuideDelete", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
            }),
        })
            .then(res => res.json())
            .then(
                (resultDelete) => {
                    setStyleGuideExists(false)
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }*/

    async function openStyleGuideLink() {
        let link = window.location.href.split("/")

        window.open('http://' + link[2] + '/managed_quality_on_project/project_bible/styleguide?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' + encodeURIComponent(projectName) +
            '&project_code=' + encodeURIComponent(projectCode) +
            '#', '_blank')
    }

    async function copyStyleGuideLinkForLinguists() {
        let area = document.createElement('textarea');
        document.body.appendChild(area);
        let link = window.location.href.split("/")
        area.value = 'http://' + link[2] + '/managed_quality_on_project/project_bible/styleguidelinguist?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' + encodeURIComponent(projectName) +
            '&project_code=' + encodeURIComponent(projectCode) + '#';
        area.select();
        document.execCommand("copy");
        document.body.removeChild(area);
        alert("Ссылка скопирована в буфер обмена")
    }

    async function openModalToAddLinksAndImages(value, method, modalHistoryTitle, tableDBName, identificationData) {
        let queryDB = "/proxy/project_bible_template/"

        clearModalOpenCellDataHistoryData()

        if (tableDBName === "project_bible_text") {
            queryDB += "getHistoryOfChangesInProjectBibleTextData"

            if (typeof value !== "string") {
                return
            }
        }

        await fetch(queryDB, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "tableName": tableDBName,
                "identityData": identificationData
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setModalOpenCellDataHistoryData(
                        modalOpenCellDataHistoryData.map(info => {
                            for (let i = 0; i < result.main.length; i++) {
                                result.main[i]["isOtherValueChecked"] = false
                            }

                            let resultData = result.main

                            if ("client" in result) {
                                for (let i = 0; i < result.client.length; i++) {
                                    result.client[i]["notAcceptedValuesFromClient"] = true
                                }

                                resultData = result.main.concat(result.client)

                                /*resultData = */resultData.sort(function (a, b) {
                                    let dateA = new Date(a.tstamp)
                                    let dateB = new Date(b.tstamp)

                                    console.log("sort", dateA.getTime(), dateB.getTime())

                                    if (dateA.getTime() < dateB.getTime()) {
                                        return 1;
                                    }
                                    if (dateA.getTime() > dateB.getTime()) {
                                        return -1;
                                    }

                                    return 0;
                                })
                            }

                            info.modalTitle = modalHistoryTitle
                            info.tableName = tableDBName
                            info.saveMethod = method
                            info.data = resultData
                            info.identityData = {
                                column: identificationData.column,
                                row: identificationData.row,
                                type: identificationData.type,
                                refillCell: true
                            }

                            return info
                        })
                    )

                    setModalAddLinksAndImagesActive(true)

                    setModalAddLinksAndImagesData(
                        modalAddLinksAndImagesData.map(info => {
                            let regexLink = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;
                            let regexTagLink = /(<a[^>]*?href=\"((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))\">(.*?)<\/a>)/g;
                            let regexLinkTitle = /<a[^>]*?href=\"(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\">(.*?)<\/a>/;
                            let linkArray = value.match(regexLink) || []
                            let tagLinkArray = value.match(regexTagLink) || []
                            let associativeLinks = []
                            let regexImg = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\".*?\">.*?<\/button>/g;
                            let regexSrcTitle = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\"(.*?)\">(.*?)<\/button>/
                            let imgArray = value.match(regexImg) || []
                            let associativeImages = []

                            for (let i = 0; i < tagLinkArray.length; i++) {
                                let link = tagLinkArray[i].match(regexLinkTitle)[1]

                                for (let j = 0; j < linkArray.length; j++) {
                                    if (linkArray[j].includes(link)) {
                                        linkArray[j] = link
                                    }
                                }
                            }

                            for (let i = 0; i < linkArray.length; i++) {
                                associativeLinks[linkArray[i]] = {
                                    "link": linkArray[i],
                                    "title": "",
                                    "tagExist": ""
                                }
                            }

                            for (let i = 0; i < imgArray.length; i++) {
                                let srcValue = imgArray[i].match(regexSrcTitle)[1]
                                let titleValue = imgArray[i].match(regexSrcTitle)[2]

                                associativeImages[i] = {
                                    "src": srcValue,
                                    "title": titleValue
                                }
                            }

                            for (let i = 0; i < tagLinkArray.length; i++) {
                                let link = tagLinkArray[i].match(regexLinkTitle)[1]
                                let linkTitle = tagLinkArray[i].match(regexLinkTitle)[2]

                                associativeLinks[link].tagExist = tagLinkArray[i]
                                associativeLinks[link].title = linkTitle
                            }

                            console.log("associativeImages", associativeImages)

                            info.value = value
                            info.saveMethod = method
                            info.identificationData = identificationData
                            info.links = associativeLinks
                            info.images = associativeImages
                            info.bufferedImages = associativeImages

                            return info
                        })
                    )
                },
                (error) => {

                }
            )
    }

    async function generalTabSaveCellValue(column, row, type, value, refillCell) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'

        if (type === "input") {
            queryLinkExist += 'projectBibleFilledCellTextByName'
            queryUpdateCell += 'projectBibleOninputUpdateTextCell'
            queryInsertCell += 'projectBibleOninputInsertTextCell'
        } else if (type === "checkbox") {
            queryLinkExist += 'projectBibleFilledCellBoolByName'
            queryUpdateCell += 'projectBibleOninputUpdateBoolCell'
            queryInsertCell += 'projectBibleOninputInsertBoolCell'
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    if (refillCell) {
                                        setRows(
                                            rows.map(rowsData => {
                                                rowsData.data.map(rowValue => {
                                                    if (rowValue.code === row) {
                                                        rowValue.data[column] = value

                                                        triggerOpenInModalAddedImage()
                                                    }

                                                    console.log("UPDATE", column, row, value, rowValue)

                                                    return rowValue
                                                })

                                                return rowsData
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                    calculateBibleFillPercentage()
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    if (refillCell) {
                                        setRows(
                                            rows.map(rowsData => {
                                                rowsData.data.map(rowValue => {
                                                    if (rowValue.code === row) {
                                                        rowValue.data[column] = value

                                                        triggerOpenInModalAddedImage()
                                                    }

                                                    console.log("INSERT", column, row, value, rowValue)

                                                    return rowValue
                                                })

                                                return rowsData
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                    calculateBibleFillPercentage()
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function generalTabSaveBoolCellValue(column, row, type, value) {
        let queryLinkExist = '/proxy/project_bible_template/projectBibleFilledCellBoolByName'
        let queryUpdateCell = '/proxy/project_bible_template/projectBibleOninputUpdateBoolCell'
        let queryInsertCell = '/proxy/project_bible_template/projectBibleOninputInsertBoolCell'

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "colCode": column,
                                "rowCode": row,
                                "value": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    setRows(
                                        rows.map(rowsData => {
                                            rowsData.data.map(rowValue => {
                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = value
                                                }

                                                return rowValue
                                            })

                                            return rowsData
                                        })
                                    )

                                    calculateBibleFillPercentage()
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "colCode": column,
                                "rowCode": row,
                                "value": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    setRows(
                                        rows.map(rowsData => {
                                            rowsData.data.map(rowValue => {
                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = value
                                                }

                                                return rowValue
                                            })

                                            return rowsData
                                        })
                                    )

                                    calculateBibleFillPercentage()
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    function removeDataJustUploadedParam(value) {
        let newValue = value

        newValue = newValue.replace(/data-just-uploaded='1'/g,'')
        newValue = newValue.replace(/data-just-uploaded="1"/g,'')

        return newValue
    }

    function openInModalAddedImage(path) {
        console.log("CLICK openInModalAddedImage")

        setModalOpenAddedImageSrc(path)
        setModalOpenAddedImageActive(true)
    }

    function triggerOpenInModalAddedImage() {
        const openInModalAddedImageArray = document.getElementsByClassName("open-in-modal-added-image")

        for (const trigger of openInModalAddedImageArray) {
            /*trigger.addEventListener('click', (e) => {
                if (e.target.dataset.src) {
                    openInModalAddedImage(e.target.dataset.src)
                }
            });*/

            trigger.addEventListener('mouseover', (e) => {
                setTooltipImageData(
                    tooltipImageData.map(data => {
                        data.src = e.target.dataset.src
                        data.title = trigger.innerText
                        data.element = e
                        data.justUploaded = "justUploaded" in e.target.dataset && e.target.dataset.justUploaded == "1";

                        return data
                    })
                )
            });
        }
    }

    function setImagesBufferedData(bufferedData) {
        setModalAddLinksAndImagesData(
            modalAddLinksAndImagesData.map(info => {
                info.bufferedImages = bufferedData

                return info
            })
        )
    }

    function selectTemplateFilter(filterId, filterType, filterData) {
        console.log("Выбрали фильтр", filterId, filterType, filterData)

        setTemplatesFilter(
            templatesFilter.map(info => {
                info.isOn.value = true
                info.isOn.type = filterType
                info.isOn.id = filterId
                info.isOn.filter = filterData

                return info
            })
        )

        filterData.map(filterInfo => {
            setColumns(
                columns.map(columnInfo => {
                    columnInfo.data.map(column => {
                        if (column.code === filterInfo.code) {
                            if (column.type === "input") {
                                let selectedData = filterInfo.showAll.selectedData
                                let correctSelectedDataArray = []

                                column.filter.show = filterInfo.show
                                column.filter.showAll.value = filterInfo.showAll.value
                                column.filter.showAll.showFilledCells = filterInfo.showAll.showFilledCells
                                column.filter.showAll.showEmptyCells = filterInfo.showAll.showEmptyCells

                                for (let i = 0; i < selectedData.length; i++) {
                                    if (column.filter.showAll.data.has(selectedData[i])) {
                                        correctSelectedDataArray.push(selectedData[i])
                                    }
                                }

                                column.filter.showAll.selectedData = correctSelectedDataArray
                            } else if (column.type === "checkbox") {
                                column.filter.show = filterInfo.show
                                column.filter.showAll.value = filterInfo.showAll.value
                                column.filter.showAll.showFilledCells = filterInfo.showAll.showFilledCells
                            } else if (column.type === "tags_list") {
                                let selectedData = filterInfo.showAll.selectedData
                                let correctSelectedDataArray = []

                                column.filter.show = filterInfo.show
                                column.filter.showAll.value = filterInfo.showAll.value
                                column.filter.showAll.showFilledCells = filterInfo.showAll.showFilledCells
                                column.filter.showAll.showEmptyCells = filterInfo.showAll.showEmptyCells

                                for (let i = 0; i < selectedData.length; i++) {
                                    let group = selectedData[i].group
                                    let tag = selectedData[i].tag
                                    let showAllData = column.filter.showAll.data

                                    if (showAllData.has(group)) {
                                        if (showAllData.get(group).includes(tag)) {
                                            correctSelectedDataArray.push(selectedData[i])
                                        }
                                    }
                                }

                                column.filter.showAll.selectedData = correctSelectedDataArray
                            }
                        }

                        return column
                    })

                    return columnInfo
                })
            )

            return filterInfo
        })

        filterTable()
        calculateBibleFillPercentage()
    }

    function removeTemplatesFilterIsOn() {
        resetFilter()

        setTemplatesFilter(
            templatesFilter.map(info => {
                info.isOn.value = false

                return info
            })
        )

        calculateBibleFillPercentage()
    }

    function openModalToSaveNewTemplateFilter() {
        setModalOpenTemplatesFilterEditActive(true)
        setModalOpenTemplatesFilterEditType("save")
    }

    function openModalToRenameTemplateFilter() {
        setModalOpenTemplatesFilterEditActive(true)
        setModalOpenTemplatesFilterEditType("rename")
    }

    async function saveNewTemplateFilter(filterName) {
        let filterData = []

        columns.map(info => {
            info.data.map(column => {
                filterData.push({
                    "code": column.code,
                    "name": column.name,
                    "show": column.filter.show,
                    "type": column.type,
                    "showAll": {
                        "value": column.filter.showAll.value,
                        "selectedData": column.filter.showAll.selectedData,
                        "showEmptyCells": column.filter.showAll.showEmptyCells,
                        "showFilledCells": column.filter.showAll.showFilledCells
                    }
                })

                return column
            })

            return info
        })

        await fetch("/proxy/project_bible_template/addProjectBibleTemplateFilter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "filterName": filterName,
                "filterData": filterData
            }),
        })
            .then(res => res.json())
            .then(
                (resultSaveTemplateFilter) => {
                    let newId = resultSaveTemplateFilter.id

                    setTemplatesFilter(
                        templatesFilter.map(info => {
                            let newFilter = {
                                id: newId,
                                client_name: clientName,
                                project_name: projectName,
                                project_code: projectCode,
                                filter_name: filterName,
                                filter: filterData
                            }

                            console.log("ADD FILTER", newId, newFilter, info.filters.project, info.isOn)

                            info.filters.project.push(newFilter)

                            info.isOn.value = true
                            info.isOn.id = newId
                            info.isOn.type = "project"
                            info.isOn.filter = filterData

                            return info
                        })
                    )

                    setModalOpenTemplatesFilterEditActive(false)
                },
                (error) => {
                    setError({
                        message: "Error on saving new filter. Please try again."
                    })
                }
            )
    }

    async function renameTemplateFilter(filterName) {
        let currentId = templatesFilter[0].isOn.id

        await fetch("/proxy/project_bible_template/renameProjectBibleTemplateFilter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "id": currentId,
                "filterName": filterName
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setTemplatesFilter(
                        templatesFilter.map(info => {
                            for (let keyType in info.filters) {
                                if (keyType === templatesFilter[0].isOn.type) {
                                    let valueType = info.filters[keyType]

                                    for (let key in valueType) {
                                        let value = valueType[key]

                                        console.log("RENAME FILTER", currentId, value.id)

                                        if (value.id === currentId) {
                                            value.filter_name = filterName
                                        }
                                    }
                                }
                            }

                            return info
                        })
                    )

                    setModalOpenTemplatesFilterEditActive(false)
                },
                (error) => {
                    setError({
                        message: "Error on renaming filter. Please try again."
                    })
                }
            )
    }

    async function deleteTemplateFilter() {
        let currentId = templatesFilter[0].isOn.id
        let currentType = templatesFilter[0].isOn.type

        await fetch("/proxy/project_bible_template/deleteProjectBibleTemplateFilter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "id": currentId
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    resetFilter()

                    setTemplatesFilter(
                        templatesFilter.map(info => {
                            info.isOn.value = false

                            for (let i = 0; i < info.filters[currentType].length; i++) {
                                if (info.filters[currentType][i].id === currentId) {
                                    info.filters[currentType].splice(i, 1)

                                    break;
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setError({
                        message: "Error on deleting filter. Please try again."
                    })
                }
            )
    }

    async function updateTemplateFilter() {
        let filterData = []
        let currentId = templatesFilter[0].isOn.id

        columns.map(info => {
            info.data.map(column => {
                filterData.push({
                    "code": column.code,
                    "name": column.name,
                    "show": column.filter.show,
                    "type": column.type,
                    "showAll": {
                        "value": column.filter.showAll.value,
                        "selectedData": column.filter.showAll.selectedData,
                        "showEmptyCells": column.filter.showAll.showEmptyCells,
                        "showFilledCells": column.filter.showAll.showFilledCells
                    }
                })

                return column
            })

            return info
        })

        await fetch("/proxy/project_bible_template/updateProjectBibleTemplateFilter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "id": currentId,
                "filterData": filterData
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setTemplatesFilter(
                        templatesFilter.map(info => {
                            for (let key in info.filters[templatesFilter[0].isOn.type]) {
                                let value = info.filters[templatesFilter[0].isOn.type][key]

                                if (value.id === currentId) {
                                    value.filter = filterData
                                }
                            }

                            info.isOn.filter = filterData

                            return info
                        })
                    )

                    setModalOpenTemplatesFilterEditActive(true)
                    setModalOpenTemplatesFilterEditType("update")
                },
                (error) => {
                    setError({
                        message: "Error on updating filter. Please try again."
                    })
                }
            )
    }

    async function openCellDataHistory(modalTitle, tableName, saveMethod, identityData) {
        let queryDB = "/proxy/project_bible_template/"

        clearModalOpenCellDataHistoryData()

        if (tableName === "project_bible_text") {
            queryDB += "getHistoryOfChangesInProjectBibleTextData"

            if (typeof identityData.value !== "string") {
                return
            }
        } else if (tableName === "project_bible_bool") {
            queryDB += "getHistoryOfChangesInProjectBibleBoolData"

            if (typeof identityData.value !== "boolean") {
                return
            }
        }

        await fetch(queryDB, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "tableName": tableName,
                "identityData": identityData
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setModalOpenCellDataHistoryData(
                        modalOpenCellDataHistoryData.map(info => {
                            for (let i = 0; i < result.length; i++) {
                                result[i]["isOtherValueChecked"] = false
                            }

                            info.modalTitle = modalTitle
                            info.tableName = tableName
                            info.saveMethod = saveMethod
                            info.data = result
                            info.identityData = identityData

                            return info
                        })
                    )
                    setModalOpenCellDataHistoryActive(true)
                },
                (error) => {

                }
            )
    }

    function clearModalOpenCellDataHistoryData() {
        setModalOpenCellDataHistoryData(
            modalOpenCellDataHistoryData.map(info => {
                info.modalTitle = ""
                info.tableName = ""
                info.saveMethod = ""
                info.data = []
                info.identityData = []

                return info
            })
        )
    }

    async function replaceToOldValueFromDataHistory(saveMethod, identityData, tableName, newValue) {
        if (saveMethod === "generalTabSaveCellValue") {
            await generalTabSaveCellValue(identityData.column, identityData.row,
                identityData.type, newValue, identityData.refillCell)

            setModalOpenCellDataHistoryActive(false)
        } else if (saveMethod === "generalTabSaveBoolCellValue") {
            await generalTabSaveBoolCellValue(identityData.column, identityData.row,
                identityData.type, newValue)

            setModalOpenCellDataHistoryActive(false)
            window.location.reload()
        }
    }

    function editModalHistoryDataCheckedValue(key, isСhecked) {
        setModalOpenCellDataHistoryData(
            modalOpenCellDataHistoryData.map(info => {
                info.data.map((storedValue, index) => {
                    storedValue.isOtherValueChecked = isСhecked && index === key

                    return storedValue
                })

                return info
            })
        )
    }

    function backToPBList() {
        let link = window.location.href.split("/")

        window.location.href = 'http://' + link[2] + '/managed_quality_on_project/pb_report';
    }

    function openClientView() {
        let link = window.location.href.split("/")

        window.open('http://' + link[2] + '/managed_quality_on_project/project_bible/client_view_new?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' +
            encodeURIComponent(projectName) + '&project_code=' +
            encodeURIComponent(projectCode) + '&code=' + clientViewData[0].code + '#', '_blank')
    }

    function copyClientViewLink() {
        let area = document.createElement('textarea');
        document.body.appendChild(area);

        let link = window.location.href.split("/")
        area.value = 'http://' + link[2] + '/managed_quality_on_project/project_bible/client_view_new?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' +
            encodeURIComponent(projectName) + '&project_code=' +
            encodeURIComponent(projectCode) + '&code=' + clientViewData[0].code + '#';

        area.select();
        document.execCommand("copy");
        document.body.removeChild(area);
    }

    async function openClientViewChanges() {
        resetClientViewData()
        await getClientViewAdditionalComment()
        await getClientViewGeneralColumns()
        await getClientViewGeneralRows()
        await fillClientViewGeneralData()
        await getClientViewStyleGuideData()

        setClientViewAcceptDataModalActive(true)
    }

    function resetClientViewData() {
        setClientViewData(
            clientViewData.map(info => {
                info.additionalComment = ""
                info.generalTabRowsList = []
                info.generalTabRows = []
                info.languageTabData = []
                info.languageTabListOfCodes = []
                info.languageTabLinguistLinksData = []

                return info
            })
        )
    }

    async function getClientViewAdditionalComment() {
        await fetch("/proxy/project_bible_template/getClientViewAdditionalComment", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": clientViewData[0].code
            })
        })
            .then(res => res.json())
            .then(
                async (resultAdditionalComment) => {
                    if (resultAdditionalComment.length) {
                        setClientViewData(
                            clientViewData.map(info => {
                                info.additionalComment = resultAdditionalComment[0].comment

                                return info
                            })
                        )
                    }
                },
                (error) => {
                    alert("An error occurred while loading client data. Please try again or refresh the page.")
                }
            )
    }

    async function getClientViewGeneralColumns(){
        await fetch("/proxy/project_bible_template/getPBColumnsForClientView", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    let associativeColumns = {}

                    for (let i = 0; i < result.length; i++) {
                        associativeColumns[result[i].code] = result[i]
                    }

                    setClientViewData(
                        clientViewData.map(info => {
                            info.generalTabColumns.map(column => {
                                column["name"] = associativeColumns[column.code].name
                                column["type"] = associativeColumns[column.code].type
                                column["template"] = associativeColumns[column.code].template
                                column["editable"] = associativeColumns[column.code].editable

                                return column
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    alert("An error occurred while loading client data. Please try again or refresh the page.")
                }
            )
    }

    async function getClientViewGeneralRows(){
        await fetch("/proxy/project_bible_template/getPBClientViewRowsWithChanges", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": clientViewData[0].code
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    for (let i = 0; i < result.rows.length; i++) {
                        setClientViewData(
                            clientViewData.map(info => {
                                info.generalTabRowsList.push(result.rows[i]["row_code"])
                                info.generalTabRows.push({
                                    "code": result.rows[i]["row_code"],
                                    "data": []
                                })

                                return info
                            })
                        )
                    }
                },
                (error) => {
                    alert("An error occurred while loading client data. Please try again or refresh the page.")
                }
            )
    }

    async function fillClientViewGeneralData() {
        await fetch("/proxy/project_bible_template/getPBCellDataForClientViewAllByRows", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                clientName: clientName,
                projectName: projectName,
                projectCode: projectCode,
                viewCode: clientViewData[0].code,
                rows: clientViewData[0].generalTabRowsList
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    let associativeTemplateValues = {}
                    let associativeEditableValues = {}
                    let associativeEditableByClientValues = {}

                    for (let i = 0; i < result.templateValues.length; i++) {
                        let valueParam = result.templateValues[i]

                        if (!(valueParam["row_code"] in associativeTemplateValues)) {
                            associativeTemplateValues[valueParam["row_code"]] = {}
                        }

                        associativeTemplateValues[valueParam["row_code"]][valueParam["col_code"]] = {
                            value: valueParam["value"]
                        }
                    }

                    for (let i = 0; i < result.editableValues.length; i++) {
                        let valueParam = result.editableValues[i]

                        if (!(valueParam["row_code"] in associativeEditableValues)) {
                            associativeEditableValues[valueParam["row_code"]] = {}
                        }

                        associativeEditableValues[valueParam["row_code"]][valueParam["col_code"]] = {
                            value: valueParam["value"]
                        }
                    }

                    for (let i = 0; i < result.editableByClientValues.length; i++) {
                        let valueParam = result.editableByClientValues[i]

                        if (!(valueParam["row_code"] in associativeEditableByClientValues)) {
                            associativeEditableByClientValues[valueParam["row_code"]] = {}
                        }

                        console.log("valueParam[\"value\"]", valueParam["value"][0])

                        associativeEditableByClientValues[valueParam["row_code"]][valueParam["col_code"]] = {
                            value: valueParam["value"][0],
                            isAccepted: /*valueParam["is_accepted"]*/false
                        }
                    }

                    console.log("getPBCellDataForClientViewAllByRows", associativeTemplateValues,
                        associativeEditableValues, associativeEditableByClientValues)

                    setClientViewData(
                        clientViewData.map(info => {
                            info.generalTabRows.map(row => {
                                /*rowsInfo.data.map(row => {*/
                                    info.generalTabColumns.map(column => {
                                        /*columnsInfo.data.map(column => {*/
                                            let mainValue = ""
                                            let clientValue = ""

                                            if (row.code in associativeTemplateValues &&
                                                column.code in associativeTemplateValues[row.code]) {
                                                mainValue = associativeTemplateValues[row.code][column.code].value
                                            }

                                            if (row.code in associativeEditableValues &&
                                                column.code in associativeEditableValues[row.code]) {
                                                mainValue = associativeEditableValues[row.code][column.code].value
                                            }

                                            if (row.code in associativeEditableByClientValues &&
                                                column.code in associativeEditableByClientValues[row.code]) {
                                                clientValue = associativeEditableByClientValues[row.code][column.code].value
                                            }

                                            row.data[column.code] = {
                                                mainValue: mainValue
                                            }

                                            if (column.editableByClient) {
                                                row.data[column.code]["clientValue"] = clientValue
                                            }

                                            /*return column
                                        })*/

                                        return column
                                    })

                                    /*return row
                                })*/

                                return row
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    alert("An error occurred while loading client data. Please try again or refresh the page.")
                }
            )
    }

    async function getClientViewStyleGuideData() {
        await fetch("/proxy/project_bible_template/getPBStyleGuideDataForClientViewAllByRows", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "viewCode": clientViewData[0].code
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setClientViewData(
                        clientViewData.map(value => {
                            for (let i = 0; i < result.querySGLanguages.length; i++) {
                                if (result.queryPBStyleGuide[0].languages.includes(result.querySGLanguages[i]["language_code"])) {
                                    value.languageTabListOfCodes.push(result.querySGLanguages[i]["language_code"])
                                    value.languageTabData.push({
                                        title: result.querySGLanguages[i].language,
                                        code: result.querySGLanguages[i]["language_code"],
                                        data: [],
                                        accept: {}
                                    })
                                }
                            }

                            return value
                        })
                    )

                    result.querySGLinguist.map(value => {
                        setClientViewData(
                            clientViewData.map(info => {
                                info.languageTabLinguistLinksData[value["linguist_id"]] = {
                                    code: value["linguist_id"],
                                    languages: value.languages,
                                    languagesNames: [],
                                    languagePreferences: {},
                                    acceptData: []
                                }

                                return info
                            })
                        )

                        return value
                    })

                    result.querySGLinguistAcceptChanges.map(value => {
                        setClientViewData(
                            clientViewData.map(info => {
                                let linguistId = value["linguist_id"]
                                let languageCode = value["language_code"]
                                let acceptLanguagePreferences = value["accept_language_preferences"]

                                info.languageTabLinguistLinksData[linguistId].acceptData[languageCode] = {
                                    languagePreferences: {
                                        value: acceptLanguagePreferences
                                    }
                                }

                                if (acceptLanguagePreferences) {
                                    setLanguagePreferencesAcceptData(linguistId, languageCode, acceptLanguagePreferences)
                                }

                                return info
                            })
                        )

                        return value
                    })

                    fillInLanguageData(result.querySGLanguages, result.querySGLanguageExtraRows,
                        result.querySGLanguageFilledData, result.querySGLinguistLanguageFilledData,
                        result.queryClientViewSGFilledData)
                },
                (error) => {
                    alert("An error occurred while loading client data. Please try again or refresh the page.")
                }
            )
    }

    function setLanguagePreferencesAcceptData(linguistId, languageCode, value) {
        setClientViewData(
            clientViewData.map(info => {
                info.languageTabData.map(language => {
                    if (language.code === languageCode) {
                        language.accept[linguistId] = value
                    }

                    return language
                })

                return info
            })
        )
    }

    function fillInLanguageData(querySGLanguages, querySGLanguageExtraRows, querySGLanguageFilledData,
                                querySGLinguistLanguageFilledData, queryClientViewSGFilledData) {
        let sgLanguagesByCode = {}
        let sgExtraRowsByLanguageCode = {}
        let sgFilledDataByLanguageCode = {}
        let sgLinguistFilledDataByLanguageCode = {}
        let sgClientFilledDataByLanguageCode = {}

        for (let i = 0; i < querySGLanguages.length; i++) {
            let languageCode = querySGLanguages[i]["language_code"]

            sgLanguagesByCode[languageCode] = querySGLanguages[i]
        }

        for (let i = 0; i < querySGLanguageExtraRows.length; i++) {
            let languageCode = querySGLanguageExtraRows[i]["language_code"]

            if (!(languageCode in sgExtraRowsByLanguageCode)) {
                sgExtraRowsByLanguageCode[languageCode] = []
            }

            sgExtraRowsByLanguageCode[languageCode].push(querySGLanguageExtraRows[i])
        }

        for (let i = 0; i < querySGLanguageFilledData.length; i++) {
            let languageCode = querySGLanguageFilledData[i]["language_code"]

            if (!(languageCode in sgFilledDataByLanguageCode)) {
                sgFilledDataByLanguageCode[languageCode] = []
            }

            sgFilledDataByLanguageCode[languageCode].push(querySGLanguageFilledData[i])
        }

        for (let i = 0; i < querySGLinguistLanguageFilledData.length; i++) {
            let linguistId = querySGLinguistLanguageFilledData[i]["linguist_id"]
            let languageCode = querySGLinguistLanguageFilledData[i]["language_code"]
            let blockCode = querySGLinguistLanguageFilledData[i]["block"]
            let rowCode = querySGLinguistLanguageFilledData[i]["row_code"]
            let colCode = querySGLinguistLanguageFilledData[i]["col_code"]
            let value = querySGLinguistLanguageFilledData[i]["value"]

            if (!(linguistId in sgLinguistFilledDataByLanguageCode)) {
                sgLinguistFilledDataByLanguageCode[linguistId] = {}
            }

            if (!(languageCode in sgLinguistFilledDataByLanguageCode[linguistId])) {
                sgLinguistFilledDataByLanguageCode[linguistId][languageCode] = []
            }

            if (!(blockCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode])) {
                sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode] = {}
            }

            if (!(rowCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode])) {
                sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode][rowCode] = {}
            }

            sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode][rowCode][colCode] = value
        }

        for (let i = 0; i < queryClientViewSGFilledData.length; i++) {
            let languageCode = queryClientViewSGFilledData[i]["language_code"]
            let blockCode = queryClientViewSGFilledData[i]["block"]
            let rowCode = queryClientViewSGFilledData[i]["row_code"]
            let colCode = queryClientViewSGFilledData[i]["col_code"]
            let value = queryClientViewSGFilledData[i]["value"]
            let isAccepted = /*queryClientViewSGFilledData[i]["is_accepted"]*/false

            if (!(languageCode in sgClientFilledDataByLanguageCode)) {
                sgClientFilledDataByLanguageCode[languageCode] = []
            }

            if (!(blockCode in sgClientFilledDataByLanguageCode[languageCode])) {
                sgClientFilledDataByLanguageCode[languageCode][blockCode] = {}
            }

            if (!(rowCode in sgClientFilledDataByLanguageCode[languageCode][blockCode])) {
                sgClientFilledDataByLanguageCode[languageCode][blockCode][rowCode] = {}
            }

            sgClientFilledDataByLanguageCode[languageCode][blockCode][rowCode][colCode] = {
                value: value,
                isAccepted: isAccepted
            }
        }

        for (let languageCode in sgExtraRowsByLanguageCode) {
            let rows = sgExtraRowsByLanguageCode[languageCode]

            if (languageCode in sgLanguagesByCode) {
                for (let i = 0; i < rows.length; i++) {
                    let rowToPush = []

                    for (let j = 0; j < rows[i].columns.length; j++) {
                        rowToPush.push({
                            "code": rows[i].columns[j],
                            "text": "",
                            "styleGuideEditable": true
                        })
                    }

                    if (rows[i].block in sgLanguagesByCode[languageCode]) {
                        sgLanguagesByCode[languageCode][rows[i].block].data.push({
                            "row": rowToPush,
                            "code": rows[i]["row_code"],
                            "colspan": rows[i].colspan,
                            "extraRow": true
                        })
                    }
                }
            }
        }

        for (let languageCode in sgFilledDataByLanguageCode) {
            let rows = sgFilledDataByLanguageCode[languageCode]

            if (languageCode in sgLanguagesByCode) {
                for (let i = 0; i < rows.length; i++) {
                    if (rows[i].block in sgLanguagesByCode[languageCode]) {
                        let elData = sgLanguagesByCode[languageCode][rows[i].block].data

                        for (let j = 0; j < elData.length; j++) {
                            if (elData[j].code === rows[i].row_code) {
                                for (let k = 0; k < elData[j].row.length; k++) {
                                    if (elData[j].row[k].code === rows[i].col_code) {
                                        elData[j].row[k].text = rows[i].value

                                        /*if (languageCode in sgClientFilledDataByLanguageCode &&
                                            rows[i].block in sgClientFilledDataByLanguageCode[languageCode] &&
                                            rows[i].row_code in sgClientFilledDataByLanguageCode[languageCode][rows[i].block] &&
                                            rows[i].col_code in sgClientFilledDataByLanguageCode[languageCode][rows[i].block][rows[i].row_code]) {
                                            let clientValue = sgClientFilledDataByLanguageCode[languageCode][rows[i].block][rows[i].row_code][rows[i].col_code]

                                            elData[j]["show"] = true
                                            elData[j].row[k]["clientText"] = clientValue.value
                                        }*/
                                    }
                                }
                            }
                        }

                        sgLanguagesByCode[languageCode][rows[i].block].data = elData
                    }
                }
            }
        }

        for (let languageCode in sgClientFilledDataByLanguageCode) {
            let blocks = sgClientFilledDataByLanguageCode[languageCode]

            if (languageCode in sgLanguagesByCode) {
                for (let blockCode in blocks) {
                    if (blockCode in sgLanguagesByCode[languageCode]) {
                        let elData = sgLanguagesByCode[languageCode][blockCode].data
                        let rows = blocks[blockCode]

                        for (let i = 0; i < elData.length; i++) {
                            if (elData[i].code in rows) {
                                let columns = rows[elData[i].code]

                                for (let j = 0; j < elData[i].row.length; j++) {
                                    if (elData[i].row[j].code in columns) {
                                        let columnData = columns[elData[i].row[j].code]

                                        elData[i]["show"] = true
                                        elData[i].row[j]["clientText"] = columnData.value
                                    }
                                }
                            }
                        }

                        sgLanguagesByCode[languageCode][blockCode].data = elData
                    }
                }
            }
        }

        setClientViewData(
            clientViewData.map(info => {
                info.languageTabData.map(language => {
                    let languageCode = language.code
                    let languageAcceptData = language.accept

                    for (let linguistId in languageAcceptData) {
                        let linguistData = languageAcceptData[linguistId]

                        for (let block in linguistData) {
                            let blockData = linguistData[block]

                            for (let i = 0; i < blockData.default.length; i++) {
                                let rowCode = blockData.default[i]

                                if (languageCode in sgLanguagesByCode &&
                                    block in sgLanguagesByCode[languageCode]) {
                                    sgLanguagesByCode[languageCode][block].data.map(languageDataInfo => {
                                        if (languageDataInfo.code === rowCode) {
                                            for (let j = 0; j < languageDataInfo.row.length; j++) {
                                                let colCode = languageDataInfo.row[j].code

                                                if (!("isAccepted" in languageDataInfo.row[j])) {
                                                    if (linguistId in sgLinguistFilledDataByLanguageCode &&
                                                        languageCode in sgLinguistFilledDataByLanguageCode[linguistId] &&
                                                        block in sgLinguistFilledDataByLanguageCode[linguistId][languageCode] &&
                                                        rowCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block] &&
                                                        colCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode]) {
                                                        languageDataInfo.row[j].text =
                                                            sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode][colCode]
                                                    }
                                                }
                                            }
                                        }

                                        return languageDataInfo
                                    })
                                }
                            }
                        }
                    }

                    language.data = sgLanguagesByCode[languageCode]
                })

                return info
            })
        )
    }

    async function createOldClientView() {
        console.log('Отправленное имя: ' + clientViewManagerName);

        if (clientViewManagerName) {
            await fetch("/proxy/project_bible_template/projectBibleClientViewGenerateIndividualCode", {
                method: 'GET',
            })
                .then(res => res.json())
                .then(
                    async (resultGenerate) => {
                        if (!resultGenerate.exist.length) {
                            let columnsForClientView = createColumnsArrayForClientView()
                            let rowsForClientView = createRowsArrayForClientView()

                            await fetch("/proxy/project_bible_template/projectBibleClientViewInsert", {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "code": resultGenerate.individualCode,
                                    "columns": columnsForClientView,
                                    "rows": rowsForClientView,
                                    "manager": clientViewManagerName
                                }),
                            })
                                .then(res => res.json())
                                .then(
                                    (resultAdd) => {
                                        let link = window.location.href.split("/")

                                        window.open(
                                            'http://' + link[2] + '/managed_quality_on_project/project_bible/client_view?client_name=' +
                                            encodeURIComponent(clientName) + '&project_name=' +
                                            encodeURIComponent(projectName) + '&project_code=' +
                                            encodeURIComponent(projectCode) + '&code=' +
                                            encodeURIComponent(resultGenerate.individualCode) + '#', '_blank')

                                        setClientViewsList(
                                            clientViewsList.map(info => {
                                                console.log("DATE", new Date().toJSON())

                                                info.data.push({
                                                    code: resultGenerate.individualCode,
                                                    submit: false,
                                                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                                                    time: new Date().toLocaleTimeString('en-US', { hour12: false,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                        second: "numeric"}),
                                                    manager: clientViewManagerName
                                                })

                                                return info
                                            })
                                        )
                                    },
                                    (error) => {
                                        //todo придумать какой-то текст ошибки
                                    }
                                )
                        } else {
                            createClientView()
                        }
                    },
                    (error) => {
                        //todo придумать какой-то текст ошибки
                    }
                )
        } else {
            alert("Please fill manager name field at first")
        }
    }

    function createColumnsArrayForClientView() {
        let requiredColumns = []

        columns.map(info => {
            info.data.map(column => {
                if (column.filter.show) {
                    requiredColumns.push({
                        "code": column.code,
                        "name": column.name,
                        "type": column.type,
                        "editable": column.editable,
                        "template": column.template,
                    })
                }

                return column
            })

            return info
        })

        return requiredColumns
    }

    function createRowsArrayForClientView() {
        let requiredRows = []

        rows.map(info => {
            info.data.map(row => {
                if (row.show) {
                    requiredRows.push({
                        "code": row.code
                    })
                }

                return row
            })

            return info
        })

        return requiredRows
    }

    async function fillDataForOldClientViewsList() {
        await fetch("/proxy/project_bible_template/projectBibleClientView", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            }),
        })
            .then(res => res.json())
            .then(
                (resultClientView) => {
                    console.log("resultClientView", resultClientView)

                    if (resultClientView.length) {
                        resultClientView.map(value => {
                            setClientViewsList(
                                clientViewsList.map(info => {
                                    let cutDate = value["date_create"].substr(0, 10)
                                    let correctDate = new Date(cutDate);
                                    // correctDate.setDate(correctDate.getDate() + 1)

                                    info.data.push({
                                        code: value.code,
                                        submit: value.submit,
                                        date: correctDate.toISOString().split('T')[0],
                                        time: value.time_create.substr(0, 8),
                                        manager: value["created_by_manager"],
                                        isFormUpdate: value["is_form_update"]
                                    })

                                    return info
                                })
                            )

                            setIsLoadedClientViews(true)

                            return value
                        })
                    } else {
                        setIsLoadedClientViews(true)
                    }
                },
                (error) => {
                    setIsLoadedClientViews(false)
                    setError(error)
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function addClientViewCustommTable() {
        alert("Please wait :)")

        let filterData = []
        let createdBy = {
            id: employeeId,
            name: employeeName
        }

        columns.map(info => {
            info.data.map(column => {
                filterData.push({
                    "code": column.code,
                    "name": column.name,
                    "show": column.filter.show,
                    "type": column.type,
                    "showAll": {
                        "value": column.filter.showAll.value,
                        "selectedData": column.filter.showAll.selectedData,
                        "showEmptyCells": column.filter.showAll.showEmptyCells,
                        "showFilledCells": column.filter.showAll.showFilledCells
                    }
                })

                return column
            })

            return info
        })

        await fetch("/proxy/project_bible_template/addClientViewCustomTable", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "createdBy": createdBy,
                "filterData": filterData
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    window.open('http://sg.allcorrectgames.com/custom_table_create?client_name=' +
                        encodeURIComponent(clientName) + '&project_name=' + encodeURIComponent(projectName) +
                        '&project_code=' + encodeURIComponent(projectCode) +
                        '&code=' + result.rows[0].code + '#', '_blank');
                },
                (error) => {
                    setError({
                        message: "Error on creating new client custom table."
                    })
                }
            )
    }

    function openClientViewCustommTables() {
        window.open('http://sg.allcorrectgames.com/custom_table_create?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' + encodeURIComponent(projectName) +
            '&project_code=' + encodeURIComponent(projectCode) + '#', '_blank');
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Ошибка: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded || !isLoadedTagData || !isLoadedClientViews || !isLoadedTemplatesFilter) {
        return (
            <div className="row" style={styles.progressBar}>
                <div className="col-sm-4">

                </div>
                <div className="col-sm-4 center">
                    <h4>Loading...</h4>
                    <ProgressBar now={progressPercent} label={`${progressPercent}%`} />
                </div>
                <div className="col-sm-4">

                </div>
            </div>
        )
    } else {
        return(
            <div className="main-block-div-dark">
                <div className="row">
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicClientName">
                                <Form.Label>Client name</Form.Label>
                                <Form.Control type="clientName" disabled
                                              placeholder={queryStringParams.client_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectName">
                                <Form.Label>Project name</Form.Label>
                                <Form.Control type="projectName" disabled
                                              placeholder={queryStringParams.project_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectCode">
                                <Form.Label>Project code</Form.Label>
                                <Form.Control type="projectCode" disabled
                                              placeholder={queryStringParams.project_code} />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12 center">
                        <Button variant="primary" className="btn btn-blue" onClick={(e) => backToPBList()}>
                            To PB list&nbsp;&nbsp;<FontAwesomeIcon icon={faBackward}/>
                        </Button>
                        &nbsp;&nbsp;
                        {styleGuideExists ? (
                            <>
                                <Button variant="light" onClick={(e) => openStyleGuideLink()}>
                                    Open styleguide&nbsp;&nbsp;<FontAwesomeIcon icon={faLink}/>
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="secondary" onClick={(e) => copyStyleGuideLinkForLinguists()}>
                                    Copy SG link for linguists&nbsp;&nbsp;<FontAwesomeIcon icon={faCopy}/>
                                </Button>
                            </>
                        ) : (
                            <Button className="center" variant="dark"
                                    onClick={(e) => openStyleGuideModalWindow()}>
                                Create a styleguide&nbsp;&nbsp;<FontAwesomeIcon icon={faMagic}/>
                            </Button>
                        )}
                        &nbsp;&nbsp;
                        <Button className="center" variant="secondary" disabled>
                            { bibleFillPercentage }%
                        </Button>
                    </div>
                </div>
                <br/>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Filter</Accordion.Header>
                        <Accordion.Body>
                            <Filter columns={columns[0].data} changeFilter={changeFilter}
                                    selectedDataMultiselect={setSelectedDataForColumnInMultiselect}
                                    filterTable={filterTable} resetFilter={resetFilter}
                                    templatesFilter={templatesFilter}
                                    selectTemplateFilter={selectTemplateFilter}
                                    removeTemplatesFilterIsOn={removeTemplatesFilterIsOn}
                                    openModalToSaveNewTemplateFilter={openModalToSaveNewTemplateFilter}
                                    openModalToRenameTemplateFilter={openModalToRenameTemplateFilter}
                                    deleteTemplateFilter={deleteTemplateFilter}
                                    updateTemplateFilter={updateTemplateFilter} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br />
                <div className="row">
                    <div className="col-sm-12 tableFixHead">
                        {<TableInfo columns={columns[0].data} rows={rows[0].data} moveUpRow={moveUpRow}
                                    moveDownRow={moveDownRow} actionColumn={actionColumn}
                                    setInputRegexModalValue={setInputRegexModalValue}
                                    modalAddLinksAndImagesActive={modalAddLinksAndImagesActive}
                                    modalOpenAddedImageActive={modalOpenAddedImageActive}
                                    generalTabSaveCellValue={generalTabSaveCellValue}
                                    generalTabSaveBoolCellValue={generalTabSaveBoolCellValue}
                                    openInModalAddedImage={openInModalAddedImage}
                                    triggerOpenInModalAddedImage={triggerOpenInModalAddedImage}
                                    openModalToAddLinksAndImages={openModalToAddLinksAndImages}
                                    employeeId={employeeId} employeeName={employeeName}
                                    openCellDataHistory={openCellDataHistory}
                        />}
                    </div>
                </div>
                <br />
                <br />
                <div className="row">
                    <div className="col-sm-12 center">
                        <Button variant="success" className="btn btn-blue" onClick={(e) => createExcel()}>
                            Выгрузить excel&nbsp;&nbsp;<FontAwesomeIcon icon={faFileExcel}/>
                        </Button>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <Tab.Container defaultActiveKey={"clientView"}>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" className="nav-custom-grey">
                                <Nav.Item>
                                    <Nav.Link eventKey={"clientView"}>
                                        Client View
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"customTables"}>
                                        <div onClick={(e) => addClientViewCustommTable()}>Custom tables</div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"customTablesList"}>
                                        <div onClick={(e) => openClientViewCustommTables()}>Open custom tables list</div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"oldClientViews"}>
                                        Old Client Views
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey={"clientView"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        {
                                            styleGuideExists ? (
                                                <>
                                                    {
                                                        clientViewData[0].code === 0 ? (
                                                            <div style={styles.clientBlockView}>
                                                                <div className="row">
                                                                    <div className="col-sm-12 center">
                                                                        <Button className="center btn btn-blue"
                                                                                variant="primary"
                                                                                onClick={(e) => createClientView()}>
                                                                            Create Client Page&nbsp;&nbsp;
                                                                            <FontAwesomeIcon
                                                                                icon={faUserTie}/>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div style={styles.clientBlockView}>
                                                                <div className="row">
                                                                    <div className="col-sm-12 center">
                                                                        <strong>Client View</strong>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        Created: {clientViewData[0].dateCreate} {clientViewData[0].timeCreate}
                                                                        &nbsp;&nbsp;
                                                                        Manager: <u><a
                                                                        href={"https://b24.allcorrectgames.com/company/personal/user/" + clientViewData[0].createdBy.id + "/"}
                                                                        target="_blank">
                                                                        {clientViewData[0].createdBy.name}
                                                                    </a></u>
                                                                    </div>
                                                                    <div className="col-sm-6 center">
                                                                        {
                                                                            clientViewData[0].changesExist &&
                                                                            <>
                                                                                <Button variant="warning"
                                                                                        className="btn btn-yellow"
                                                                                        onClick={(e) => openClientViewChanges()}>
                                                                                    View Changes&nbsp;&nbsp;
                                                                                    <FontAwesomeIcon
                                                                                        icon={faExclamation}/>
                                                                                </Button>
                                                                                &nbsp;&nbsp;
                                                                            </>
                                                                        }
                                                                        <Button variant="light"
                                                                                onClick={(e) => openClientView()}>
                                                                            Open Link&nbsp;&nbsp;<FontAwesomeIcon
                                                                            icon={faLink}/>
                                                                        </Button>
                                                                        &nbsp;&nbsp;
                                                                        <Button variant="secondary"
                                                                                onClick={(e) => copyClientViewLink()}>
                                                                            Copy Link&nbsp;&nbsp;<FontAwesomeIcon
                                                                            icon={faCopy}/>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <div style={styles.clientBlockView}>
                                                    <div className="row">
                                                        <div className="col-sm-12 center">
                                                            <h4>You can't create the client page at the moment. Please
                                                                create a style guide
                                                                first. ⬆️</h4>
                                                            <br/>
                                                            <Button className="center" variant="secondary" disabled>
                                                                Create Client Page&nbsp;&nbsp;<FontAwesomeIcon
                                                                icon={faUserTie}/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={"oldClientViews"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        {
                                            styleGuideExists ? (
                                                <div style={styles.clientBlockView}>
                                                    <div className="row">
                                                        <div className="col-sm-12 center">
                                                            <strong>Old client View</strong>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <Form>
                                                                        <Form.Group className="mb-3"
                                                                                    controlId="fxorManagerName">
                                                                            <Form.Label>Имя менеджера</Form.Label>
                                                                            <Form.Control onChange={e =>
                                                                                setClientViewManagerName(e.target.value)}/>
                                                                        </Form.Group>
                                                                    </Form>
                                                                    <div className="center">
                                                                        <Button className="center btn btn-blue"
                                                                                variant="primary"
                                                                                onClick={(e) => createOldClientView()}>
                                                                            Создать страницу для клиента&nbsp;&nbsp;
                                                                            <FontAwesomeIcon
                                                                                icon={faUserTie}/>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <div className="row">
                                                                <div className="col-sm-12 center">
                                                                    <ClientViewList clientName={clientName}
                                                                                    projectName={projectName}
                                                                                    projectCode={projectCode}
                                                                                    clientViewList={clientViewsList[0].data}
                                                                                    deleteClientView={deleteClientView}
                                                                                    returnClientViewToWork={returnClientViewToWork}
                                                                                    resetFormUpdateValue={resetFormUpdateValue}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={styles.clientBlockView}>
                                                    <div className="row">
                                                        <div className="col-sm-12 center">
                                                            <h4>Вы не сможете создать страницу для клиента, пока не
                                                                создадите стайлгайд ⬆️</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <br/>
                <SaveInputRegexModal saveInputRegexModalActive={saveInputRegexModalActive}
                                     setSaveInputRegexModalActive={setSaveInputRegexModalActive}
                                     saveInputRegexModalData={saveInputRegexModalData}
                                     setSaveInputRegexModalData={setSaveInputRegexModalData}
                                     saveCell={saveInputCellWithTag}/>
                <StyleGuideModal createStyleGuideModalActive={createStyleGuideModalActive}
                                 setCreateStyleGuideModalActive={setCreateStyleGuideModalActive}
                                 createStyleGuide={createStyleGuide} styleGuideLanguages={styleGuideLanguages[0].data}/>
                <AddLinksAndImagesModal modalAddLinksAndImagesActive={modalAddLinksAndImagesActive}
                                        setModalAddLinksAndImagesActive={setModalAddLinksAndImagesActive}
                                        modalAddLinksAndImagesData={modalAddLinksAndImagesData}
                                        setModalAddLinksAndImagesData={setModalAddLinksAndImagesData}
                                        generalTabSaveCellValue={generalTabSaveCellValue}
                                        openInModalAddedImage={openInModalAddedImage}
                                        setImagesBufferedData={setImagesBufferedData}
                                        modalOpenCellDataHistoryData={modalOpenCellDataHistoryData}
                                        editModalHistoryDataCheckedValue={editModalHistoryDataCheckedValue}
                                        replaceToOldValueFromDataHistory={replaceToOldValueFromDataHistory} />
                <OpenInModalAddedImage modalOpenAddedImageActive={modalOpenAddedImageActive}
                                       setModalOpenAddedImageActive={setModalOpenAddedImageActive}
                                       modalOpenAddedImageSrc={modalOpenAddedImageSrc} />
                <TemplatesFilterModal modalOpenTemplatesFilterEditActive={modalOpenTemplatesFilterEditActive}
                                      setModalOpenTemplatesFilterEditActive={setModalOpenTemplatesFilterEditActive}
                                      modalOpenTemplatesFilterEditType={modalOpenTemplatesFilterEditType}
                                      saveNewTemplateFilter={saveNewTemplateFilter}
                                      renameTemplateFilter={renameTemplateFilter} />
                <OpenInTooltipAddedImage tooltipImageSrc={tooltipImageData[0].src}
                                         tooltipImageTitle={tooltipImageData[0].title}
                                         tooltipImageJustUploaded={tooltipImageData[0].justUploaded}
                />
                <ModalCellDataHistory modalOpenCellDataHistoryActive={modalOpenCellDataHistoryActive}
                                      setModalOpenCellDataHistoryActive={setModalOpenCellDataHistoryActive}
                                      modalOpenCellDataHistoryData={modalOpenCellDataHistoryData}
                                      replaceToOldValueFromDataHistory={replaceToOldValueFromDataHistory}
                                      editModalHistoryDataCheckedValue={editModalHistoryDataCheckedValue} />
                <ClientViewAcceptChangesModal
                    clientViewAcceptDataModalActive={clientViewAcceptDataModalActive}
                    setClientViewAcceptDataModalActive={setClientViewAcceptDataModalActive}
                    viewCode={clientViewData[0].code}
                    additionalComment={clientViewData[0].additionalComment}
                    generalTabColumns={clientViewData[0].generalTabColumns}
                    generalTabRows={clientViewData[0].generalTabRows}
                    languageTabData={clientViewData[0].languageTabData}
                    employeeId={employeeId}
                    employeeName={employeeName}
                />
                <AlertModal modalAlertActive={modalAlertActive}
                            setModalAlertActive={setModalAlertActive}
                            message={modalAlertData[0].message}
                />
            </div>
        )
    }
}
