import {Table} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import TableRow from "../view/TableRow";
import ColumnResizer from "react-column-resizer"

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    /*
    Это для закрепления верхней строки
    _______________________________________________________________
    */
    tableFixHeadTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },

    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
        // borderLeftColor: '#4A8EFF',
        // borderRightColor: '#4A8EFF'
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
        // borderLeftColor: '#4A8EFF',
        // borderRightColor: '#4A8EFF'
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    }
    /*
    _______________________________________________________________
    */
}

export default function TableInfo(props) {
    console.log("props.columns, props.rows", props.columns, props.rows)
    let rowNum = 0

    useEffect(() => {
        props.triggerOpenInModalAddedImage()
    }, []);

    return (
        <div className="div-table-blue">
            <Table className="resizableTable" responsive bordered style={styles.tableFixHeadTable}>
                <thead>
                <tr style={styles.tableHeaderRow}>
                    <th key="№" className="center" style={styles.tableFixHeadTh}>№</th>
                    {
                        props.columns.map(column => {
                            if (column.code !== "k4m02gng-b2nh8sjnyaght-rvv3olg1n1x76" && column.filter.show) {
                                return (
                                    <>
                                        <ColumnResizer key={column.code} className="columnResizer"/>
                                        <th key={column.name} className="center"
                                            style={styles.tableFixHeadTh}>{column.name}</th>
                                    </>
                                )
                            }
                        })
                    }
                    {
                        props.actionColumn &&
                        <ColumnResizer key="ActionColumnResizer" className="columnResizer"/>
                    }
                    {
                        props.actionColumn &&
                        <th key="Action" className="center" style={styles.tableFixHeadTh}>Action</th>
                    }
                </tr>
                </thead>
                <tbody style={styles.tableBodyTopBorder}>
                {
                    /*props.rows.map(info => {
                        /!*let rowNum = 1*!/

                        info.data.map((row, rowIndex)  => {
                            if (row.show) {
                                return (
                                    <TableRow key={rowIndex} row={row} rowIndex={rowIndex} columns={props.columns}
                                              moveUpRow={props.moveUpRow} moveDownRow={props.moveDownRow}
                                              actionColumn={props.actionColumn} /!*rowNum={rowNum}*!/ />
                                )

                                /!*rowNum++*!/
                            }

                            return row
                        })

                        return info
                    })*/

                    props.rows.map((row, rowIndex) => {
                        let showDeletedRow = "showDeletedRow" in row

                        if (row.show && (!showDeletedRow || (showDeletedRow && row.showDeletedRow))) {
                            rowNum++

                            return (
                                <TableRow key={rowIndex} row={row} rowIndex={rowIndex} columns={props.columns}
                                          moveUpRow={props.moveUpRow} moveDownRow={props.moveDownRow}
                                          actionColumn={props.actionColumn} rowNum={rowNum}
                                          setInputRegexModalValue={props.setInputRegexModalValue}
                                          modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                                          modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                                          triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                                          openInModalAddedImage={props.openInModalAddedImage}
                                          openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                                          generalTabSaveCellValue={props.generalTabSaveCellValue}
                                          generalTabSaveBoolCellValue={props.generalTabSaveBoolCellValue}
                                          employeeId={props.employeeId} employeeName={props.employeeName}
                                          openCellDataHistory={props.openCellDataHistory}
                                />
                            )
                        }
                    })
                }
                </tbody>
            </Table>
        </div>
    )
}
