import React, {useState} from "react";
import "./css/forms.css"
import queryString from "query-string";
import {Button, Form, FormControl, FormSelect, Table} from "react-bootstrap";
import DateInput from 'react-input-date'
import { MultiSelect } from 'primereact/multiselect';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const styles = {
    formLabel: {
        marginBottom: '0px'
    },

    requiredAlert: {
        color: "#ff0000",
        display: "none"
    },

    multiselectStyle: {
        width: '100%'
    }
}

export default function CategoryFieldsForm(props) {
    let getFields = fields => {
        let content = []

        for (let key in fields) {
            let value = fields[key]

            content.push(
                <div key={key} className="row formFieldRow">
                    <div className="col-sm-12">
                        <Form>
                            <Form.Group className="mb-12">
                                <Form.Label style={styles.formLabel}>
                                    <strong>
                                        {value.title}
                                        {
                                            value.required &&
                                            <span className="redText">*</span>
                                        }
                                    </strong>
                                </Form.Label>
                                {
                                    "description" in value &&
                                    <>
                                        <br/>
                                        <Form.Text muted>
                                            {value.description}
                                            {
                                                value.type === "num" &&
                                                <strong>&nbsp;(only numbers, dots, and dashes are allowed)</strong>
                                            }
                                        </Form.Text>
                                    </>
                                }
                                { getFormField(key, value.type, value.attioType,
                                    "selectOptions" in value ? value.selectOptions : [],
                                    value.required, "tableStyle" in value ? value.tableStyle : []) }
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            )
        }

        return content
    };

    let getFormField = (id, type, attioType, selectOptions, required, tableStyle) => {
        let content = []

        if (type === "input") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    onBlur={(e) =>
                        validateInputText(id, type, attioType, e.target.value, required)} />
            )
        } else if (type === "textarea") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    as="textarea" rows={3}
                    onBlur={(e) =>
                        props.setFieldValue(props.category, id, type, attioType, e.target.value)} />
            )
        } else if (type === "num") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    onKeyPress={(e) => validateInputNum(e)}
                    onBlur={(e) =>
                        props.setFieldValue(props.category, id, type, attioType, e.target.value)} />
            )
        } else if (type === "checkbox") {
            content.push(
                <Form.Check
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    type="checkbox"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, attioType, e.target.checked)} />
            )
        } else if (type === "select") {
            content.push(
                <Form.Select
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    defaultValue="default"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, attioType, e.target.value)} >
                    <option value="default" disabled></option>
                    { getSelectOptions(selectOptions) }
                </Form.Select>
            )

            for (let key in selectOptions) {
                let value = selectOptions[key]

                if ("attioSlug" in value &&
                    props.category in props.filledValues &&
                    attioType in props.filledValues[props.category] &&
                    id in props.filledValues[props.category][attioType] &&
                    props.filledValues[props.category][attioType][id].value === value.attioCode) {
                    if (value.type === "input") {
                        content.push(
                            <>
                                <br />
                                <Form.Control
                                    id={"field-" + props.category + "-" + id + "-" + value.attioSlug}
                                    key={"field-" + props.category + "-" + id + "-" + value.attioSlug}
                                    onBlur={(e) =>
                                        props.setAdditionalFieldValue(props.category, id, value.attioSlug, type, attioType, e.target.value)} />
                            </>
                        )
                    }

                    if (value.required) {
                        content.push(
                            <Form.Text
                                id={"red-required-text-" + props.category + "-" + id + "-" + value.attioSlug}
                                key={"red-required-text-" + props.category + "-" + id + "-" + value.attioSlug}
                                style={styles.requiredAlert} >
                                This is a required field, please fill it in
                            </Form.Text>
                        )
                    }
                }
            }
        } else if (type === "multiselect") {
            const multiselectOptions = []
            let selectedOptionsArray = []

            for (let key in selectOptions) {
                multiselectOptions.push({
                    label: key,
                    value: selectOptions[key].attioCode
                })
            }

            if (props.category in props.filledValues &&
                attioType in props.filledValues[props.category] &&
                id in props.filledValues[props.category][attioType]) {
                selectedOptionsArray = props.filledValues[props.category][attioType][id].value
            }

            content.push(
                <MultiSelect
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    value={selectedOptionsArray}
                    options={multiselectOptions}
                    style={styles.multiselectStyle}
                    className="multiselect-custom-style"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, attioType, e.value)}
                    maxSelectedLabels={1} />
            )
        } else if (type === "file") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    type="file"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, attioType, e.target.files)} />
            )
        } else if (type === "table") {
            let rowsForRender = []

            if (props.category in props.filledValues &&
                attioType in props.filledValues[props.category] &&
                id in props.filledValues[props.category][attioType]) {
                rowsForRender  = props.filledValues[props.category][attioType][id].value
            } else {
                let rowElementsForRender = []

                for (let i = 0; i < tableStyle.length; i++) {
                    rowElementsForRender.push("")
                }

                rowsForRender.push(rowElementsForRender)
            }

            content.push(
                <div className="center" key={"field-" + props.category + "-" + id}>
                    <Table responsive bordered id={"field-" + props.category + "-" + id}>
                        <thead>
                        <tr>
                        {
                            tableStyle.map(column => {
                                return (
                                    <th key={column.title}>{column.title}</th>
                                )
                            })
                        }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            rowsForRender.map((row, indexOfRow) => {
                                return (
                                    <tr key={indexOfRow}>
                                        {
                                            row.map((column, indexOfColumn) => {
                                                if (tableStyle[indexOfColumn].type === "select") {
                                                    let optionArray = []

                                                    for (let key in tableStyle[indexOfColumn].selectOptions) {
                                                        let value = tableStyle[indexOfColumn].selectOptions[key]

                                                        optionArray.push(
                                                            <option key={key} value={value.attioCode}>{key}</option>
                                                        )
                                                    }

                                                    return (
                                                        <td key={indexOfColumn}>
                                                            <FormSelect defaultValue={column} onChange={(e) =>
                                                                props.setTableFieldValue(props.category, id, type, attioType, indexOfRow, indexOfColumn, e.target.value)}>
                                                                <option value=""></option>
                                                                { optionArray }
                                                            </FormSelect>
                                                        </td>
                                                    )
                                                } else if (tableStyle[indexOfColumn].type === "input") {
                                                    return (
                                                        <td key={indexOfColumn}>
                                                            <FormControl
                                                                placeholder={tableStyle[indexOfColumn].title} aria-label="Username"
                                                                aria-describedby="basic-addon1" defaultValue={column}
                                                                onBlur={(e) =>
                                                                    props.setTableFieldValue(props.category, id, type, attioType, indexOfRow, indexOfColumn, e.target.value)}
                                                            />
                                                        </td>
                                                    )
                                                }
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                    <Button size="sm" variant="success" onClick={(e) =>
                        props.addTableRow(props.category, id, type, attioType)}><FontAwesomeIcon icon={faPlus} /> Add row</Button>
                </div>
            )
        }

        if (required) {
            content.push(
                <Form.Text
                    id={"red-required-text-" + props.category + "-" + id}
                    key={"red-required-text-" + props.category + "-" + id}
                    style={styles.requiredAlert} >
                    This is a required field, please fill it in
                </Form.Text>
            )
        }

        return content
    }

    let getSelectOptions = options => {
        let content = []

        for (let key in options) {
            let value = options[key]

            content.push(
                <option key={key} value={value.attioCode}>{key}</option>
            )
        }

        return content
    }

    function validateInputText(id, type, attioType, value, required) {
        // id.match(/email/i)

        if (id.toLowerCase().indexOf("email") !== -1 &&
            (required || (!required && value !== "")) &&
            !props.validateEmail(value)) {
            props.showAlertMessage("email")
        }

        props.setFieldValue(props.category, id, type, attioType, value)
    }

    function validateInputNum(event) {
        //8 Backspase
        //9 Tab
        //27 Escape
        //45 -
        //46 .

        if (event.charCode === 45 || event.charCode === 46 || event.charCode === 8 ||
            event.charCode === 9 || event.charCode === 27 ||
            // Разрешаем: Ctrl+A
            (event.charCode === 65 && event.ctrlKey === true) /*||
            // Разрешаем: home, end, влево, вправо
            (event.charCode >= 35 && event.charCode <= 39)*/) {

            // Ничего не делаем
            // return;
        } else {
            // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault()
            }
        }
    }

    return(
        <>
            { getFields(props.data[props.category].fields) }
        </>
    )
}